/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import './showletter.css';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const ShowLetter = (props: any) => {
  if (props.branch === 'daños') {
    return (
      <>
        <header className="container mt-15px mb-15px font-bold">
          <div>
            <img className="w-100 h-200px" src="./seglogo.png" alt="logo" />
          </div>
          <p className="font-12px mb-5px text-uppercase">
            {props.first_name ?? ''} {props.last_name ?? ''}
          </p>
          <p className="font-12px mb-5px text-uppercase">
            {props.pa_street ?? ''}
          </p>
          <p className="font-12px text-uppercase">
            {props.pa_city ?? ''} {props.pa_state ?? ''}{' '}
            {props.pa_zip_code ?? ''}
          </p>
        </header>
        <main className="container">
          <section className="mb-15px">
            <p className="font-12px mb-5px">Dear Madam or Sir:</p>
            <p className="font-12px mb-5px">
              Your insurance policy expired on:{' '}
              <span className="font-bold">{props.end_validity ?? ''}</span>
            </p>
            <p className="font-12px mb-5px">
              If you want to renew your policy {props.number ?? ''}{' '}
              {props.months ?? ''}, please send a check for:{' '}
              <span className="font-bold">{`$${props.payment ?? ''}`}</span>{' '}
              DLLS
            </p>
            <p className="font-12px mb-5px">
              Payable to:{' '}
              <span className="font-bold text-uppercase">
                {props.company_name ?? ''}
              </span>
            </p>
            <p className="font-12px mb-5px">
              To the address:{' '}
              <span className="font-bold">
                PO BOX 402 * LUKEVILLE, AZ * 85341
              </span>
            </p>
          </section>
          <section className="mb-15px">
            <p className="font-12px font-bold border text-center w-50 m-auto p-2px">
              NEW POLICY NUMBER
              <span className="d-block mt-5px">
                {props.policy_number ?? ''}
              </span>
            </p>
          </section>
          <section className="mb-15px">
            <p className="text-center font-12px">
              Please take a moment to review the following information about
              your home and COMPLETE THE SURVEY ON THE BACK if the information
              has change. Enclose this letter with your payment to update our
              records.
            </p>
          </section>
          <section className="mb-15px">
            <table
              cellPadding="0"
              cellSpacing="0"
              className="font-12px w-100 m-auto table"
            >
              <tr>
                <th>LOCATION:</th>
                <th>
                  {props.rl_street ?? ''} {props.rl_ext_number ?? ''}
                </th>
              </tr>
              <tr>
                <th className="text-left">BUILDING</th>
                <td className="text-center">
                  {props.building_fire ?? ''} DLLS
                </td>
              </tr>
              <tr>
                <th className="text-left">CONTENTS</th>
                <td className="text-center">
                  {props.content_burning ?? ''} DLLS
                </td>
              </tr>
              <tr>
                <th className="text-left">GLASS</th>
                <td className="text-center">{props.glass ?? ''} DLLS</td>
              </tr>
              <tr>
                <th className="text-left">THEFT</th>
                <td className="text-center">{props.stole ?? ''} DLLS</td>
              </tr>
              <tr>
                <th className="text-left">LIABILITY</th>
                <td className="text-center">{props.liability ?? ''} DLLS</td>
              </tr>
              <tr>
                <th className="text-left">DAMAGES TO ELECTRICAL EQUIPMENT</th>
                <td className="text-center">
                  {props.electronic_equipment ?? ''} DLLS
                </td>
              </tr>
            </table>
          </section>
        </main>
        <footer className="text-center mb-15px container">
          <p className="font-12px">THANK FOR YOUR TIME</p>
          <p className="font-pacifico font-12px">ALEJANDRO PORTUGAL MARTINEZ</p>
        </footer>
      </>
    );
  }

  if (props.branch === 'autos') {
    return (
      <>
        <header className="container mt-15px mb-15px font-bold">
          <div>
            <img className="w-100 h-200px" src="./seglogo.png" alt="logo" />
          </div>
          <p className="font-12px mb-5px text-uppercase">
            {props.first_name ?? ''} {props.last_name ?? ''}
          </p>
          <p className="font-12px mb-5px text-uppercase">
            {props.pa_street ?? ''}
          </p>
          <p className="font-12px text-uppercase">
            {props.pa_city ?? ''} {props.pa_state ?? ''}{' '}
            {props.pa_zip_code ?? ''}
          </p>
        </header>
        <main className="container">
          <section className="mb-15px">
            <p className="font-12px line-height-1-5 indentation">
              Your Auto policy{' '}
              <span className="font-bold">#{props.policy_number ?? ''}</span>{' '}
              coming up for renewal on{' '}
              <span className="font-bold">{props.end_validity}</span>; If you
              would like to renew your policy for the next anual term. Please
              mail us a check or money order in the amount of{' '}
              <span className="font-bold">
                {`$${props.payment ?? ''}`} dlls
              </span>
              . Payable to <span className="font-bold">ALEJANDRO PORTUGAL</span>{' '}
              to the address show below.
            </p>
          </section>
          <section className="mb-15px">
            <div className="grid clearfix">
              {props.car_data.map((car: any, index: number) => (
                <div
                  key={index}
                  className="p-2px col-4"
                  style={{ width: 'calc(33.3% - 4px)' }}
                >
                  <table
                    cellPadding="0"
                    cellSpacing="0"
                    className="font-12px table"
                  >
                    <tr>
                      <th colSpan={2}>{index + 1}</th>
                    </tr>
                    <tr>
                      <th className="text-left">YEAR:</th>
                      <td className="text-center">{car.year || ''}</td>
                    </tr>
                    <tr>
                      <th className="text-left">BRAND:</th>
                      <td className="text-center">{car.brand || ''}</td>
                    </tr>
                    <tr>
                      <th className="text-left">BODY:</th>
                      <td className="text-center">{car.model || ''}</td>
                    </tr>
                    <tr>
                      <th className="text-left">VIN NUMBER:</th>
                      <td className="text-center">{car.serial_number || ''}</td>
                    </tr>
                    <tr>
                      <th className="text-left">DRIVER LICENSE:</th>
                      <td className="text-center">
                        {car.license_number || ''}
                      </td>
                    </tr>
                    <tr>
                      <th className="text-left">Insured Sum:</th>
                      <td className="text-center">
                        {props.spec1?.sum_assured?.length &&
                        props.spec2?.sum_assured?.length
                          ? 'LIABILITY'
                          : 'EXCLUDED'}
                      </td>
                    </tr>
                  </table>
                </div>
              ))}
            </div>
            <p className="font-12px mt-15px">
              Tel: 602-692-9147 - Email: trinidadangel@gmail.com
            </p>
          </section>
          <section className="mb-15px">
            <p className="indentation font-12px line-height-1-5">
              If the information show above has changed, please feel free to
              contact me directly{' '}
              <span className="font-bold">{props.user_name}</span> e-mail:{' '}
              <span className="font-bold">{props.user_email}</span> or update
              the information yourself.{' '}
              <span className="font-bold">
                PLEASE DO NOT FORGET TO ENCLOSE A COPY OF YOUR DRIVER LICENSE
                FOR UNDERWRITING PURPOSES.
              </span>
            </p>
          </section>
          <section className="mb-15px">
            <p className="font-12px text-center line-height-1-5">
              Your time and bussines is greatly appreciated,
              <span className="d-block font-bold">
                Alejandro Portugal Martínez
              </span>
              Insurance Agent.
            </p>
          </section>
          <section className="mb-15px">
            <p className="font-bold font-12px">
              NOW WE HAVE TWO OFFICES -IN ROCKY POINT (PUERTO PENASCO)
            </p>
            <div className="grid clearfix mt-5px">
              <div className="col-6">
                <h3>OFFICE N° 1</h3>
                <p className="font-12px font-bold">
                  BLVD. BENITO JUAREZ Y SIMON MORUA S/N
                </p>
                <p className="font-12px font-bold">
                  PUERTO PENASCO, SON. MEX. 83550
                </p>
                <p className="font-12px font-bold">
                  TEL:
                  <span>011-52-638-383-2390</span>
                  <span>011-52-638-383-4070</span>
                </p>
                <p className="font-12px font-bold">FAX: 011-52-638-383-2324</p>
              </div>
              <div className="col-6">
                <h3>OFFICE N° 2</h3>
                <p className="font-12px font-bold">
                  BLVD. FREEMONT (road to las conchas)
                </p>
                <p className="font-12px font-bold">
                  PUERTO PENASCO, SON. MEX. 83550
                </p>
                <p className="font-12px font-bold">TEL: 011-52-638-383-5900</p>
                <p className="font-12px font-bold">FAX: 011-52-638-383-5800</p>
                <p className="font-12px font-bold">U.S NUMBER: 602-283-3686</p>
              </div>
            </div>
          </section>
        </main>
        <footer className="container text-center">
          <p className="font-12px font-bold">
            P.O. BOX 402 . LUKEVILLE, AZ . 85341
          </p>
          <p className="font-12px font-bold">602-445-6182</p>
          <p className="font-12px font-bold">
            <span className="border-bottom">SEGUROSP@GMAIL.COM</span> -{' '}
            <span className="border-bottom">WWW.PORTUGALINSURANCE.COM</span>
          </p>
          <p className="font-12px font-bold mt-5px">
            {props.user_name_uppercase}
          </p>
        </footer>
      </>
    );
  }

  return null;
};

export default ShowLetter;
