/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
import React, { useEffect, useRef, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { Button, Modal, Space, Typography, Row, Col } from 'antd';
import { deleteGroup, getGroups } from 'api/group';
import { GroupType } from 'interfaces';
import TableComponent from 'components/TableComponent';
import { handleError, handleSuccess, MessageTypes } from 'utils/handlers';

const { Text, Title } = Typography;

const Group: React.FC = () => {
  const [loading, setLoading] = useState<boolean>(true);
  const [showmodal, setShowModal] = useState<boolean>(false);
  const [groups, setGroups] = useState<GroupType[]>([]);
  const [deletegroup, setDeleteGroup] = useState<GroupType | null>(null);
  const [total, setTotal] = useState<number>(1);
  const [perpage, setPerPage] = useState<number>(10);
  const [currentpage, setCurrentPage] = useState<number>(1);

  const history = useHistory();
  const isMounted = useRef(true);

  useEffect(() => {
    return () => {
      isMounted.current = false;
    };
  }, []);

  useEffect(() => {
    const queryApi = async () => {
      try {
        setLoading(true);
        const response = await getGroups(perpage, currentpage);
        if (response?.data) {
          const groups = response.data.groups.map((group: GroupType) => ({
            ...group,
            key: group.id,
          }));
          if (isMounted.current) {
            setTotal(response.data.totalGroups);
            setGroups(groups);
            setLoading(false);
          }
        }
      } catch (err: any) {
        handleError({ err, type: MessageTypes.error });
        setTimeout(() => {
          history.push('/');
        }, 3000);
      }
    };
    queryApi();
  }, [currentpage, perpage]);

  const columns = [
    {
      title: 'Nombre',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Estado',
      dataIndex: 'is_available',
      key: 'is_available',
      render(isAvailable: string | number) {
        return (
          <>
            {isAvailable == 0 ? (
              <Text type="danger">Eliminado</Text>
            ) : (
              <Text type="success">Disponible</Text>
            )}
          </>
        );
      },
    },
    {
      title: 'Acciones',
      dataIndex: 'actions',
      key: 'actions',
      render(_: unknown, group: GroupType) {
        return (
          <Space direction="horizontal" size="small" style={{ width: '100%' }}>
            <Link to={`/grupo/editar/${group.id}`}>
              <Button type="link" block>
                Editar
              </Button>
            </Link>

            <Button
              type="link"
              danger
              block
              disabled={!group.is_available && true}
              onClick={() => handleModal('open', group)}
            >
              Eliminar
            </Button>
          </Space>
        );
      },
    },
  ];

  const handleModal = (action: string, group?: GroupType) => {
    if (action === 'open') {
      setDeleteGroup(group!);
      setShowModal(true);
    } else {
      setDeleteGroup(null);
      setShowModal(false);
    }
  };
  const handleModalOk = async () => {
    try {
      setLoading(true);
      const response = await deleteGroup(deletegroup!.id!);
      const groupDeleted = deletegroup;
      groupDeleted!.is_available = 0;
      setGroups((groups) =>
        groups.map((group) =>
          group.id === deletegroup!.id ? groupDeleted! : group
        )
      );
      setLoading(false);
      handleSuccess(response);
    } catch (err: any) {
      handleError({ err, type: MessageTypes.error });
    }
    setShowModal(false);
  };

  return (
    <>
      <Title style={{ textTransform: 'capitalize' }} level={2}>
        Grupos
      </Title>
      <Row justify="start" align="middle" style={{ marginBottom: '10px' }}>
        <Col xs={24} sm={12} md={10} lg={8} xl={6}>
          <Link to={`/grupo/crear`}>
            <Button type="primary" block disabled={loading}>
              Agregar nuevo Grupo
            </Button>
          </Link>
        </Col>
      </Row>
      <TableComponent
        columns={columns}
        dataSource={groups}
        loading={loading}
        pagination={{
          position: ['bottomLeft'],
          current: currentpage,
          pageSize: perpage,
          showSizeChanger: true,
          total,
          onChange: (currentPage: number, pageSize: number) => {
            setPerPage(pageSize);
            setCurrentPage(currentPage);
          },
        }}
      />
      <Modal
        title={`Eliminar Compañia`}
        centered
        visible={showmodal}
        confirmLoading={loading}
        onCancel={() => handleModal('close')}
        onOk={handleModalOk}
      >
        <p>
          ¿Estas seguro de querer eliminar el grupo{' '}
          {deletegroup && deletegroup.name}?
        </p>
      </Modal>
    </>
  );
};

export default Group;
