/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { Fragment } from 'react';
import { useParams } from 'react-router-dom';
import { Form, Card, Input, Select, Row, Col, Space, Divider } from 'antd';
import TableComponent from 'components/TableComponent';

const { Option } = Select;

interface Props {
  touristOption: string;
  specsData: any[];
}

const TouristData: React.FC = ({ touristOption, specsData }: Props) => {
  const params: { id: string } = useParams();

  const columns = [
    {
      title: 'Sección',
      dataIndex: 'section',
      key: 'section',
    },
    {
      title: 'Especificaciones',
      dataIndex: 'specs',
      key: 'specs',
      render(value: string, touristInfo: any) {
        return (
          <>
            <Form.Item
              name={[`specs_policy_${touristInfo.key}`, 'name']}
              hidden
            >
              <Input />
            </Form.Item>
            <Form.Item name={[`specs_policy_${touristInfo.key}`, 'id']} hidden>
              <Input />
            </Form.Item>
            <p>{value}</p>
          </>
        );
      },
    },
    {
      title: 'Amparado/Exclusivo',
      dataIndex: 'protected',
      key: 'protected',
      render(_: unknown, touristInfo: any) {
        return (
          <Form.Item
            name={[`specs_policy_${touristInfo.key}`, 'protected']}
            style={{ marginBottom: '0px' }}
          >
            <Input />
          </Form.Item>
        );
      },
    },
    {
      title: 'Suma asegurada',
      dataIndex: 'sum_assured',
      key: 'sum_assured',
      render(_: unknown, touristInfo: any) {
        return (
          <Form.Item
            name={[`specs_policy_${touristInfo.key}`, 'sum_assured']}
            style={{ marginBottom: '0px' }}
          >
            <Input />
          </Form.Item>
        );
      },
    },
    {
      title: 'Prima',
      dataIndex: 'raw',
      key: 'raw',
      render(_: unknown, touristInfo: any) {
        return (
          <Form.Item
            name={[`specs_policy_${touristInfo.key}`, 'raw']}
            style={{ marginBottom: '0px' }}
          >
            <Input />
          </Form.Item>
        );
      },
    },
  ];

  const vehicles = () => {
    const children: any = [];
    const numberOfVehicles = Number(touristOption.split('_')[0]);

    for (let i = 1; i <= numberOfVehicles; i++) {
      children.push(
        <Fragment key={i}>
          <Row align="middle" wrap gutter={[16, 8]}>
            <Col xs={24} md={12} lg={8}>
              <Form.Item
                label="Año"
                name={[`car_policy_${i}`, 'year']}
                rules={[
                  {
                    required: true,
                    type: 'string',
                  },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col xs={24} md={12} lg={8}>
              <Form.Item
                label="Marca"
                name={[`car_policy_${i}`, 'brand']}
                rules={[
                  {
                    required: true,
                    type: 'string',
                  },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col xs={24} md={12} lg={8}>
              <Form.Item
                label="Modelo"
                name={[`car_policy_${i}`, 'model']}
                rules={[
                  {
                    required: true,
                    type: 'string',
                  },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col xs={24} md={12} lg={8}>
              <Form.Item
                label="Número de serie"
                name={[`car_policy_${i}`, 'serial_number']}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col xs={24} md={12} lg={8}>
              <Form.Item label="Valor" name={[`car_policy_${i}`, 'value']}>
                <Input />
              </Form.Item>
            </Col>
          </Row>
          <Form.Item name={[`car_policy_${i}`, 'id']} hidden>
            <Input />
          </Form.Item>
          <Divider
            style={{
              height: '3px',
              marginTop: '10px',
            }}
          />
        </Fragment>
      );
    }
    return children;
  };

  const license = () => {
    const children: any = [];
    const numberOfLicenses = Number(touristOption.split('_')[0]);

    for (let i = 1; i <= numberOfLicenses; i++) {
      children.push(
        <Fragment key={i}>
          <Row align="middle" wrap gutter={[16, 8]}>
            <Col xs={24} md={12}>
              <Form.Item
                label="Número de Licencia"
                name={[`car_policy_${i}`, 'license_number']}
                rules={[
                  {
                    required: true,
                    type: 'string',
                  },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>
          </Row>
          <Form.Item name={[`car_policy_${i}`, 'id']} hidden>
            <Input />
          </Form.Item>
        </Fragment>
      );
    }
    return children;
  };

  return (
    <Space direction="vertical" style={{ width: '100%' }}>
      <Card title="Turista">
        <Row align="middle" wrap gutter={[16, 8]}>
          <Col xs={24} md={12} lg={8}>
            <Form.Item
              label="Tipo"
              name={['policy', 'tourist_policy_category']}
              rules={[
                {
                  required: true,
                  type: 'string',
                },
              ]}
            >
              <Select
                placeholder="--- Seleccionar --- "
                style={{ width: '100%' }}
                disabled={params.id ? true : undefined}
              >
                <Option value="1_vehicle">1 Vehículo</Option>
                <Option value="2_vehicle">2 Vehículos</Option>
                <Option value="3_vehicle">3 Vehículos</Option>
                <Option value="1_license">1 Licencia</Option>
                <Option value="2_license">2 Licencias</Option>
                <Option value="3_license">3 Licencias</Option>
              </Select>
            </Form.Item>
          </Col>
        </Row>
      </Card>
      <Card
        title={
          touristOption !== ''
            ? touristOption.split('_')[1] === 'vehicle'
              ? 'Vehiculos'
              : 'Licencias'
            : ''
        }
      >
        <Space direction="vertical" style={{ width: '100%' }}>
          {touristOption.split('_')[1] === 'vehicle' && vehicles()}
          {touristOption.split('_')[1] === 'license' && license()}
          <TableComponent
            columns={columns}
            dataSource={specsData}
            loading={false}
            pagination={false}
          />
        </Space>
      </Card>
    </Space>
  );
};

export default TouristData;
