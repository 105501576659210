/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useRef, useState } from 'react';
import { useHistory, useParams } from 'react-router';
import { Form, Row, Col, Input, Button, Spin, Typography } from 'antd';
import { createGroup, getGroup, updateGroup } from 'api/group';
import { CompanyType } from 'interfaces';
import { handleError, handleSuccess, MessageTypes } from 'utils/handlers';

const { Title } = Typography;

const AddEditGroup: React.FC = () => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState<boolean>(true);
  const [formstate, setFormState] = useState<CompanyType | undefined>(
    undefined
  );
  const history = useHistory();
  const params: { id: string } = useParams();
  const isMounted = useRef(true);

  useEffect(() => {
    return () => {
      isMounted.current = false;
    };
  }, []);

  useEffect(() => {
    if (params.id) {
      const queryApi = async () => {
        try {
          const response = await getGroup(params.id);
          if (isMounted.current) {
            setFormState(response.data.group);
            setLoading(false);
          }
        } catch (err: any) {
          handleError({ err, type: MessageTypes.error });
          setTimeout(() => {
            history.push('/grupo');
          }, 3000);
        }
      };
      queryApi();
    } else {
      if (isMounted.current) setLoading(false);
    }
  }, [params]);

  const onFinish = async (values: any) => {
    try {
      setLoading(true);
      await form.validateFields();
      let response: any;
      if (params.id) {
        response = await updateGroup(params.id, values);
      } else {
        response = await createGroup(values);
      }
      handleSuccess(response);
      setTimeout(() => {
        history.push('/grupo');
      }, 3000);
    } catch (err: any) {
      setLoading(false);
      handleError({ err, setLoading, type: MessageTypes.error });
    }
  };

  return (
    <Spin spinning={loading} tip="Cargando...">
      {!loading && (
        <>
          <Title level={2} style={{ marginBottom: '100px' }}>
            {params.id ? `Editar grupo` : 'Agregar grupo'}
          </Title>
          <Form
            form={form}
            initialValues={formstate}
            layout="horizontal"
            labelCol={{ xs: 12, sm: 10, md: 6, lg: 4 }}
            onFinish={onFinish}
          >
            <Row justify="center" align="middle">
              <Col xs={18}>
                <Form.Item
                  label="Nombre"
                  name="name"
                  rules={[
                    {
                      required: true,
                      type: 'string',
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
              </Col>
            </Row>
            <Row align="middle" justify="center" gutter={[16, 8]}>
              <Col xs={18}>
                <Form.Item>
                  <Button htmlType="submit" type="primary" block>
                    {params.id ? `Editar Grupo` : 'Crear Grupo'}
                  </Button>
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </>
      )}
    </Spin>
  );
};

export default AddEditGroup;
