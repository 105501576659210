/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
export const formatDate = (date: string): string => {
  return date?.split('-').reverse().join('-') ?? '';
};

export const notInformation = (objOrArr: any): boolean => {
  if (Array.isArray(objOrArr)) {
    return Object.values(objOrArr[0]).every((value) => value === null);
  }
  return Object.values(objOrArr).every((value) => value === null);
};
