/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { FC } from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Redirect,
  Route,
} from 'react-router-dom';
import styled from 'styled-components';
import { Layout as AntLayout } from 'antd';
import SideBar from '../Sidebar';
import ContentLayout from '../ContentLayout';
import AuthLayout from '../AuthLayout';
import { guessRoutes, guessPaths, privateRoutes, privatePaths } from './routes';
import AuthRoute from './AuthRoute';
import NotFound from './NotFound';
// -------- Components
// Users
import AddEditUser from '../../screens/AddEditUser';
// Clients
import ShowClient from '../../screens/ShowClient';
import AddEditClient from '../../screens/AddEditClient';

// Policy
import AddEditPolicy from '../../screens/AddEditPolicy';
import ShowPolicy from '../../screens/ShowPolicy';

// Company
import AddEditCompany from '../../screens/AddEditCompany';

// Group
import AddEditGroup from '../../screens/AddEditGroup';

// Policy Payment
import EditPolicyPayment from '../../screens/EditPolicyPayment';

interface Props {
  children?: React.ReactNode;
}

const privatePathsWithNewPaths = [
  // User
  '/usuario/crear',
  '/usuario/editar/:id',
  // Client
  '/cliente/ver/:id',
  '/cliente/crear',
  '/cliente/editar/:id',
  // policy
  '/poliza/crear',
  '/poliza/editar/:id',
  '/poliza/ver/:id',
  // company
  '/compañia/crear',
  '/compañia/editar/:id',
  // group
  '/grupo/crear',
  '/grupo/editar/:id',
  // policy Payment
  '/cobranza/editar/:id',
  ...privatePaths,
];
const privateRoutesWithNewRoutes = [
  // user
  { path: '/usuario/crear', component: AddEditUser },
  { path: '/usuario/editar/:id', component: AddEditUser },
  // client
  { path: '/cliente/ver/:id', component: ShowClient },
  { path: '/cliente/crear', component: AddEditClient },
  { path: '/cliente/editar/:id', component: AddEditClient },
  // policy
  { path: '/poliza/crear', component: AddEditPolicy },
  { path: '/poliza/editar/:id', component: AddEditPolicy },
  { path: '/poliza/ver/:id', component: ShowPolicy },
  // company
  { path: '/compañia/crear', component: AddEditCompany },
  { path: '/compañia/editar/:id', component: AddEditCompany },
  // group
  { path: '/grupo/crear', component: AddEditGroup },
  { path: '/grupo/editar/:id', component: AddEditGroup },
  // policy payment
  { path: '/cobranza/editar/:id', component: EditPolicyPayment },
  ...privateRoutes,
];

const allPaths = [...guessPaths, ...privatePathsWithNewPaths];

const Layout: FC = ({ children }: Props) => (
  <AntLayout>
    <SideBar />
    <ContentLayout>{children}</ContentLayout>
  </AntLayout>
);

const Navigation: FC = () => {
  const getPrivateRoutes = (
    routes: any[] = [],
    parentPath = ''
  ): React.ReactNode[] => {
    return routes.map((route) => {
      if (route.children?.length) {
        return getPrivateRoutes(route.children, route.path);
      }
      return (
        <AuthRoute
          key={parentPath + route.path}
          path={parentPath + route.path}
          component={route.component}
          isPrivate
        />
      );
    });
  };
  const PrivateRoutes = () => {
    return (
      <Route path={privatePathsWithNewPaths} exact>
        <Switch>
          <Layout>{getPrivateRoutes(privateRoutesWithNewRoutes)}</Layout>
        </Switch>
      </Route>
    );
  };

  const GuessRoutes = () => (
    <Route path={guessPaths} exact>
      <Switch>
        <AuthLayout>
          {guessRoutes.map((route) => (
            <AuthRoute
              key={route.path}
              path={route.path + ''}
              component={route.component}
            />
          ))}
        </AuthLayout>
      </Switch>
    </Route>
  );

  return (
    <AppContainer>
      <Router>
        <Switch>
          {/* Routes that work with and without authentication */}
          <Route exact path="/404" component={NotFound} />
          <Route path={allPaths} exact>
            {/* Guess routes */}
            <GuessRoutes />
            {/* Private routes */}
            <PrivateRoutes />
          </Route>
          <Route path="*">
            <Redirect to="/404" />
          </Route>
        </Switch>
      </Router>
    </AppContainer>
  );
};

const AppContainer = styled.div`
  height: 100vh;
  width: 100vw;
`;

export default Navigation;
