import React, { useEffect, useState } from 'react';
import { AutoComplete, Input } from 'antd';
import useDebounce from 'hooks/useDebounce';
import { useLocation } from 'react-router';

interface Props {
  setSearchText: (
    string: string
  ) => React.Dispatch<React.SetStateAction<string>>;
}

const SearchComponent: React.FC = ({ setSearchText }: Props) => {
  const [search, setSearch] = useState<string>('');
  const debouncedText = useDebounce(search, 800);

  const location = useLocation();

  useEffect(() => {
    setSearchText(debouncedText);
  }, [debouncedText]);

  const handleSearch = async (value: string) => {
    if (value.length) {
      setSearch(value);
    } else if (!value.length) {
      setSearch('');
    }
  };

  return (
    <AutoComplete
      dropdownMatchSelectWidth={252}
      style={{ width: '100%' }}
      onSearch={handleSearch}
    >
      <Input.Search
        size="middle"
        placeholder={`Buscar ${location.pathname.split('/')[1]}`}
        enterButton
      />
    </AutoComplete>
  );
};

export default SearchComponent;
