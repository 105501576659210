import React from 'react';
import { Form, Row, Col, Input, Select, Card, DatePicker } from 'antd';
import { GroupType } from 'interfaces';

const { Option } = Select;
const dateFormat = 'DD-MM-YYYY';

interface Props {
  groups: GroupType[];
}

const ClientData: React.FC = ({ groups }: Props) => (
  <Card title="Datos del Cliente">
    <Row align="middle" wrap gutter={[16, 8]}>
      <Col xs={24} md={12} lg={8}>
        <Form.Item
          label="Nombre(s)"
          name={['client', 'first_name']}
          rules={[
            {
              required: true,
              type: 'string',
            },
          ]}
        >
          <Input data-testid="first_name" />
        </Form.Item>
      </Col>

      <Col xs={24} md={12} lg={8}>
        <Form.Item
          label="Apellido(s)"
          name={['client', 'last_name']}
          rules={[
            {
              required: true,
              type: 'string',
            },
          ]}
        >
          <Input data-testid="last_name" />
        </Form.Item>
      </Col>

      <Col xs={24} md={12} lg={8}>
        <Form.Item label="Estado Civil" name={['client', 'marital_status']}>
          <Select placeholder="--- Seleccionar --- ">
            <Option value="casado">Casado</Option>
            <Option value="soltero">Soltero</Option>
            <Option value="viudo">Viudo</Option>
            <Option value="divorciado">Divorciado</Option>
          </Select>
        </Form.Item>
      </Col>

      <Col xs={24} md={12} lg={8}>
        <Form.Item
          label="Fecha de Nacimiento"
          name={['client', 'birthday']}
          rules={[
            () => ({
              validator(_, value) {
                const today = new Date();
                if (!value || value?._d < today) {
                  return Promise.resolve();
                }
                return Promise.reject(
                  new Error(
                    'La fecha de nacimiento no puede ser mayor al dia actual'
                  )
                );
              },
            }),
          ]}
        >
          <DatePicker style={{ width: '100%' }} format={dateFormat} />
        </Form.Item>
      </Col>

      <Col xs={24} md={12} lg={8}>
        <Form.Item
          label="Email"
          name={['client', 'email']}
          rules={[
            {
              required: false,
              type: 'email',
            },
          ]}
        >
          <Input />
        </Form.Item>
      </Col>

      <Col xs={24} md={12} lg={8}>
        <Form.Item
          label="Grupo"
          name={['client', 'group']}
          rules={[{ required: true }]}
        >
          <Select placeholder="--- Buscar ---" mode="multiple">
            {groups.map((group) => (
              <Option key={group.id} value={group.name}>
                {group.name}
              </Option>
            ))}
          </Select>
        </Form.Item>
      </Col>

      <Col xs={24} md={12} lg={8}>
        <Form.Item
          label="Teléfono"
          name={['client', 'phone']}
          rules={[{ required: true }]}
        >
          <Input />
        </Form.Item>
      </Col>

      <Col xs={24} md={12} lg={8}>
        <Form.Item label="Celular" name={['client', 'mobile']}>
          <Input />
        </Form.Item>
      </Col>

      <Col xs={24} md={12} lg={8}>
        <Form.Item label="Oficina" name={['client', 'office_phone']}>
          <Input />
        </Form.Item>
      </Col>
    </Row>
  </Card>
);

export default ClientData;
