/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import { useSelector } from 'react-redux';
import { updateUserAction } from 'store/actions/auth';
import { Redirect, Route, useHistory } from 'react-router-dom';
import { Modal } from 'antd';
import { privateRoutesClients } from '../routes';

interface Props {
  path: string;
  component: React.FC | React.ReactNode;
  isPrivate?: boolean;
}

const AuthRoute = ({
  isPrivate = false,
  path,
  component: Component,
}: Props): React.ReactElement => {
  const { user, isAuthenticated } = useSelector(({ auth }: any) => auth);
  const history = useHistory();

  document.title = 'SIS';
  if (!isPrivate && isAuthenticated) {
    return <Redirect to="/" />;
  }

  if (isPrivate && !isAuthenticated) {
    return <Redirect to="/login" />;
  }
  if (user?.role === '3' && !privateRoutesClients.includes(path)) {
    return <Redirect to="/poliza" />;
  }

  return (
    <Route
      path={path}
      exact
      render={(props) => (
        <>
          {isAuthenticated && (
            <Modal
              title="Cambiar contraseña"
              centered
              visible={user?.default_password === 1 ? true : false}
              okText="Cambiar"
              cancelText="Omitir"
              onCancel={() =>
                updateUserAction({ ...user, default_password: 0 })
              }
              onOk={() => {
                updateUserAction({ ...user, default_password: 0 });
                setTimeout(() => {
                  history.push('/perfil');
                }, 1000);
              }}
            >
              <p>Puedes cambiar tu contraseña que se establecio por defecto</p>
            </Modal>
          )}
          <Component {...props} />
        </>
      )}
    />
  );
};

export default AuthRoute;
