import React from 'react';
import { Form, Row, Col, Card, Input, Select, Space } from 'antd';

const { Option } = Select;

const HouseData: React.FC = () => (
  <Space direction="vertical">
    <Card title="Datos de Casa">
      <Row align="middle" wrap gutter={[16, 8]}>
        <Col xs={24} md={12} lg={8}>
          <Form.Item
            label="Tipo de construcción"
            name={['building_policy', 'construction_type']}
          >
            <Select placeholder="--- Seleccionar --- ">
              <Option value="muros macizos y techos de concreto">
                Muros macizos y techos de concreto
              </Option>
              <Option value="muros y techos ligeros (madera o panel estucado)">
                Muros y techos ligeros (madera o panel estucado)
              </Option>
              <Option value="muros macizos y techos ligeros">
                Muros macizos y techos ligeros
              </Option>
            </Select>
          </Form.Item>
        </Col>
        <Col xs={24} md={12} lg={8}>
          <Form.Item label="Consta de" name={['building_policy', 'floors']}>
            <Select placeholder="--- Seleccionar --- ">
              <Option value="1 planta">1 planta</Option>
              <Option value="2 plantas">2 plantas</Option>
              <Option value="3 plantas">3 plantas</Option>
            </Select>
          </Form.Item>
        </Col>
        <Col xs={24} md={12} lg={8}>
          <Form.Item label="Terremoto" name={['building_policy', 'earthquake']}>
            <Select placeholder="--- Seleccionar --- ">
              <Option value="1">Si</Option>
              <Option value="0">No</Option>
            </Select>
          </Form.Item>
        </Col>
        <Col xs={24} md={12} lg={8}>
          <Form.Item label="R.H." name={['building_policy', 'rh']}>
            <Select placeholder="--- Seleccionar --- ">
              <Option value="1">Si</Option>
              <Option value="0">No</Option>
            </Select>
          </Form.Item>
        </Col>
        <Form.Item name={['building_policy', 'id']} hidden>
          <Input />
        </Form.Item>
      </Row>
    </Card>
    <Card title="Ubicación del Riesgo">
      <Row align="middle" wrap gutter={[16, 8]}>
        <Col xs={24} md={12} lg={8}>
          <Form.Item
            label="País"
            name={['risk_location', 'country']}
            rules={[
              {
                required: true,
                type: 'string',
              },
            ]}
          >
            <Input />
          </Form.Item>
        </Col>
        <Col xs={24} md={12} lg={8}>
          <Form.Item
            label="Estado"
            name={['risk_location', 'state']}
            rules={[
              {
                required: true,
                type: 'string',
              },
            ]}
          >
            <Input />
          </Form.Item>
        </Col>
        <Col xs={24} md={12} lg={8}>
          <Form.Item
            label="Ciudad"
            name={['risk_location', 'city']}
            rules={[
              {
                required: true,
                type: 'string',
              },
            ]}
          >
            <Input />
          </Form.Item>
        </Col>
        <Col xs={24} md={12} lg={8}>
          <Form.Item label="Calle" name={['risk_location', 'street']}>
            <Input />
          </Form.Item>
        </Col>
        <Col xs={24} md={12} lg={8}>
          <Form.Item label="Núm. Ext." name={['risk_location', 'ext_number']}>
            <Input />
          </Form.Item>
        </Col>
        <Col xs={24} md={12} lg={8}>
          <Form.Item label="Colonia" name={['risk_location', 'neighborhood']}>
            <Input />
          </Form.Item>
        </Col>
        <Col xs={24} md={12} lg={8}>
          <Form.Item label="Código Postal" name={['risk_location', 'zip_code']}>
            <Input />
          </Form.Item>
        </Col>
        <Form.Item name={['risk_location', 'id']} hidden>
          <Input />
        </Form.Item>
      </Row>
    </Card>
    <Card title="Daños Casa">
      <Row align="middle" wrap gutter={[16, 8]}>
        <Col xs={24} md={12} lg={8}>
          <Form.Item
            label="Incendio del edificio"
            name={['building_damage', 'building_fire']}
          >
            <Input />
          </Form.Item>
        </Col>
        <Col xs={24} md={12} lg={8}>
          <Form.Item
            label="R.E. Edificios"
            name={['building_damage', 're_buildings']}
          >
            <Input />
          </Form.Item>
        </Col>
        <Col xs={24} md={12} lg={8}>
          <Form.Item
            label="B.E. Edificios"
            name={['building_damage', 'be_buildings']}
          >
            <Input />
          </Form.Item>
        </Col>
        <Col xs={24} md={12} lg={8}>
          <Form.Item
            label="Incendio de contenidos"
            name={['building_damage', 'content_burning']}
          >
            <Input />
          </Form.Item>
        </Col>
        <Col xs={24} md={12} lg={8}>
          <Form.Item
            label="R.E. Contenidos"
            name={['building_damage', 're_content']}
          >
            <Input />
          </Form.Item>
        </Col>
        <Col xs={24} md={12} lg={8}>
          <Form.Item
            label="G.E. Contenidos"
            name={['building_damage', 'ge_content']}
          >
            <Input />
          </Form.Item>
        </Col>
        <Col xs={24} md={12} lg={8}>
          <Form.Item
            label="Rotura accidental de cristales"
            name={['building_damage', 'accidental_glass_breakage']}
          >
            <Input />
          </Form.Item>
        </Col>
        <Col xs={24} md={12} lg={8}>
          <Form.Item
            label="Cristales templados"
            name={['building_damage', 'crystals']}
          >
            <Input />
          </Form.Item>
        </Col>
        <Col xs={24} md={12} lg={8}>
          <Form.Item
            label="Dinero en Efectivo"
            name={['building_damage', 'cash']}
          >
            <Input />
          </Form.Item>
        </Col>
        <Col xs={24} md={12} lg={8}>
          <Form.Item
            label="Robo con violencia y asalto"
            name={['building_damage', 'stole']}
          >
            <Input />
          </Form.Item>
        </Col>
        <Col xs={24} md={12} lg={8}>
          <Form.Item
            label="Resp. civil y familiar"
            name={['building_damage', 'civil_family_responsibility']}
          >
            <Input />
          </Form.Item>
        </Col>
        <Col xs={24} md={12} lg={8}>
          <Form.Item
            label="Daños a equipo electrodomestico"
            name={['building_damage', 'electronic_equipment']}
          >
            <Input />
          </Form.Item>
        </Col>
        <Col xs={24} md={12} lg={8}>
          <Form.Item label="Otros" name={['building_damage', 'others']}>
            <Input />
          </Form.Item>
        </Col>
        <Form.Item name={['building_damage', 'id']} hidden>
          <Input />
        </Form.Item>
      </Row>
    </Card>
  </Space>
);
export default HouseData;
