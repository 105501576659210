/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useState, Fragment, useRef } from 'react';
import { Card, Typography, Space, Divider } from 'antd';
import styled from 'styled-components';
import { handleError, MessageTypes } from 'utils/handlers';
import { formatDate, notInformation } from 'utils/functions';
import { useParams, useHistory } from 'react-router-dom';
import { getClient } from 'api/client';
import {
  UserType,
  ClientType,
  FiscalAddressType,
  PaymentAddressType,
} from 'interfaces';

const { Title } = Typography;

export interface ClientInterface {
  user: UserType;
  client: ClientType;
  fiscal_address?: FiscalAddressType;
  payment_address: PaymentAddressType[];
}

const ShowClient: React.FC = () => {
  const { id }: { id: string } = useParams();
  const [client, setClient] = useState<ClientInterface>({});
  const [loading, setLoading] = useState<boolean>(true);
  const history = useHistory();
  const isMounted = useRef(true);

  useEffect(() => {
    return () => {
      isMounted.current = false;
    };
  }, []);

  useEffect(() => {
    const queryApi = async () => {
      try {
        const response = await getClient(id);
        if (isMounted.current) {
          setClient(response.data.clients[0]);
          setLoading(false);
        }
      } catch (err: any) {
        handleError({ err, type: MessageTypes.error });
        setTimeout(() => {
          history.push('/cliente');
        }, 3000);
      }
    };
    queryApi();
  }, []);

  return (
    <Card loading={loading}>
      {!loading && (
        <>
          <Title>Ver Cliente {client.user.first_name}</Title>
          <Space direction="vertical" style={{ width: '100%' }}>
            <Card type="inner" title="Información del Cliente">
              <StyledCardGrid>
                <span>Nombre(s):</span> {client.user.first_name}
              </StyledCardGrid>
              <StyledCardGrid>
                <span>Apellido(s):</span> {client.user.last_name}
              </StyledCardGrid>
              {client.client.marital_status && (
                <StyledCardGrid>
                  <span>Estado Civil:</span> {client.client.marital_status}
                </StyledCardGrid>
              )}
              {client.client.birthday && (
                <StyledCardGrid>
                  <span>Fecha de Nacimiento:</span>{' '}
                  {formatDate(client.client.birthday)}
                </StyledCardGrid>
              )}
              <StyledCardGrid>
                <span>Email:</span> {client.user.email}
              </StyledCardGrid>
              <StyledCardGrid>
                <span>Grupo:</span> {client.client.group}
              </StyledCardGrid>
              {client.client.phone && (
                <StyledCardGrid>
                  <span>Teléfono:</span> {client.client.phone}
                </StyledCardGrid>
              )}
              {client.client.mobile && (
                <StyledCardGrid>
                  <span>Celular:</span> {client.client.mobile}
                </StyledCardGrid>
              )}
              {client.client.office_phone && (
                <StyledCardGrid>
                  <span>Oficina:</span> {client.client.office_phone}
                </StyledCardGrid>
              )}
              {client.client.observations && (
                <StyledCardGrid style={{ width: '100%' }}>
                  <span>Observaciones:</span> {client.client.observations}
                </StyledCardGrid>
              )}
            </Card>
            {client.fiscal_address && !notInformation(client.fiscal_address) && (
              <Card type="inner" title="Dirección Fiscal">
                {client.fiscal_address.street && (
                  <StyledCardGrid>
                    <span>Calle:</span> {client.fiscal_address.street}
                  </StyledCardGrid>
                )}
                {client.fiscal_address.ext_number && (
                  <StyledCardGrid>
                    <span>Num. Ext.:</span> {client.fiscal_address.ext_number}
                  </StyledCardGrid>
                )}
                {client.fiscal_address.rfc && (
                  <StyledCardGrid>
                    <span>RFC:</span> {client.fiscal_address.rfc}
                  </StyledCardGrid>
                )}
                {client.fiscal_address.country && (
                  <StyledCardGrid>
                    <span>País:</span> {client.fiscal_address.country}
                  </StyledCardGrid>
                )}
                {client.fiscal_address.state && (
                  <StyledCardGrid>
                    <span>Estado:</span> {client.fiscal_address.state}
                  </StyledCardGrid>
                )}
                {client.fiscal_address.city && (
                  <StyledCardGrid>
                    <span>Ciudad:</span> {client.fiscal_address.city}
                  </StyledCardGrid>
                )}
                {client.fiscal_address.township && (
                  <StyledCardGrid>
                    <span>Municipio:</span> {client.fiscal_address.township}
                  </StyledCardGrid>
                )}
                {client.fiscal_address.neighborhood && (
                  <StyledCardGrid>
                    <span>Colonia:</span> {client.fiscal_address.neighborhood}
                  </StyledCardGrid>
                )}
                {client.fiscal_address.zip_code && (
                  <StyledCardGrid>
                    <span>Código Postal:</span> {client.fiscal_address.zip_code}
                  </StyledCardGrid>
                )}
              </Card>
            )}
            {!notInformation(client.payment_address) && (
              <Card type="inner" title="Direcciones de Cobro">
                <Space direction="vertical" style={{ width: '100%' }}>
                  {client.payment_address.map((payment, index) => (
                    <Fragment key={payment.id}>
                      <Card
                        type="inner"
                        title={`Dirección de Cobro ${index + 1}`}
                      >
                        {payment.contact_reference && (
                          <StyledCardGrid>
                            <span>Contacto de referencia:</span>{' '}
                            {payment.contact_reference}
                          </StyledCardGrid>
                        )}
                        {payment.street && (
                          <StyledCardGrid>
                            <span>Calle:</span> {payment.street}
                          </StyledCardGrid>
                        )}
                        {payment.ext_number && (
                          <StyledCardGrid>
                            <span>Num. Ext.:</span> {payment.ext_number}
                          </StyledCardGrid>
                        )}
                        {payment.int_number && (
                          <StyledCardGrid>
                            <span>Num. Int.:</span> {payment.int_number}
                          </StyledCardGrid>
                        )}
                        {payment.country && (
                          <StyledCardGrid>
                            <span>País:</span> {payment.country}
                          </StyledCardGrid>
                        )}
                        {payment.state && (
                          <StyledCardGrid>
                            <span>Estado:</span> {payment.state}
                          </StyledCardGrid>
                        )}
                        {payment.city && (
                          <StyledCardGrid>
                            <span>Ciudad:</span> {payment.city}
                          </StyledCardGrid>
                        )}
                        {payment.neighborhood && (
                          <StyledCardGrid>
                            <span>Colonia:</span> {payment.neighborhood}
                          </StyledCardGrid>
                        )}
                        {payment.zip_code && (
                          <StyledCardGrid>
                            <span>Código Postal:</span> {payment.zip_code}
                          </StyledCardGrid>
                        )}
                      </Card>
                      {client.payment_address.length > 1 && (
                        <Divider
                          style={{
                            height: '3px',
                            marginTop: '10px',
                          }}
                        />
                      )}
                    </Fragment>
                  ))}
                </Space>
              </Card>
            )}
          </Space>
        </>
      )}
    </Card>
  );
};

const StyledCardGrid = styled(Card.Grid)`
  width: 100%;
  min-height: 100% !important;
  text-align: center;
  overflow-x: auto;

  span {
    display: block;
    font-weight: bold;
  }

  @media (min-width: 992px) {
    width: 50%;
  }
`;

export default ShowClient;
