import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import { ConfigProvider, message } from 'antd';
import esES from 'antd/es/locale/es_ES';
import App from './App';
import Moment from 'moment';
import locale from 'moment/locale/es';

message.config({
  top: 24,
  duration: 3,
  getContainer: () => document.querySelector('#sider') || document.body,
});

Moment.updateLocale('es', locale);

/* eslint-disable no-template-curly-in-string */
const validateMessages = {
  required: '${label} es requerido',
  types: {
    email: '${label} no es un email válido',
    integer: '${label} debe ser un número enteró',
    url: '${label} debe ser una URL válida',
  },
};
/* eslint-enable no-template-curly-in-string */

ReactDOM.render(
  <ConfigProvider form={{ validateMessages }} locale={esES}>
    <App />
  </ConfigProvider>,
  document.getElementById('root')
);
