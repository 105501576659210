import API from 'api/index';
import { AxiosResponse } from 'axios';

interface GetUsers {
  userPerPage?: number;
  currentPage?: number;
  q?: string;
}

export const getUsers = ({
  userPerPage,
  currentPage,
  q,
}: GetUsers): Promise<AxiosResponse> => {
  let URI = '/user';
  if (userPerPage && currentPage) {
    URI += `?per_page=${userPerPage}&page=${currentPage}`;
  }
  if (q) {
    URI += `&q=${q}`;
  }
  return API.get(URI);
};

export const getUser = (id: number | string): Promise<AxiosResponse> =>
  API.get(`/user/${id}`);

export const deleteUser = (id: number | string): Promise<AxiosResponse> =>
  API.delete(`/user/${id}`);

export const createUser = (data: unknown): Promise<AxiosResponse> =>
  API.post(`/user`, data);

export const updateUser = (
  id: number | string,
  data: unknown
): Promise<AxiosResponse> => API.put(`/user/${id}`, data);
