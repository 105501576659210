/* eslint-disable react/prop-types */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useContext, useEffect, useRef, useState } from 'react';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import {
  Button,
  Col,
  Row,
  Space,
  Tag,
  Typography,
  Switch,
  Spin,
  Select,
  Form,
  Input,
} from 'antd';
import { DownloadOutlined } from '@ant-design/icons';
import { FormInstance } from 'antd/lib/form';
import TableComponent from 'components/TableComponent';
import SearchComponent from 'components/SearchComponent';
import DateRangeFilterComponent from 'components/DateRangeFilterComponent';
import {
  getPoliciesPayment,
  editPolicyPayment,
  downloadPolicyPayments,
} from 'api/policyPayment';
import { getUsers } from 'api/user';
import { getCompanies } from 'api/company';
import { handleError, MessageTypes } from 'utils/handlers';
import { formatDate } from 'utils/functions';

import './index.css';

const EditableContext = React.createContext<FormInstance<any> | null>(null);

interface EditableRowProps {
  index: number;
}

interface EditableCellProps {
  editable: boolean;
  children: React.ReactNode;
  dataIndex: any;
  record: any;
  usersOption: any[];
  handleSave: (values: any) => void;
}

const { Title } = Typography;
const { Option } = Select;

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const EditableRow: React.FC<EditableRowProps> = ({ index, ...props }) => {
  const [form] = Form.useForm();
  return (
    <Form form={form} component={false}>
      <EditableContext.Provider value={form}>
        <tr {...props} />
      </EditableContext.Provider>
    </Form>
  );
};

const EditableCell: React.FC<EditableCellProps> = ({
  editable,
  children,
  dataIndex,
  record,
  usersOption,
  handleSave,
  ...restProps
}) => {
  const [editing, setEditing] = useState(false);
  const inputRef = useRef<Input>(null);
  const form = useContext(EditableContext)!;

  useEffect(() => {
    if (editing) {
      inputRef.current!.focus();
    }
  }, [editing]);

  const toggleEdit = () => {
    setEditing(!editing);
    form.setFieldsValue({ [dataIndex]: record[dataIndex] });
  };

  const save = async () => {
    try {
      const values = await form.validateFields();

      toggleEdit();
      handleSave({ previousValues: record, newValues: values });
    } catch (err: any) {
      handleError({ err, type: MessageTypes.error });
    }
  };

  let childNode = children;

  if (editable) {
    childNode = editing ? (
      dataIndex && dataIndex === 'policy_payment_method' ? (
        <Form.Item style={{ margin: 0 }} name={dataIndex}>
          <Select
            ref={inputRef}
            onChange={save}
            onBlur={save}
            placeholder="--- Seleccionar --- "
          >
            <Option value="efectivo">efectivo</Option>
            <Option value="tarjeta">tarjeta</Option>
            <Option value="transferencia">transferencia</Option>
            <Option value="cheque">cheque</Option>
          </Select>
        </Form.Item>
      ) : dataIndex && dataIndex === 'paid_by' ? (
        <Form.Item style={{ margin: 0 }} name={dataIndex}>
          <Select
            ref={inputRef}
            onChange={save}
            onBlur={save}
            placeholder="--- Seleccionar --- "
          >
            {usersOption.map((option: any, index: number) => (
              <Option value={`${option.value}-${option.name}`} key={index}>
                {option.name}
              </Option>
            ))}
          </Select>
        </Form.Item>
      ) : (
        <Form.Item style={{ margin: 0 }} name={dataIndex}>
          <Input ref={inputRef} onPressEnter={save} onBlur={save} />
        </Form.Item>
      )
    ) : (
      <div
        className="editable-cell-value-wrap"
        style={{ paddingRight: 24 }}
        onClick={toggleEdit}
      >
        {children}
      </div>
    );
  }

  return (
    <td
      {...restProps}
      style={dataIndex === 'paid_by' ? { minWidth: '150px' } : {}}
    >
      {childNode}
    </td>
  );
};

const PolicyPayment: React.FC = () => {
  const [loading, setLoading] = useState<boolean>(true);
  const [perpage, setPerPage] = useState<number>(10);
  const [currentpage, setCurrentPage] = useState<number>(1);
  const [policies, setPolicies] = useState<any[]>([]);
  const [total, setTotal] = useState<number>(1);
  const [searchText, setSearchText] = useState<string>('');
  const [dates, setDates] = useState<{
    initial_date?: string;
    final_date?: string;
  } | null>(null);
  const [findByText, setfindByText] = useState<boolean>(false);
  const [reportOptions, setReportOptions] = useState<Record<string, any>>({});
  const [reportValues, setReportValues] = useState<Record<string, any>>({});
  const [downloadLoading, setDownloadLoading] = useState<boolean>(false);

  const history = useHistory();
  const location = useLocation();
  const { user } = useSelector((store) => store.auth);
  const isMounted = useRef(true);

  useEffect(() => {
    return () => {
      isMounted.current = false;
    };
  }, []);

  useEffect(() => {
    (async () => {
      setLoading(true);
      await queryAPI();
    })();
  }, [currentpage]);

  useEffect(() => {
    setCurrentPage(1);
    if (findByText && currentpage === 1) {
      (async () => {
        setLoading(true);
        await queryAPI();
      })();
    }
  }, [searchText, dates, reportValues, perpage]);

  const handleSwitchChange = (id: number, checked: boolean) => {
    const data: any = {};
    data.updated_by = user.id;

    const date = new Date();
    const updated_date = `${date.getFullYear()}-${(
      '0' +
      (date.getMonth() + 1)
    ).slice(-2)}-${('0' + date.getDate()).slice(-2)}`;
    data.updated_date = updated_date;

    if (location.pathname.split('/')[2] === 'pagado') {
      if (checked) {
        data.payment_status = 'pagado';
      } else {
        data.payment_status = 'pendiente';
      }
    }

    if (location.pathname.split('/')[2] === 'liquidado') {
      if (checked) {
        data.payment_status = 'liquidado';
      } else {
        data.payment_status = 'pagado';
      }
    }

    if (location.pathname.split('/')[2] === 'consolidado') {
      if (checked) {
        data.payment_status = 'consolidado';
      } else {
        data.payment_status = 'liquidado';
      }
    }

    setPolicies((policies) =>
      policies.map((policy) =>
        policy.policy_payment_id === id
          ? {
              ...policy,
              payment_status: data.payment_status,
              updated_by: `${user.first_name} ${user.last_name}`,
              updated_date: formatDate(updated_date),
            }
          : policy
      )
    );

    try {
      editPolicyPayment(id, data);
    } catch (err: any) {
      handleError({ err, type: MessageTypes.error });
    }
  };

  const columns = [
    {
      title: 'Núm. póliza',
      dataIndex: 'policy_number',
      key: 'policy_number',
    },
    {
      title: 'ID póliza',
      dataIndex: 'policy_id',
      key: 'policy_id',
    },
    {
      title: 'Sub ramo',
      dataIndex: 'sub_branch',
      key: 'sub_branch',
    },
    {
      title: 'Compañia',
      dataIndex: 'company_name',
      key: 'company_name',
    },
    {
      title: 'Forma de pago',
      dataIndex: 'payment_method',
      key: 'payment_method',
    },
    {
      title: 'Núm. de pago',
      dataIndex: 'payment_number',
      key: 'payment_number',
    },
    {
      title: 'Método de pago',
      dataIndex: 'policy_payment_method',
      key: 'policy_payment_method',
      editable: true,
    },
    {
      title: 'Monto',
      dataIndex: 'payment_amount',
      key: 'payment_amount',
      editable: true,
    },
    {
      title: 'Estado',
      dataIndex: 'payment_status',
      key: 'payment_status',
      render(paymentStatus: string | null, payment: any) {
        const colors: any = {
          pagado: '#F6C216',
          liquidado: '#108EE9',
          consolidado: '#87d068',
          defaultColor: 'red',
        };
        const checkSwitch: any = {
          pagado(paymentStatus: string | null) {
            if (paymentStatus !== 'pagado') return false;
            return true;
          },
          liquidado(paymentStatus: string | null) {
            if (paymentStatus !== 'liquidado') return false;
            return true;
          },
          consolidado(paymentStatus: string | null) {
            if (paymentStatus !== 'consolidado') return false;
            return true;
          },
        };

        const defaultCheckedValue: boolean =
          checkSwitch[location.pathname.split('/')[2]](paymentStatus);

        return (
          <Row
            justify="space-between"
            align="middle"
            wrap
            gutter={[8, 8]}
            style={{ width: '180px' }}
          >
            <Col md={24} lg={12}>
              {paymentStatus ? (
                <Tag color={colors[paymentStatus] ?? colors.defaultColor}>
                  {paymentStatus}
                </Tag>
              ) : (
                <Tag>sin asignar</Tag>
              )}
            </Col>
            <Col md={24} lg={12}>
              <Switch
                defaultChecked={defaultCheckedValue}
                onChange={(checked) =>
                  handleSwitchChange(payment.policy_payment_id, checked)
                }
              />
            </Col>
          </Row>
        );
      },
    },
    {
      title: 'Pagado por',
      dataIndex: 'paid_by',
      key: 'paid_by',
      editable: true,
    },
    {
      title: 'Actualizado por',
      dataIndex: 'updated_by',
      key: 'updated_by',
    },
    {
      title: 'Fecha de actualización',
      dataIndex: 'updated_date',
      key: 'updated_date',
    },
    {
      title: 'Acciones',
      dataIndex: 'actions',
      key: 'actions',
      render(_: unknown, payment: any) {
        return (
          <Space direction="horizontal" size="small" style={{ width: '100%' }}>
            <Link to={`/cobranza/editar/${payment.policy_payment_id}`}>
              <Button type="link" block>
                Editar
              </Button>
            </Link>
            <Link to={`/poliza/ver/${payment.policy_id}`}>
              <Button type="link" block>
                Detalles
              </Button>
            </Link>
          </Space>
        );
      },
    },
  ];

  const handleSave = async ({ previousValues, newValues }: any) => {
    if (Object.values(newValues)[0] === null) return;

    const date = new Date();
    const updated_date = `${date.getFullYear()}-${(
      '0' +
      (date.getMonth() + 1)
    ).slice(-2)}-${('0' + date.getDate()).slice(-2)}`;

    const replaceObject = {
      ...previousValues,
      ...newValues,
      updated_by: `${user.first_name} ${user.last_name}`,
      updated_date: formatDate(updated_date),
    };

    if (newValues.paid_by) {
      replaceObject.paid_by = newValues.paid_by.split('-')[1];
    }

    setPolicies((policies) =>
      policies.map((policy) =>
        policy.policy_payment_id === previousValues.policy_payment_id
          ? replaceObject
          : policy
      )
    );

    const data: any = {
      ...newValues,
      updated_by: user.id,
      updated_date,
    };

    if (newValues.policy_payment_method) {
      data.payment_method = newValues.policy_payment_method;
    }

    if (newValues.paid_by) {
      data.paid_by = +newValues.paid_by.split('-')[0];
    }

    try {
      await editPolicyPayment(previousValues.policy_payment_id, data);
    } catch (err: any) {
      handleError({ err, type: MessageTypes.error });
    }
  };

  const newColumns = columns.map((col) =>
    !col.editable
      ? col
      : {
          ...col,
          onCell: (record: any) => ({
            record,
            editable: col.editable,
            dataIndex: col.dataIndex,
            usersOption: reportOptions.users,
            handleSave,
          }),
        }
  );

  const queryAPI = async () => {
    try {
      const [response, userResponse, companyResponse] = await Promise.all([
        getPoliciesPayment({
          perPage: perpage,
          currentPage: currentpage,
          q: searchText.length ? searchText.trim() : '',
          initial_date: dates?.initial_date,
          final_date: dates?.final_date,
          payment_status: location.pathname.split('/')[2],
          users: reportValues.users,
          subBranches: reportValues.subBranches,
          companies: reportValues.companies,
        }),
        getUsers({}),
        getCompanies(),
      ]);

      setReportOptions({
        ...reportOptions,
        users: userResponse.data.users.map((user: any) => ({
          value: user.id,
          name: `${user.first_name} ${user.last_name}`,
          profile_color: user.profile_color,
        })),
        companies: companyResponse.data.companies.map((company: any) => ({
          value: company.name,
          name: company.name,
        })),
        subBranches: [
          // autos
          {
            value: 'turistas',
            name: 'turistas',
          },
          {
            value: 'sedanes',
            name: 'sedanes',
          },
          {
            value: 'pick up',
            name: 'pick up',
          },
          {
            value: 'motocicleta',
            name: 'motocicleta',
          },
          // daños
          {
            value: 'casas',
            name: 'casas',
          },
          {
            value: 'empresarial',
            name: 'empresarial',
          },
          {
            value: 'bote',
            name: 'bote',
          },
          {
            value: 'r.c.',
            name: 'r.C.',
          },
          {
            value: 'transporte',
            name: 'transporte',
          },
          // vida
          {
            value: 'gastos_medicos',
            name: 'gastos médicos',
          },
          {
            value: 'vida',
            name: 'vida',
          },
        ],
      });

      const data = response.data.policyPayments.map(
        (policy: any, index: number) => {
          let months = 0;
          const totalpayments = +policy.payment_number.split('/')[1];

          if (totalpayments === 12) {
            months = 1;
          } else if (totalpayments === 4) {
            months = 3;
          } else if (totalpayments === 2) {
            months = 6;
          } else if (totalpayments === 1) {
            months = 12;
          }
          const date = new Date(`${policy.payment_date}T00:00:00`);
          date.setMonth(date.getMonth() + months);

          const period = `${('0' + date.getDate()).slice(-2)}-${(
            '0' +
            (date.getMonth() + 1)
          ).slice(-2)}-${date.getFullYear()}`;

          return {
            ...policy,
            payment_number: `${policy.payment_number}: ${formatDate(
              policy.payment_date
            )} / ${period}`,
            paid_by: `${policy.paid_by_first_name ?? ''} ${
              policy.paid_by_last_name ?? ''
            }`,
            updated_by: `${policy.first_name ?? ''} ${policy.last_name ?? ''}`,
            updated_date: policy.updated_date
              ? formatDate(policy.updated_date)
              : '',
            key: index,
          };
        }
      );
      if (isMounted.current) {
        setPolicies(data);
        setTotal(response.data.totalPolicies);
        setfindByText(true);
        setLoading(false);
      }
    } catch (err: any) {
      handleError({ err, type: MessageTypes.error });
      setTimeout(() => {
        history.push('/');
      }, 3000);
    }
  };

  const handleSelectsChange = (values: any) => {
    for (const [key, value] of Object.entries(values)) {
      if (value) {
        setReportValues({
          ...reportValues,
          [key]:
            key === 'subBranches' || key === 'companies'
              ? value.slice(-1)
              : value,
        });
      }
    }
  };

  const handleDownloadReport = async () => {
    try {
      setDownloadLoading(true);
      const response = await downloadPolicyPayments({
        payment_status: location.pathname.split('/')[2],
        users: reportValues.users,
        subBranches: reportValues.subBranches,
        companies: reportValues.companies,
        initial_date: dates?.initial_date,
        final_date: dates?.final_date,
        q: searchText.length ? searchText.trim() : '',
      });
      const imageURL = URL.createObjectURL(response.data);

      const date = new Date();
      const today = `${('0' + date.getDate()).slice(-2)}-${(
        '0' +
        (date.getMonth() + 1)
      ).slice(-2)}-${date.getFullYear()}`;
      const route = location.pathname.split('/')[2];
      const $link = document.createElement('a');
      $link.href = imageURL;
      $link.download = `cobranza-${
        (route === 'pagado' && 'pagos') ||
        (route === 'liquidado' && 'liquidados') ||
        (route === 'consolidado' && 'consolidados')
      }-${today}`;
      document.body.append($link);
      $link.click();
      $link.remove();
      setDownloadLoading(false);
    } catch (err: any) {
      handleError({ err, type: MessageTypes.error });
      setTimeout(() => {
        history.push('/');
      }, 3000);
    }
  };

  return (
    <>
      <Title level={3}>Cobranzas</Title>
      <Row
        justify="space-around"
        align="middle"
        wrap
        gutter={[8, 8]}
        style={{ marginBottom: '10px' }}
      >
        <Col xs={24} md={12}>
          <SearchComponent setSearchText={setSearchText} />
        </Col>
        <Col xs={24} md={12}>
          <DateRangeFilterComponent setDates={setDates} />
        </Col>
        <Col xs={24} md={6}>
          <Select
            mode="multiple"
            allowClear
            style={{ width: '100%' }}
            placeholder="Buscar: actualizado por"
            onChange={(value) => handleSelectsChange({ users: value })}
          >
            {reportOptions.users?.map((option: any, index: number) => (
              <Option value={`${option.value}-${option.name}`} key={index}>
                <span
                  style={{
                    backgroundColor: option.profile_color ?? '#fff',
                    minHeight: 10,
                    width: 10,
                    borderRadius: '50%',
                    marginRight: 2,
                    border: '1px solid #000',
                    display: 'inline-block',
                  }}
                ></span>
                {option.name}
              </Option>
            )) ?? null}
          </Select>
        </Col>
        <Col xs={24} md={6}>
          <Select
            mode="multiple"
            allowClear
            style={{ width: '100%' }}
            placeholder="Buscar: sub ramo"
            value={reportValues.subBranches || []}
            onChange={(value) => handleSelectsChange({ subBranches: value })}
          >
            {reportOptions.subBranches?.map((option: any, index: number) => (
              <Option value={option.value} key={index}>
                {option.name}
              </Option>
            )) ?? null}
          </Select>
        </Col>
        <Col xs={24} md={6}>
          <Select
            mode="multiple"
            allowClear
            style={{ width: '100%' }}
            placeholder="Buscar: compañia"
            value={reportValues.companies || []}
            onChange={(value) => handleSelectsChange({ companies: value })}
          >
            {reportOptions.companies?.map((option: any, index: number) => (
              <Option value={option.value} key={index}>
                {option.name}
              </Option>
            )) ?? null}
          </Select>
        </Col>
        <Col xs={24} md={6}>
          <Button
            type="primary"
            block
            disabled={loading}
            icon={<DownloadOutlined />}
            onClick={handleDownloadReport}
            loading={downloadLoading}
          >
            Generar reporte
          </Button>
        </Col>
      </Row>
      <Spin spinning={loading} tip="Cargando información">
        {!loading && (
          <TableComponent
            columns={newColumns}
            loading={loading}
            dataSource={policies}
            components={{
              body: {
                row: EditableRow,
                cell: EditableCell,
              },
            }}
            rowClassName={() => 'editable-row'}
            pagination={{
              position: ['bottomLeft'],
              current: currentpage,
              pageSize: perpage,
              showSizeChanger: true,
              total,
              onChange: (currentPage: number, pageSize: number) => {
                setPerPage(pageSize);
                setCurrentPage(currentPage);
              },
            }}
            style={{ whiteSpace: 'nowrap' }}
          />
        )}
      </Spin>
    </>
  );
};

export default PolicyPayment;
