/* eslint-disable no-param-reassign */
import axios, { AxiosRequestConfig, AxiosResponse, AxiosError } from 'axios';
import { store } from 'store';
import { logoutAction, refreshTokenAction } from 'store/actions/auth';
import { clearAppAction } from 'store/actions/app';
import { refreshToken } from './auth';
import jwt_decode from 'jwt-decode';

const HOST = process.env.BASE_URL;
const PATH = process.env.API_PATH;

export const baseURL = `${HOST}${PATH}`;

// const timeout = 3_600;

const defaultOptions = {
  baseURL,
  // timeout,
};

const http = axios.create(defaultOptions);

const requestInterceptor = async (options: AxiosRequestConfig) => {
  // if (options.url[options.url.length - 1] !== '/') {
  //   options.url = `${options.url}/`;
  // }
  options.headers['accept-language'] = 'es';
  let { accessToken } = store.getState().auth;
  if (accessToken && !options.url.includes('refresh-token')) {
    const decoded = jwt_decode(accessToken);
    const current = new Date();
    const expiration = new Date(decoded.exp * 1_000);
    const isExpired = current.getTime() >= expiration.getTime();
    if (isExpired) {
      accessToken = await getRefreshedToken();
    }
  }
  if (accessToken) {
    options.headers.Authorization = `Bearer ${accessToken}`;
  }
  return options;
};

const successResponseInterceptor = (response: AxiosResponse) => response;

const getRefreshedToken = async (): Promise<string | null> => {
  try {
    const { accessToken } = store.getState().auth;
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const res = await refreshToken(accessToken!);
    if (res?.status === 201 && res.data?.accessToken) {
      refreshTokenAction(res.data.accessToken);
      return res.data.accessToken;
    }
    return null;
  } catch (err) {
    return null;
  }
};

const logout = () => {
  setTimeout(() => {
    clearAppAction();
    logoutAction();
  });
};

const errorResponseInterceptor = async (err: AxiosError) => {
  const inRefreshToken = err?.config?.url?.includes('refresh-token');
  if (inRefreshToken) {
    logout();
  }
  return Promise.reject(err);
};

http.interceptors.request.use(requestInterceptor);
http.interceptors.response.use(
  successResponseInterceptor,
  errorResponseInterceptor
);

export default http;
