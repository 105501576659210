import { SetStateAction, Dispatch } from 'react';
import { message } from 'antd';
import { AxiosResponse } from 'axios';

export enum MessageTypes {
  warning = 'warning',
  warn = 'warn',
  error = 'error',
  err = 'error',
}

export interface ErrorType {
  response?: AxiosResponse;
  message?: string;
}

interface Props {
  err: ErrorType;
  setLoading?: Dispatch<SetStateAction<boolean>>;
  type?: MessageTypes;
}

const calcDuration = (msg = '') => msg.split(' ').length / 2;

export const handleError = ({
  err: _err,
  setLoading,
  type = MessageTypes.warn,
}: Props): void => {
  const error = _err;
  const response = error.response?.data;

  // stop loading
  if (setLoading) {
    setLoading(false);
  }
  // Checking if error is a response error and have a message
  if (response?.statusCode && response?.message) {
    message[type](
      response.message,
      Math.max(...[3, calcDuration(response.message)])
    );
    return;
  }
  // Checking if error is due to offline
  if (error?.message === 'Network Error' && !navigator.onLine) {
    message[type](
      'La petición no pudo ser realizada. Verifique su conexión a internet.',
      5
    );
    return;
  }
  // Checking enviroment to always return the error in development
  if (process.env.NODE_ENV === 'development') {
    // eslint-disable-next-line no-console
    console.error(error);
    message[type](error.toString(), 20);
  }
};

export const handleSuccess = (
  response: AxiosResponse | undefined,
  defaultMessage = ''
): void => {
  const msg = response?.data?.message || defaultMessage;
  // Validating that have a response or default message
  if (msg) {
    message.info(msg);
    return;
  }
  // Validating that does not have a message if is development enviroment
  if (process.env.NODE_ENV === 'development') {
    message.error('Message not provided');
  }
};

const handlers = {
  handleError,
  handleSuccess,
};

export default handlers;
