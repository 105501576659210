/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
import React, { useEffect, useRef, useState } from 'react';
import {
  Button,
  Col,
  Row,
  Space,
  Typography,
  Modal,
  Checkbox,
  DatePicker,
} from 'antd';
import { useHistory } from 'react-router-dom';
import TableComponent from 'components/TableComponent';
import SearchComponent from 'components/SearchComponent';
import ShowLetter from 'components/ShowLetter';
import { getPolicies, getPolicy } from 'api/policy';
import { downloadLetter, sendLetter } from 'api/letter';
import { formatDate } from 'utils/functions';
import { PolicyType } from 'interfaces';
import { handleError, handleSuccess, MessageTypes } from 'utils/handlers';

const { Text, Title } = Typography;

const Letter: React.FC = () => {
  const [loading, setLoading] = useState<boolean>(true);
  const [showmodal, setShowModal] = useState<boolean>(false);
  const [policies, setPolicies] = useState<PolicyType[]>([]);
  const [total, setTotal] = useState<number>(1);
  const [perpage, setPerPage] = useState<number>(10);
  const [currentpage, setCurrentPage] = useState<number>(1);
  const [searchText, setSearchText] = useState<string>('');
  const [endValidityDate, setEndValidityDate] = useState<string | null>(null);
  const [findByText, setfindByText] = useState<boolean>(false);
  const [clientId, setClientId] = useState<number | null>(null);
  const [loadingFile, setLoadingFile] = useState<boolean>(false);

  const history = useHistory();
  const isMounted = useRef(true);

  useEffect(() => {
    return () => {
      isMounted.current = false;
    };
  }, []);

  useEffect(() => {
    const queryApi = async () => {
      try {
        setLoading(true);
        await sortPolicies();
      } catch (err: any) {
        handleError({ err, type: MessageTypes.error });
        setTimeout(() => {
          history.push('/');
        }, 3000);
      }
    };
    queryApi();
  }, [currentpage]);

  useEffect(() => {
    setCurrentPage(1);
    if (findByText && currentpage === 1) {
      (async () => {
        try {
          setLoading(true);
          await sortPolicies();
        } catch (err: any) {
          handleError({ err, type: MessageTypes.error });
          setTimeout(() => {
            history.push('/');
          }, 3000);
        }
      })();
    }
  }, [searchText, endValidityDate, perpage]);

  const handleSendLetter = async ({
    policyId,
    clientId,
    clientEmail,
  }: {
    policyId: number;
    clientId: number;
    clientEmail: string | null;
  }) => {
    if (!clientEmail) {
      setClientId(clientId);
      setShowModal(true);
      return;
    }

    setLoadingFile(true);

    try {
      const response = await sendLetter(policyId);
      setPolicies((policies) =>
        policies.map((policy) =>
          policy.id === policyId
            ? { ...policy, is_renew_notification: 1 }
            : policy
        )
      );
      handleSuccess(response);
    } catch (err: any) {
      handleError({ err, type: MessageTypes.error });
    }
    setLoadingFile(false);
  };

  const columns = [
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
    },
    {
      title: 'Nombre',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Sub Ramo',
      dataIndex: 'sub_branch',
      key: 'sub_branch',
    },
    {
      title: 'Núm. póliza',
      dataIndex: 'policy_number',
      key: 'policy_number',
    },
    {
      title: 'Ubicación Riesgo / Datos Vehículo',
      dataIndex: 'information',
      key: 'information',
    },
    {
      title: 'Descripción',
      dataIndex: 'description',
      key: 'description',
    },
    {
      title: 'Inicio Vig',
      dataIndex: 'start_validity',
      key: 'start_validity',
      sorter: (a: any, b: any) => {
        const aDate: any = new Date(
          a.start_validity.split('-').reverse().join('-') + 'T00:00:00'
        );
        const bDate: any = new Date(
          b.start_validity.split('-').reverse().join('-') + 'T00:00:00'
        );
        return aDate - bDate;
      },
    },
    {
      title: 'Fin Vig',
      dataIndex: 'end_validity',
      key: 'end_validity',
      sorter: (a: any, b: any) => {
        const aDate: any = new Date(
          a.end_validity.split('-').reverse().join('-') + 'T00:00:00'
        );
        const bDate: any = new Date(
          b.end_validity.split('-').reverse().join('-') + 'T00:00:00'
        );
        return aDate - bDate;
      },
    },
    {
      title: 'Enviada',
      dataIndex: 'is_renew_notification',
      key: 'is_renew_notification',
      render(is_renew_notification: number) {
        return (
          <div style={{ textAlign: 'center' }}>
            <Checkbox
              checked={is_renew_notification === 1 ? true : false}
              disabled={true}
            />
          </div>
        );
      },
      sorter: (a: any, b: any) =>
        a.is_renew_notification - b.is_renew_notification,
      defaultSortOrder: 'ascend',
    },
    {
      title: 'Creado por:',
      dataIndex: 'created_by',
      key: 'created_by',
    },
    {
      title: 'Estado',
      dataIndex: 'is_available',
      key: 'is_available',
      render(isAvailable: string | number) {
        return (
          <>
            {isAvailable == 0 ? (
              <Text type="danger">Eliminado</Text>
            ) : (
              <Text type="success">Disponible</Text>
            )}
          </>
        );
      },
    },
    {
      title: 'Acciones',
      dataIndex: 'actions',
      key: 'actions',
      render(_: unknown, policyData: any) {
        if (
          policyData.branch === 'vida' ||
          policyData.sub_branch === 'bote' ||
          policyData.sub_branch === 'r.c.' ||
          policyData.sub_branch === 'tranporte'
        )
          return null;

        return (
          <Space
            direction="horizontal"
            size="small"
            style={{ width: '100%' }}
            align="baseline"
          >
            <Button
              type="link"
              block
              disabled={loadingFile}
              onClick={async () => {
                setLoadingFile(true);
                try {
                  const response = await getPolicy(policyData.id);
                  const policy = response.data;

                  const end_validity_array =
                    policy.policy.end_validity?.split('-');
                  const end_validity = `${end_validity_array[1]}/${end_validity_array[2]}/${end_validity_array[0]}`;

                  const payment =
                    policy?.policy_payment[0].payment_number === '1/1'
                      ? policy.policy.total || 0
                      : policy?.policy_payment[0].payment_amount || 0;
                  const content = {
                    first_name: policy.policy_belongs_user.first_name,
                    last_name: policy.policy_belongs_user.last_name,
                    pa_street: policy.payment_address
                      ? policy.payment_address[0].street
                      : '',
                    pa_city: policy.payment_address
                      ? policy.payment_address[0].city
                      : '',
                    pa_state: policy.payment_address
                      ? policy.payment_address[0].state
                      : '',
                    pa_zip_code: policy.payment_address
                      ? policy.payment_address[0].zip_code
                      : '',
                    policy_number: policy.policy.policy_number,
                    end_validity,
                    payment,
                    branch: policy.policy.branch,
                  };

                  let branchContent = null;
                  if (policy.policy.branch === 'daños') {
                    branchContent = {
                      ...content,
                      number: policy.policy_payment.length,
                      months:
                        policy.policy_payment.length > 1 ? 'MONTHS' : 'YEAR',
                      company_name: policy.policy.company_name,
                      rl_street: policy.risk_location
                        ? policy.risk_location[0].street || ''
                        : '',
                      rl_ext_number: policy.risk_location
                        ? policy.risk_location[0].ext_number || ''
                        : '',
                      building_fire: policy.building_damage
                        ? isNaN(policy.building_damage[0].building_fire)
                          ? '0.000'
                          : policy.building_damage[0].building_fire || '0.000'
                        : '0.000',
                      content_burning: policy.building_damage
                        ? isNaN(policy.building_damage[0].content_burning)
                          ? '0.000'
                          : policy.building_damage[0].content_burning || '0.000'
                        : '0.000',
                      glass: policy.building_damage
                        ? policy.policy.sub_branch === 'casas'
                          ? isNaN(
                              policy.building_damage[0]
                                .accidental_glass_breakage
                            )
                            ? '0.000'
                            : policy.building_damage[0]
                                .accidental_glass_breakage || '0.000'
                          : policy.policy.sub_branch === 'empresarial'
                          ? isNaN(policy.building_damage[0].crystals)
                            ? '0.000'
                            : policy.building_damage[0].crystals || '0.000'
                          : '0.000'
                        : '0.000',
                      stole: policy.building_damage
                        ? isNaN(policy.building_damage[0].stole)
                          ? '0.000'
                          : policy.building_damage[0].stole || '0.000'
                        : '0.000',
                      liability: policy.building_damage
                        ? policy.policy.sub_branch === 'casas'
                          ? isNaN(
                              policy.building_damage[0]
                                .civil_family_responsibility
                            )
                            ? '0.000'
                            : policy.building_damage[0]
                                .civil_family_responsibility || '0.000'
                          : policy.policy.sub_branch === 'empresarial'
                          ? isNaN(
                              policy.building_damage[0]
                                .civil_liability_activities_and_real_estate
                            )
                            ? '0.000'
                            : policy.building_damage[0]
                                .civil_liability_activities_and_real_estate ||
                              '0.000'
                          : '0.000'
                        : '0.000',
                      electronic_equipment: policy.building_damage
                        ? isNaN(policy.building_damage[0].electronic_equipment)
                          ? '0.000'
                          : policy.building_damage[0].electronic_equipment ||
                            '0.000'
                        : '0.000',
                    };
                  }

                  if (policy.policy.branch === 'autos') {
                    let spec1: any = null;
                    let spec2: any = null;
                    if (policy.specs) {
                      spec1 = policy.specs.find(
                        (spec: any) =>
                          spec.name === 'Colisión, Rotura de cristales'
                      );
                      spec2 = policy.specs.find(
                        (spec: any) =>
                          spec.name ===
                          'Incendio, robo total, y desastres naturales'
                      );
                    }

                    branchContent = {
                      ...content,
                      car_data: policy.car_policy,
                      spec1,
                      spec2,
                      user_name: `${
                        policy.user.first_name.charAt(0).toUpperCase() +
                        policy.user.first_name.slice(1)
                      } ${
                        policy.user.last_name.charAt(0).toUpperCase() +
                        policy.user.last_name.slice(1)
                      }`,
                      user_name_uppercase:
                        `${policy.user.first_name} ${policy.user.last_name}`.toUpperCase(),
                      user_email: policy.user.email,
                    };
                  }

                  Modal.info({
                    content: <ShowLetter {...branchContent} />,
                    width: '70%',
                    style: { top: 20 },
                    icon: null,
                  });
                } catch (err: any) {
                  handleError({ err, type: MessageTypes.error });
                }
                setLoadingFile(false);
              }}
            >
              Ver Carta
            </Button>

            <Button
              type="link"
              block
              disabled={loadingFile}
              onClick={() => {
                handleSendLetter({
                  policyId: policyData.id,
                  clientId: policyData.client_id,
                  clientEmail: policyData.email,
                });
              }}
            >
              Enviar Carta
            </Button>

            <Button
              type="link"
              block
              disabled={loadingFile}
              onClick={async () => {
                setLoadingFile(true);
                try {
                  const response = await downloadLetter(policyData.id);
                  const base64Data = Buffer.from(response.data.file).toString(
                    'base64'
                  );
                  const base64Response = await fetch(
                    `data:application/pdf;base64,${base64Data}`
                  );
                  const blob = await base64Response.blob();

                  const imageURL = URL.createObjectURL(blob);
                  const link = document.createElement('a');
                  link.href = imageURL;
                  link.download = response.data.name;
                  document.body.appendChild(link);
                  link.click();
                  document.body.removeChild(link);
                } catch (err: any) {
                  handleError({
                    err,
                    type: MessageTypes.error,
                  });
                }
                setLoadingFile(false);
              }}
            >
              Descargar Carta
            </Button>
          </Space>
        );
      },
    },
  ];

  const sortPolicies = async () => {
    const date = new Date();
    date.setMonth(date.getMonth() + 1);
    const nextMonthDate = `${date.getFullYear()}-${String(
      '0' + (date.getMonth() + 1)
    ).slice(-2)}`;

    const response = await getPolicies({
      perPage: perpage,
      currentPage: currentpage,
      q: searchText.length ? searchText : '',
      end_validity_date: endValidityDate ?? nextMonthDate,
    });

    if (response?.data?.totalPolicies === 0) {
      if (isMounted.current) {
        setPolicies([]);
        setTotal(1);
        setfindByText(true);
        setLoading(false);
        return;
      }
    }

    const data = response.data.policy.map((policy: any) => {
      let information = '';

      if (policy.car_policy.length) {
        for (const data of policy.car_policy) {
          information += `
          ${data.brand || ''} 
          ${data.model || ''} 
          ${data.year || ''} 
          ${data.license_number || ''},`;
        }

        information = information.slice(0, -1);
      }

      if (policy.risk_location.length) {
        for (const data of policy.risk_location) {
          information += `
          ${data.country || ''} 
          ${data.state || ''} 
          ${data.city || ''}
          ${data.street || ''} 
          ${data.neighborhood || ''}`;
        }
      }

      return {
        ...policy,
        name: `${policy.first_name} ${policy.last_name}`,
        information,
        start_validity: formatDate(policy.start_validity),
        end_validity: formatDate(policy.end_validity),
        key: policy.id,
      };
    });

    if (isMounted.current) {
      setTotal(response.data.totalPolicies);
      setPolicies(data);
      setfindByText(true);
      setLoading(false);
    }
  };

  const handleRangePickerMonth = (date: any) => {
    if (!date) {
      setEndValidityDate(null);
      return;
    }
    setEndValidityDate(date.format('YYYY-MM'));
  };

  return (
    <>
      <Title level={3}>Cartas</Title>
      <Row
        justify="start"
        align="middle"
        wrap
        gutter={[8, 8]}
        style={{ marginBottom: '10px' }}
      >
        <Col xs={24} md={8}>
          <SearchComponent setSearchText={setSearchText} />
        </Col>
        <Col xs={24} md={10}>
          <DatePicker
            picker="month"
            onChange={handleRangePickerMonth}
            format="MM-YYYY"
            style={{ width: '100%' }}
          />
        </Col>
      </Row>

      <TableComponent
        columns={columns}
        loading={loading}
        dataSource={policies}
        pagination={{
          position: ['bottomLeft'],
          current: currentpage,
          pageSize: perpage,
          total,
          showSizeChanger: true,
          onChange: (currentPage: number, pageSize: number) => {
            setPerPage(pageSize);
            setCurrentPage(currentPage);
          },
        }}
        style={{ whiteSpace: 'nowrap', width: 'auto' }}
      />
      <Modal
        title="Agregar correo"
        centered
        visible={showmodal}
        okText="Agregar"
        cancelText="Cancelar"
        onCancel={() => {
          setClientId(null);
          setShowModal(false);
        }}
        onOk={() => {
          history.push(`/cliente/editar/${clientId}`);
        }}
      >
        <p>
          Este cliente no posee un correo registrado, primero agrega su correo
          para poder enviar la carta.
        </p>
      </Modal>
    </>
  );
};

export default Letter;
