/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useState } from 'react';
import { Form, Row, Col, Input, Button, Spin } from 'antd';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';
import { loginAction } from 'store/actions/auth';
import { handleError, MessageTypes } from 'utils/handlers';
import { login } from 'api/auth';

const Login: React.FC = () => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState<boolean>(false);
  const history = useHistory();

  const onFinish = async () => {
    try {
      setLoading(true);
      await form.validateFields();
      const values = form.getFieldsValue();
      const response = await login(values);
      if (response?.data) {
        loginAction(response.data);
        if (response.data.user.role === '3') {
          history.push('/poliza');
        } else {
          history.push('/');
        }
      }
    } catch (err: any) {
      handleError({ err, setLoading, type: MessageTypes.error });
    }
  };

  return (
    <Spin spinning={loading} tip="Loading...">
      <StyledRow justify="center" align="middle">
        <Col xs={20} sm={16} md={12} lg={10} xl={8}>
          <Form form={form} layout="vertical" onFinish={onFinish}>
            <Form.Item
              label="Email"
              name="email"
              rules={[{ required: true, type: 'email' }]}
            >
              <Input allowClear />
            </Form.Item>
            <Form.Item
              label="Password"
              name="password"
              rules={[{ required: true }]}
            >
              <Input.Password allowClear />
            </Form.Item>
            <Form.Item>
              <Button htmlType="submit" type="primary" block>
                Login
              </Button>
            </Form.Item>
          </Form>
        </Col>
      </StyledRow>
    </Spin>
  );
};

const StyledRow = styled(Row)`
  height: 100%;
  width: 100%;
`;

export default Login;
