import React from 'react';
import { Form, Row, Col, Input, Card } from 'antd';

const FiscalAddress: React.FC = () => (
  <Card title="Dirección Fiscal">
    <Row align="middle" wrap gutter={[16, 8]}>
      <Col xs={24} md={12} lg={8}>
        <Form.Item label="Calle" name={['fiscalAddress', 'street']}>
          <Input />
        </Form.Item>
      </Col>

      <Col xs={24} md={12} lg={8}>
        <Form.Item label="Num. Ext." name={['fiscalAddress', 'ext_number']}>
          <Input />
        </Form.Item>
      </Col>

      <Col xs={24} md={12} lg={8}>
        <Form.Item label="RFC" name={['fiscalAddress', 'rfc']}>
          <Input />
        </Form.Item>
      </Col>

      <Col xs={24} md={12} lg={8}>
        <Form.Item label="País" name={['fiscalAddress', 'country']}>
          <Input />
        </Form.Item>
      </Col>

      <Col xs={24} md={12} lg={8}>
        <Form.Item label="Estado" name={['fiscalAddress', 'state']}>
          <Input />
        </Form.Item>
      </Col>

      <Col xs={24} md={12} lg={8}>
        <Form.Item label="Ciudad" name={['fiscalAddress', 'city']}>
          <Input />
        </Form.Item>
      </Col>

      <Col xs={24} md={12} lg={8}>
        <Form.Item label="Municipio" name={['fiscalAddress', 'township']}>
          <Input />
        </Form.Item>
      </Col>

      <Col xs={24} md={12} lg={8}>
        <Form.Item label="Colonia" name={['fiscalAddress', 'neighborhood']}>
          <Input />
        </Form.Item>
      </Col>

      <Col xs={24} md={12} lg={8}>
        <Form.Item label="Código Postal" name={['fiscalAddress', 'zip_code']}>
          <Input />
        </Form.Item>
      </Col>

      <Form.Item name={['fiscalAddress', 'id']}>
        <Input type="hidden" />
      </Form.Item>
    </Row>
  </Card>
);

export default FiscalAddress;
