/* eslint-disable @typescript-eslint/no-explicit-any */
import { AnyAction } from 'redux';

// Constants
export const LOGIN = 'LOGIN';
export const LOGOUT = 'LOGOUT';
export const REFRESH_TOKEN = 'REFRESH_TOKEN';
export const UPDATE_USER_INFO = 'UPDATE_USER_INFO';

export interface UserType {
  id: number;
  email: string;
  first_name: string;
  last_name: string;
  role: string;
  is_available: number;
  default_password: number;
  profile_color: string | null;
}

export interface AuthTypes {
  isAuthenticated?: boolean;
  accessToken?: string | null;
  user?: UserType | null;
}

export type handleState = (state: AuthTypes, action: AnyAction) => AuthTypes;

// Initial state
export const initialState: AuthTypes = {
  isAuthenticated: false,
  accessToken: null,
  user: null,
};

// Action handlers
const handleLogout = () => ({ ...initialState });

const handleLogin = (state: AuthTypes, payload: AuthTypes) => {
  if (payload) {
    const { accessToken, user } = payload;
    return {
      ...state,
      isAuthenticated: true,
      accessToken,
      user,
      loading: false,
    } as AuthTypes;
  }
  return state;
};

const handleRefreshToken = (state: AuthTypes, payload: string) =>
  ({
    ...state,
    accessToken: payload,
  } as AuthTypes);

const handleUserInfo = (state: AuthTypes, payload: UserType) =>
  ({ ...state, user: payload } as AuthTypes);

const ACTION_HANDLER: any = {
  [LOGIN]: handleLogin,
  [LOGOUT]: handleLogout,
  [UPDATE_USER_INFO]: handleUserInfo,
  [REFRESH_TOKEN]: handleRefreshToken,
};

const Handler: handleState = (state = initialState, action) => {
  const handler = ACTION_HANDLER[action.type];
  return handler ? handler(state, action.payload) : state;
};

export default Handler;
