import API from 'api/index';
import { AxiosResponse } from 'axios';

export const getGroups = (
  perPage?: number,
  currentPage?: number
): Promise<AxiosResponse> => {
  let URI = '';
  if (perPage && currentPage) {
    URI = `/group?per_page=${perPage}&page=${currentPage}`;
  } else {
    URI = `/group`;
  }
  return API.get(URI);
};

export const deleteGroup = (groupId: string | number): Promise<AxiosResponse> =>
  API.delete(`/group/${groupId}`);

export const getGroup = (id: string | number): Promise<AxiosResponse> =>
  API.get(`/group/${id}`);

export const createGroup = (group: unknown): Promise<AxiosResponse> =>
  API.post(`/group`, group);

export const updateGroup = (
  id: string | number,
  group: unknown
): Promise<AxiosResponse> => API.put(`/group/${id}`, group);
