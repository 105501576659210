/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useState, useEffect, useRef } from 'react';
import {
  Form,
  Row,
  Col,
  Button,
  Spin,
  Space,
  Typography,
  Card,
  Input,
} from 'antd';
import moment from 'moment';
import { useHistory, useParams } from 'react-router-dom';
import { addClient, getClient, updateClient } from 'api/client';
import { getGroups } from 'api/group';
import { handleError, handleSuccess, MessageTypes } from 'utils/handlers';
import { formatDate } from 'utils/functions';
import { ClientType, UserType, FiscalAddressType, GroupType } from 'interfaces';

// Components
import ClientData from './ClientData';
import FiscalAddress from './FiscalAddress';
import PaymentAddress from './PaymentAddress';

const { Title } = Typography;
const dateFormat = 'DD-MM-YYYY';

interface FormStateInterface {
  client: UserType & ClientType;
  fiscalAddress: FiscalAddressType;
  paNumber: number;
}

const AddClient: React.FC = () => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState<boolean>(true);
  const [formstate, setFormState] = useState<FormStateInterface | undefined>(
    undefined
  );
  const [groups, setGroups] = useState<GroupType[]>([]);
  const history = useHistory();
  const params: { id: string } = useParams();
  const isMounted = useRef(true);

  useEffect(() => {
    return () => {
      isMounted.current = false;
    };
  }, []);

  useEffect(() => {
    if (params.id) {
      const queryApi = async () => {
        try {
          const [response, responseGroups] = await Promise.all([
            getClient(params.id),
            getGroups(),
          ]);
          const clientData = response.data.clients[0];

          const state: any = {};
          state.client = {
            ...clientData.user,
            ...clientData.client,
            group: [clientData.client.group],
          };
          state.client.birthday = state.client.birthday
            ? moment(formatDate(clientData.client.birthday), dateFormat)
            : '';
          state.fiscalAddress = { ...clientData.fiscal_address };
          state.paNumber = clientData.payment_address.length;
          for (let i = 0; i < clientData.payment_address.length; i++) {
            state[`PaymentAddress_${i + 1}`] = clientData.payment_address[i];
          }
          if (isMounted.current) {
            setGroups(responseGroups.data.groups);
            setFormState(state);
            setLoading(false);
          }
        } catch (err: any) {
          handleError({ err, type: MessageTypes.error });
          setTimeout(() => {
            history.push('/cliente');
          }, 5000);
        }
      };
      queryApi();
    } else {
      (async () => {
        try {
          const response = await getGroups();
          if (isMounted.current) {
            setGroups(response.data.groups);
          }
        } catch (err: any) {
          handleError({ err, type: MessageTypes.error });
          setTimeout(() => {
            history.push('/cliente');
          }, 3000);
        }
        setLoading(false);
      })();
    }
  }, [params]);

  const onFinish = async (values: any) => {
    try {
      setLoading(true);
      await form.validateFields();
      values.client.group = values.client.group[0];

      const paymentAddress = [];
      for await (const [key, value] of Object.entries(values)) {
        if (key.includes('PaymentAddress')) {
          paymentAddress.push(value);
          delete values[key];
        }
      }
      if (values.client.birthday) {
        values.client.birthday = values.client.birthday.format('YYYY-MM-DD');
      } else {
        values.client.birthday = null;
      }
      values.paymentAddress = paymentAddress;

      if (!values.client.email) {
        values.client.email = null;
      }

      if (params.id) {
        const response = await updateClient(params.id, values);
        if (response?.data) {
          handleSuccess(response);
        }
      } else {
        const response = await addClient(values);
        if (response?.data) {
          handleSuccess(response);
        }
      }
      setTimeout(() => {
        history.push('/cliente');
      }, 3000);
    } catch (err: any) {
      setLoading(false);
      handleError({ err, type: MessageTypes.error });
    }
  };

  const handleOnFieldsChange = (changedFields: any) => {
    if (Object.keys(changedFields)[0] === 'client') {
      if (Object.keys(changedFields['client'])[0] === 'group') {
        form.setFieldsValue({
          ...formstate!,
          client: {
            ...formstate?.client,
            group: changedFields.client.group.slice(-1),
          },
        });
      }
    }
  };

  return (
    <Spin spinning={loading} tip="Cargando...">
      {!loading && (
        <>
          <Title level={2}>
            {params.id
              ? `Editar cliente: ${formstate?.client.first_name} ${formstate?.client.last_name}`
              : 'Nuevo cliente'}
          </Title>
          <Form
            initialValues={formstate}
            form={form}
            layout="vertical"
            onFinish={onFinish}
            onValuesChange={handleOnFieldsChange}
          >
            <Space direction="vertical">
              <ClientData groups={groups} />

              <FiscalAddress />

              <PaymentAddress paNumber={formstate?.paNumber} />

              <Card title="Observaciones">
                <Row align="middle">
                  <Col xs={24}>
                    <Form.Item
                      name={['client', 'observations']}
                      style={{ marginBottom: 0 }}
                    >
                      <Input.TextArea
                        style={{
                          width: '100%',
                          height: '150px',
                          resize: 'none',
                        }}
                      />
                    </Form.Item>
                  </Col>
                </Row>
              </Card>

              <Row align="middle" gutter={[16, 8]}>
                <Col xs={24} md={12}>
                  <Form.Item>
                    <Button htmlType="submit" type="primary" block>
                      {params.id ? 'Editar Cliente' : 'Crear Cliente'}
                    </Button>
                  </Form.Item>
                </Col>
              </Row>
            </Space>
          </Form>
        </>
      )}
    </Spin>
  );
};

export default AddClient;
