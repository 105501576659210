import API from 'api/index';
import { AxiosResponse } from 'axios';

interface GetClients {
  clientPerPage?: number;
  currentPage?: number;
  q?: string;
}

export const getClients = ({
  clientPerPage,
  currentPage,
  q,
}: GetClients): Promise<AxiosResponse> => {
  let URI = '/client';
  if (clientPerPage && currentPage) {
    URI += `?per_page=${clientPerPage}&page=${currentPage}`;
  }
  if (q) {
    URI += `&q=${q}`;
  }
  return API.get(URI);
};

export const searchClients = (q: string): Promise<AxiosResponse> =>
  API.get(`/client?q=${q}`);

export const getClient = (id: number | string): Promise<AxiosResponse> =>
  API.get(`/client/${id}`);

export const deleteClient = (id: number | string): Promise<AxiosResponse> =>
  API.delete(`/client/${id}`);

export const addClient = (data: unknown): Promise<AxiosResponse> =>
  API.post(`/client`, data);

export const updateClient = (
  id: string,
  data: unknown
): Promise<AxiosResponse> => API.put(`/client/${id}`, data);

export const countClients = (): Promise<AxiosResponse> =>
  API.get('/client/count');
