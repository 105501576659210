import React from 'react';
import styled from 'styled-components';
import Inbox from '../../assets/icons/inbox';

const Message = styled.p`
  font-family: Lato;
  font-size: 14px;
  font-weight: bold;
  color: var(--Regulartext);
`;

const EmptySection = styled.div`
  height: 100px;
`;

const NoResultsMessage: React.FC = (props: { loading: boolean }) => {
  return !props.loading ? (
    <>
      <Inbox />
      <Message>No se encontraron resultados</Message>
    </>
  ) : (
    <EmptySection />
  );
};

export default NoResultsMessage;
