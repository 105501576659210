/**
 * Store-main config file
 */
// Dependencies
import { createStore, compose, applyMiddleware } from 'redux';
import { persistStore } from 'redux-persist';
import rootReducer from './rootReducer';
import { AuthTypes, initialState as authInitialState } from './reducers/auth';
import { AppTypes, initialState as appInitialState } from './reducers/app';

interface Persist {
  rehydrated: boolean;
}

export interface GlobalStateTypes {
  auth: AuthTypes;
  app: AppTypes;
  _persist: Persist;
}

const initialState = {
  auth: authInitialState,
  app: appInitialState,
};

const middlewares: Array = [];
const enhancers: Array = [
  typeof window === 'object' &&
  typeof window.__REDUX_DEVTOOLS_EXTENSION__ !== 'undefined'
    ? window.__REDUX_DEVTOOLS_EXTENSION__()
    : (f) => f,
];

export const store = createStore(
  rootReducer(),
  initialState,
  compose(applyMiddleware(...middlewares), ...enhancers)
);

export const persistor = persistStore(store);

export default store;
