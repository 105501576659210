/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useRef, useState } from 'react';
import { Row, Col, Statistic, Typography, Card } from 'antd';
import DateRangeFilterComponent from 'components/DateRangeFilterComponent';
import { countClients } from 'api/client';
import { countPolicies } from 'api/policy';
import { countPolicyPayments } from 'api/policyPayment';
import { handleError, MessageTypes } from 'utils/handlers';

const { Title } = Typography;

const Dashboard: React.FC = () => {
  const [loading, setLoading] = useState(true);
  const [loadingPolicyPayments, setLoadingPolicyPayments] = useState(true);
  const [clients, setClients] = useState<any>({});
  const [policies, setPolicies] = useState<any>({});
  const [policyPayments, setPolicyPayments] = useState<any>({});
  const [dates, setDates] = useState<any>(null);
  const [findByDates, setFindByDates] = useState<any>(false);

  const isMounted = useRef(true);

  useEffect(() => {
    return () => {
      isMounted.current = false;
    };
  }, []);

  useEffect(() => {
    const queryAPI = async () => {
      try {
        const [clients, policies, policyPayments] = await Promise.all([
          countClients(),
          countPolicies(),
          countPolicyPayments(daysOfTheMonth()),
        ]);
        if (isMounted.current) {
          setClients(clients.data);
          setPolicies(policies.data);
          setPolicyPayments(policyPayments.data);
          setFindByDates(true);
          setLoading(false);
          setLoadingPolicyPayments(false);
        }
      } catch (err: any) {
        handleError({ err, type: MessageTypes.error });
      }
    };
    queryAPI();
  }, []);

  useEffect(() => {
    if (!findByDates) return;

    (async () => {
      setLoadingPolicyPayments(true);

      const policyPayments = await countPolicyPayments(
        dates || daysOfTheMonth()
      );
      if (isMounted.current) {
        setPolicyPayments(policyPayments.data);
        setLoadingPolicyPayments(false);
      }
    })();
  }, [dates]);

  const daysOfTheMonth = () => {
    const date = new Date();
    const days = new Date(date.getFullYear(), date.getMonth() + 1, 0).getDate();

    const initial_date = `${date.getFullYear()}-${(
      '0' +
      (date.getMonth() + 1)
    ).slice(-2)}-01`;
    const final_date = `${date.getFullYear()}-${(
      '0' +
      (date.getMonth() + 1)
    ).slice(-2)}-${days}`;

    return {
      initial_date,
      final_date,
    };
  };

  return (
    <>
      <Title level={2}>Clientes</Title>
      <Row align="middle" wrap gutter={[16, 8]}>
        <Col xs={24} md={8}>
          <Card loading={loading}>
            <Statistic title="Total clientes" value={clients.totalClients} />
          </Card>
        </Col>
        <Col xs={24} md={8}>
          <Card loading={loading}>
            <Statistic
              title="Clientes disponibles"
              value={clients.availableClients}
            />
          </Card>
        </Col>
        <Col xs={24} md={8}>
          <Card loading={loading}>
            <Statistic
              title="Clientes eliminados"
              value={clients.deletedClients}
            />
          </Card>
        </Col>
      </Row>
      <Title level={2} style={{ marginTop: '1rem' }}>
        Pólizas
      </Title>
      <Row align="middle" wrap gutter={[16, 8]}>
        <Col xs={24} md={8}>
          <Card loading={loading}>
            <Statistic title="Total pólizas" value={policies.totalPolicies} />
          </Card>
        </Col>
        <Col xs={24} md={8}>
          <Card loading={loading}>
            <Statistic
              title="Pólizas disponibles"
              value={policies.availablePolicies}
            />
          </Card>
        </Col>
        <Col xs={24} md={8}>
          <Card loading={loading}>
            <Statistic
              title="Pólizas eliminadas"
              value={policies.deletedPolicies}
            />
          </Card>
        </Col>
      </Row>
      <Title level={2} style={{ marginTop: '1rem' }}>
        Cobranzas
      </Title>
      <Row justify="start" wrap>
        <Col xs={24} md={12}>
          <DateRangeFilterComponent setDates={setDates} />
        </Col>
      </Row>

      <Row align="middle" wrap gutter={[16, 8]} style={{ marginTop: '1rem' }}>
        <Col xs={24} md={8}>
          <Card loading={loadingPolicyPayments}>
            <Statistic
              title="pagados"
              value={policyPayments.paid}
              suffix={`/${policyPayments.allPaid}`}
            />
          </Card>
        </Col>
        <Col xs={24} md={8}>
          <Card loading={loadingPolicyPayments}>
            <Statistic
              title="liquidados"
              value={policyPayments.liquidated}
              suffix={`/${policyPayments.allLiquidated}`}
            />
          </Card>
        </Col>
        <Col xs={24} md={8}>
          <Card loading={loadingPolicyPayments}>
            <Statistic
              title="consolidados"
              value={policyPayments.consolidated}
              suffix={`/${policyPayments.allConsolidated}`}
            />
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default Dashboard;
