import {
  SET_THEME,
  TOGGLE_SIDEBAR,
  CLEAR_APP,
  CURRENT_CLIENT,
  PREVIOUS_POLICY_ID,
} from 'store/reducers/app';
import { store } from 'store';

// Actions
export const toggleSidebarAction = (): void => {
  store.dispatch({
    type: TOGGLE_SIDEBAR,
  });
};

export const setThemeAction = (): void => {
  store.dispatch({
    type: SET_THEME,
  });
};

export const clearAppAction = (): void => {
  store.dispatch({
    type: CLEAR_APP,
  });
};

export const currentClientAction = (payload: unknown): void => {
  store.dispatch({
    type: CURRENT_CLIENT,
    payload,
  });
};

export const setPreviousPolicyIdAction = (
  payload: Record<string, unknown> | null
): void => {
  store.dispatch({
    type: PREVIOUS_POLICY_ID,
    payload,
  });
};
