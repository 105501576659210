/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { Fragment, useEffect, useRef, useState } from 'react';
import { useParams, useHistory, Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Button, Card, Col, Row, Space, Typography } from 'antd';
import { DownloadOutlined } from '@ant-design/icons';
import styled from 'styled-components';
import {
  PolicyType,
  CarPolicyType,
  SpecsPolicyType,
  CompanyType,
  RiskLocationType,
  BuildingPolicyType,
  BuildingDamageType,
  UserType,
  PolicyPaymentType,
} from 'interfaces';
import { getPolicy, sendEmail, downloadPolicy } from 'api/policy';
import { handleError, handleSuccess, MessageTypes } from 'utils/handlers';
import { formatDate, notInformation } from 'utils/functions';

const { Title } = Typography;

interface PolicyInterface {
  policy: PolicyType;
  company: CompanyType;
  user: UserType;
  policy_belongs_user: {
    first_name: string;
    last_name: string;
    email: string;
    id: number;
  };
  policy_payment: PolicyPaymentType[];
  car_policy: CarPolicyType[];
  specs?: SpecsPolicyType[];
  risk_location?: RiskLocationType[];
  building_policy: BuildingPolicyType[];
  building_damage?: BuildingDamageType[];
}

const ShowPolicy: React.FC = () => {
  const { id }: { id: string } = useParams();
  const [policydata, setPolicyData] = useState<PolicyInterface>({});
  const [loading, setLoading] = useState<boolean>(true);
  const [sendingFile, setSendingFile] = useState<boolean>(false);
  const history = useHistory();
  const isMounted = useRef(true);
  const { user } = useSelector((store) => store.auth);

  useEffect(() => {
    return () => {
      isMounted.current = false;
    };
  }, []);

  useEffect(() => {
    const queryApi = async () => {
      try {
        const response = await getPolicy(id);
        if (response?.data) {
          if (isMounted.current) {
            setPolicyData(response.data);
            setLoading(false);
          }
        }
      } catch (err: any) {
        handleError({ err, type: MessageTypes.error });
        setTimeout(() => {
          history.push('/poliza');
        }, 3000);
      }
    };
    queryApi();
  }, []);

  const handlerSendEmail = async () => {
    setSendingFile(true);
    try {
      const response = await sendEmail({
        email: policydata.policy_belongs_user.email,
        id: policydata.policy.id,
      });
      handleSuccess(response);
    } catch (err: any) {
      handleError({ err, type: MessageTypes.error });
    }
    setSendingFile(false);
  };

  const handleDownloadPolicy = async () => {
    setSendingFile(true);
    try {
      const response = await downloadPolicy(policydata.policy.id);
      const base64Data = Buffer.from(response.data.file).toString('base64');
      const base64Response = await fetch(
        `data:application/pdf;base64,${base64Data}`
      );
      const blob = await base64Response.blob();

      const imageURL = URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = imageURL;
      link.download = response.data.name;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (err: any) {
      handleError({ err, type: MessageTypes.error });
    }
    setSendingFile(false);
  };

  return (
    <Card loading={loading}>
      {!loading && (
        <>
          <Title level={2}>
            Póliza N°{id} - Cliente:{' '}
            {`${policydata.policy_belongs_user.first_name} ${policydata.policy_belongs_user.last_name}`}
          </Title>
          <Row
            justify="start"
            align="middle"
            wrap
            gutter={[8, 8]}
            style={{ marginBottom: '10px' }}
          >
            <Col xs={24} md={10} lg={8} xl={6}>
              <Button
                type="primary"
                block
                icon={<DownloadOutlined />}
                onClick={handleDownloadPolicy}
                disabled={sendingFile}
              >
                Descargar Póliza
              </Button>
            </Col>
            {user?.role !== '3' && (
              <>
                {policydata.policy_belongs_user.email ? (
                  <Col xs={24} md={10} lg={8} xl={6}>
                    <Button
                      type="primary"
                      block
                      onClick={handlerSendEmail}
                      disabled={sendingFile}
                    >
                      Enviar póliza al cliente
                    </Button>
                  </Col>
                ) : (
                  <Link
                    to={`/cliente/editar/${policydata.policy_belongs_user.id}`}
                  >
                    <Button type="primary" block>
                      Agregar correo del cliente para enviar póliza
                    </Button>
                  </Link>
                )}
              </>
            )}
          </Row>
          <Space direction="vertical" style={{ width: '100%' }}>
            <Card type="inner" title="Datos de la Póliza">
              <Space direction="vertical" style={{ width: '100%' }}>
                <Row align="middle" wrap gutter={[16, 8]}>
                  {policydata.policy.branch && (
                    <StyledCardGrid xs={24} md={12} lg={8}>
                      <span>Ramo:</span> {policydata.policy.branch}
                    </StyledCardGrid>
                  )}
                  {policydata.policy.sub_branch && (
                    <StyledCardGrid xs={24} md={12} lg={8}>
                      <span>Sub ramo:</span> {policydata.policy.sub_branch}
                    </StyledCardGrid>
                  )}
                  {policydata.policy.client_number && (
                    <StyledCardGrid xs={24} md={12} lg={8}>
                      <span>Número de cliente:</span>{' '}
                      {policydata.policy.client_number}
                    </StyledCardGrid>
                  )}
                  {policydata.policy.company_name && (
                    <StyledCardGrid xs={24} md={12} lg={8}>
                      <span>Compañia:</span> {policydata.policy.company_name}
                    </StyledCardGrid>
                  )}
                  {policydata.policy.policy_number && (
                    <StyledCardGrid xs={24} md={12} lg={8}>
                      <span>Número de póliza:</span>{' '}
                      {policydata.policy.policy_number}
                    </StyledCardGrid>
                  )}
                  {policydata.user && (
                    <StyledCardGrid xs={24} md={12} lg={8}>
                      <span>Póliza creada por:</span>{' '}
                      {policydata.user.first_name +
                        ' ' +
                        policydata.user.last_name}
                    </StyledCardGrid>
                  )}
                </Row>
                <Card type="inner" title="Vigencias">
                  <Row align="middle" wrap gutter={[16, 8]}>
                    {policydata.policy.start_validity && (
                      <StyledCardGrid xs={24} md={12} lg={8}>
                        <span>Inicio de vigencia:</span>{' '}
                        {formatDate(policydata.policy.start_validity)}
                      </StyledCardGrid>
                    )}
                    {policydata.policy.end_validity && (
                      <StyledCardGrid xs={24} md={12} lg={8}>
                        <span>Fin de vigencia:</span>{' '}
                        {formatDate(policydata.policy.end_validity)}
                      </StyledCardGrid>
                    )}
                    {policydata.policy.capture && (
                      <StyledCardGrid xs={24} md={12} lg={8}>
                        <span>Captura:</span>{' '}
                        {formatDate(policydata.policy.capture)}
                      </StyledCardGrid>
                    )}
                  </Row>
                </Card>
                {!notInformation({
                  net_premium: policydata.policy.net_premium,
                  overload: policydata.policy.overload,
                  law_policy: policydata.policy.law_policy,
                  vat: policydata.policy.vat,
                  total: policydata.policy.total,
                  currency: policydata.policy.currency,
                }) && (
                  <Card type="inner" title="Importes">
                    <Row align="middle" wrap gutter={[16, 8]}>
                      {policydata.policy.net_premium && (
                        <StyledCardGrid xs={24} md={12} lg={8}>
                          <span>Prima neta:</span>{' '}
                          {policydata.policy.net_premium}
                        </StyledCardGrid>
                      )}
                      {policydata.policy.overload && (
                        <StyledCardGrid xs={24} md={12} lg={8}>
                          <span>Recargas:</span> {policydata.policy.overload}
                        </StyledCardGrid>
                      )}
                      {policydata.policy.law_policy && (
                        <StyledCardGrid xs={24} md={12} lg={8}>
                          <span>Derecho póliza:</span>{' '}
                          {policydata.policy.law_policy}
                        </StyledCardGrid>
                      )}
                      {policydata.policy.vat && (
                        <StyledCardGrid xs={24} md={12} lg={8}>
                          <span>IVA:</span> {policydata.policy.vat}
                        </StyledCardGrid>
                      )}
                      {policydata.policy.total && (
                        <StyledCardGrid xs={24} md={12} lg={8}>
                          <span>Total:</span> {policydata.policy.total}
                        </StyledCardGrid>
                      )}
                      {policydata.policy.currency && (
                        <StyledCardGrid xs={24} md={12} lg={8}>
                          <span>Moneda:</span> {policydata.policy.currency}
                        </StyledCardGrid>
                      )}
                    </Row>
                  </Card>
                )}
                <Card type="inner" title="Pagos">
                  <Row align="middle">
                    <StyledCardGrid xs={24}>
                      <span>Forma de pago:</span>{' '}
                      {policydata.policy.payment_method}
                    </StyledCardGrid>
                  </Row>
                  <Space direction="vertical" style={{ width: '100%' }}>
                    {policydata.policy_payment.map((payment, index) => (
                      <Card
                        type="inner"
                        title={`Pago N°${index + 1}`}
                        key={payment.id}
                      >
                        <Row align="middle" wrap gutter={[16, 8]}>
                          <StyledCardGrid xs={24} md={12} lg={8}>
                            <span>Método de pago:</span>{' '}
                            {payment.payment_method}
                          </StyledCardGrid>
                          <StyledCardGrid xs={24} md={12} lg={8}>
                            <span>Monto</span> {payment.payment_amount}
                          </StyledCardGrid>
                          <StyledCardGrid xs={24} md={12} lg={8}>
                            <span>Estado:</span> {payment.payment_status}
                          </StyledCardGrid>
                        </Row>
                      </Card>
                    ))}
                  </Space>
                </Card>
                {policydata.policy.description && (
                  <Card type="inner" title="Documentación">
                    <Row align="middle" wrap gutter={[16, 8]}>
                      {policydata.policy.description && (
                        <StyledCardGrid xs={24}>
                          <span>Descripción:</span>{' '}
                          {policydata.policy.description}
                        </StyledCardGrid>
                      )}
                    </Row>
                  </Card>
                )}

                {(policydata.policy.reason ||
                  policydata.policy.completion_date) && (
                  <Card type="inner" title="Otra información">
                    <Row align="middle" wrap gutter={[16, 8]}>
                      {policydata.policy.reason && (
                        <StyledCardGrid xs={24} md={12} lg={8}>
                          <span>Motivo:</span> {policydata.policy.reason}
                        </StyledCardGrid>
                      )}
                      {policydata.policy.completion_date && (
                        <StyledCardGrid xs={24} md={12} lg={8}>
                          <span>Fecha de cancelación:</span>{' '}
                          {formatDate(policydata.policy.completion_date)}
                        </StyledCardGrid>
                      )}
                    </Row>
                  </Card>
                )}
              </Space>
            </Card>
            <Card type="inner" title="Ubicación Riesgo / Datos Vehículo(s)">
              <Space direction="vertical" style={{ width: '100%' }}>
                {policydata.car_policy
                  ? policydata.car_policy.map((policy, index) => (
                      <Fragment key={policy.id}>
                        <Card
                          type="inner"
                          title={
                            policy.license_number
                              ? `Licencia ${index + 1}`
                              : `Vehículo ${index + 1}`
                          }
                        >
                          <Row align="middle" wrap gutter={[16, 8]}>
                            {policy.license_number && (
                              <StyledCardGrid xs={24}>
                                <span>Número de Licencia:</span>{' '}
                                {policy.license_number}
                              </StyledCardGrid>
                            )}
                            {policy.year && (
                              <StyledCardGrid xs={24} md={12} lg={8}>
                                <span>Año:</span> {policy.year}
                              </StyledCardGrid>
                            )}
                            {policy.brand && (
                              <StyledCardGrid xs={24} md={12} lg={8}>
                                <span>Marca:</span> {policy.brand}
                              </StyledCardGrid>
                            )}
                            {policy.model && (
                              <StyledCardGrid xs={24} md={12} lg={8}>
                                <span>Modelo:</span> {policy.model}
                              </StyledCardGrid>
                            )}
                            {policy.serial_number && (
                              <StyledCardGrid xs={24} md={12} lg={8}>
                                <span>Número de Serie:</span>{' '}
                                {policy.serial_number}
                              </StyledCardGrid>
                            )}
                            {policy.value && (
                              <StyledCardGrid xs={24} md={12} lg={8}>
                                <span>Valor:</span> {policy.value}
                              </StyledCardGrid>
                            )}
                            {policy.plates && (
                              <StyledCardGrid xs={24} md={12} lg={8}>
                                <span>Placas:</span> {policy.plates}
                              </StyledCardGrid>
                            )}
                            {policy.others && (
                              <StyledCardGrid xs={24} md={12} lg={8}>
                                <span>Otros:</span> {policy.others}
                              </StyledCardGrid>
                            )}
                            {policy.coverage && (
                              <StyledCardGrid xs={24} md={12} lg={8}>
                                <span>Cobertura:</span> {policy.coverage}
                              </StyledCardGrid>
                            )}
                            {policy.driver_name && (
                              <StyledCardGrid xs={24} md={12} lg={8}>
                                <span>Nombre del conductor:</span>{' '}
                                {policy.driver_name}
                              </StyledCardGrid>
                            )}
                            {policy.merchandise_type && (
                              <StyledCardGrid xs={24} md={12} lg={8}>
                                <span>Tipo de mercancia:</span>{' '}
                                {policy.merchandise_type}
                              </StyledCardGrid>
                            )}
                            {policy.shipment_origin && (
                              <StyledCardGrid xs={24} md={12} lg={8}>
                                <span>Origen del embarque:</span>{' '}
                                {policy.shipment_origin}
                              </StyledCardGrid>
                            )}
                            {policy.shipment_destination && (
                              <StyledCardGrid xs={24} md={12} lg={8}>
                                <span>Destino del embarque:</span>{' '}
                                {policy.shipment_destination}
                              </StyledCardGrid>
                            )}
                          </Row>
                        </Card>
                      </Fragment>
                    ))
                  : policydata.risk_location?.map((risk, index) => (
                      <Card key={index} title="Ubicación Riesgo" type="inner">
                        <Row align="middle" wrap gutter={[16, 8]}>
                          {risk.country && (
                            <StyledCardGrid xs={24} md={12} lg={8}>
                              <span>País:</span> {risk.country}
                            </StyledCardGrid>
                          )}
                          {risk.state && (
                            <StyledCardGrid xs={24} md={12} lg={8}>
                              <span>Estado:</span> {risk.state}
                            </StyledCardGrid>
                          )}
                          {risk.city && (
                            <StyledCardGrid xs={24} md={12} lg={8}>
                              <span>Ciudad:</span> {risk.city}
                            </StyledCardGrid>
                          )}
                          {risk.street && (
                            <StyledCardGrid xs={24} md={12} lg={8}>
                              <span>Calle:</span> {risk.street}
                            </StyledCardGrid>
                          )}
                          {risk.ext_number && (
                            <StyledCardGrid xs={24} md={12} lg={8}>
                              <span>Núm. Ext.:</span> {risk.ext_number}
                            </StyledCardGrid>
                          )}
                          {risk.neighborhood && (
                            <StyledCardGrid xs={24} md={12} lg={8}>
                              <span>Colonia:</span> {risk.neighborhood}
                            </StyledCardGrid>
                          )}
                          {risk.zip_code && (
                            <StyledCardGrid xs={24} md={12} lg={8}>
                              <span>Código postal:</span> {risk.zip_code}
                            </StyledCardGrid>
                          )}
                        </Row>
                      </Card>
                    ))}
              </Space>
            </Card>

            {policydata.specs?.length ? (
              <Card type="inner" title="Especificaciones">
                <Space direction="vertical" style={{ width: '100%' }}>
                  {policydata.specs.map((policy) => (
                    <Card type="inner" title={policy.name} key={policy.id}>
                      <Row align="middle" wrap gutter={[16, 8]}>
                        {policy.protected && (
                          <StyledCardGrid xs={24} md={12} lg={8}>
                            <span>Amparado/Exclusivo:</span> {policy.protected}
                          </StyledCardGrid>
                        )}
                        {policy.sum_assured && (
                          <StyledCardGrid xs={24} md={12} lg={8}>
                            <span>Suma asegurada:</span> {policy.sum_assured}
                          </StyledCardGrid>
                        )}
                        {policy.raw && (
                          <StyledCardGrid xs={24} md={12} lg={8}>
                            <span>Prima:</span> {policy.raw}
                          </StyledCardGrid>
                        )}
                      </Row>
                    </Card>
                  ))}
                </Space>
              </Card>
            ) : null}

            {policydata.building_policy?.length
              ? policydata.building_policy.map((building) => (
                  <Card
                    type="inner"
                    title={
                      building.type === 'empresarial'
                        ? 'Datos de empresa'
                        : 'Datos de casa'
                    }
                    key={building.id}
                  >
                    <Row align="middle" wrap gutter={[16, 8]}>
                      {building.turn && (
                        <StyledCardGrid xs={24} md={12} lg={8}>
                          <span>Giro empresa:</span> {building.turn}
                        </StyledCardGrid>
                      )}
                      {building.coverage && (
                        <StyledCardGrid xs={24} md={12} lg={8}>
                          <span>Cobertura:</span> {building.coverage}
                        </StyledCardGrid>
                      )}
                      {building.construction_type && (
                        <StyledCardGrid xs={24} md={12} lg={8}>
                          <span>Tipo de construccion:</span>{' '}
                          {building.construction_type}
                        </StyledCardGrid>
                      )}
                      {building.floors && (
                        <StyledCardGrid xs={24} md={12} lg={8}>
                          <span>Consta de:</span> {building.floors}
                        </StyledCardGrid>
                      )}
                      {Number(building.earthquake) >= 0 && (
                        <StyledCardGrid xs={24} md={12} lg={8}>
                          <span>Terremoto:</span>{' '}
                          {Number(building.earthquake) ? 'Si' : 'No'}
                        </StyledCardGrid>
                      )}
                      {Number(building.rh) >= 0 && building.type === 'casas' && (
                        <StyledCardGrid xs={24} md={12} lg={8}>
                          <span>R.H.:</span> {Number(building.rh) ? 'Si' : 'No'}
                        </StyledCardGrid>
                      )}
                    </Row>
                  </Card>
                ))
              : null}

            {policydata.building_damage?.length
              ? policydata.building_damage.map((damage) => (
                  <Card
                    type="inner"
                    title={
                      policydata.building_policy[0].type === 'casas'
                        ? 'Daños casa'
                        : 'Daños Empresa'
                    }
                    key={damage.id}
                  >
                    <Row align="middle" wrap gutter={[16, 8]}>
                      {damage.building_fire && (
                        <StyledCardGrid xs={24} md={12} lg={8}>
                          <span>Incendio del edificio:</span>{' '}
                          {damage.building_fire}
                        </StyledCardGrid>
                      )}
                      {damage.re_buildings && (
                        <StyledCardGrid xs={24} md={12} lg={8}>
                          <span>R.E. Edificios:</span> {damage.re_buildings}
                        </StyledCardGrid>
                      )}
                      {damage.be_buildings && (
                        <StyledCardGrid xs={24} md={12} lg={8}>
                          <span>B.E. Edificios:</span> {damage.be_buildings}
                        </StyledCardGrid>
                      )}
                      {damage.content_burning && (
                        <StyledCardGrid xs={24} md={12} lg={8}>
                          <span>Incendio de contenidos:</span>{' '}
                          {damage.content_burning}
                        </StyledCardGrid>
                      )}
                      {damage.re_content && (
                        <StyledCardGrid xs={24} md={12} lg={8}>
                          <span>R.E. Contenidos:</span> {damage.re_content}
                        </StyledCardGrid>
                      )}
                      {damage.ge_content && (
                        <StyledCardGrid xs={24} md={12} lg={8}>
                          <span>G.E. Contenidos:</span> {damage.ge_content}
                        </StyledCardGrid>
                      )}
                      {damage.accidental_glass_breakage && (
                        <StyledCardGrid xs={24} md={12} lg={8}>
                          <span>Rotura accidental de cristales:</span>{' '}
                          {damage.accidental_glass_breakage}
                        </StyledCardGrid>
                      )}
                      {damage.crystals && (
                        <StyledCardGrid xs={24} md={12} lg={8}>
                          <span>
                            {policydata.building_policy[0].type === 'casas'
                              ? 'Cristales templados'
                              : 'Cristales'}
                          </span>{' '}
                          {damage.crystals}
                        </StyledCardGrid>
                      )}
                      {damage.cash && (
                        <StyledCardGrid xs={24} md={12} lg={8}>
                          <span>Dinero en Efectivo:</span> {damage.cash}
                        </StyledCardGrid>
                      )}
                      {damage.stole && (
                        <StyledCardGrid xs={24} md={12} lg={8}>
                          <span>Robo con violencia y asalto:</span>{' '}
                          {damage.stole}
                        </StyledCardGrid>
                      )}
                      {damage.civil_family_responsibility && (
                        <StyledCardGrid xs={24} md={12} lg={8}>
                          <span>Resp. civil y familiar:</span>{' '}
                          {damage.civil_family_responsibility}
                        </StyledCardGrid>
                      )}
                      {damage.electronic_equipment && (
                        <StyledCardGrid xs={24} md={12} lg={8}>
                          <span>
                            {policydata.building_policy[0].type === 'casas'
                              ? 'Daños a equipo electrodomestico'
                              : 'Equipo electrónico'}
                          </span>{' '}
                          {damage.electronic_equipment}
                        </StyledCardGrid>
                      )}
                      {damage.others && (
                        <StyledCardGrid xs={24} md={12} lg={8}>
                          <span>Otros:</span> {damage.others}
                        </StyledCardGrid>
                      )}
                      {damage.boilers_and_pressure_vessels && (
                        <StyledCardGrid xs={24} md={12} lg={8}>
                          <span>Calderas y recipientes a presión:</span>{' '}
                          {damage.boilers_and_pressure_vessels}
                        </StyledCardGrid>
                      )}
                      {damage.civil_liability_activities_and_real_estate && (
                        <StyledCardGrid xs={24} md={12} lg={8}>
                          <span>Resp. civil actividades e inmuebles:</span>{' '}
                          {damage.civil_liability_activities_and_real_estate}
                        </StyledCardGrid>
                      )}
                      {damage.consequential_losses && (
                        <StyledCardGrid xs={24} md={12} lg={8}>
                          <span>Perdidas consecuenciales:</span>{' '}
                          {damage.consequential_losses}
                        </StyledCardGrid>
                      )}
                      {damage.machinery_breakdown && (
                        <StyledCardGrid xs={24} md={12} lg={8}>
                          <span>Rotura de maquinaria:</span>{' '}
                          {damage.machinery_breakdown}
                        </StyledCardGrid>
                      )}
                      {damage.money_and_securities && (
                        <StyledCardGrid xs={24} md={12} lg={8}>
                          <span>Dinero y/o valores (dentro y fuera):</span>{' '}
                          {damage.money_and_securities}
                        </StyledCardGrid>
                      )}
                      {damage.neon_signs && (
                        <StyledCardGrid xs={24} md={12} lg={8}>
                          <span>Anuncios luminosos:</span> {damage.neon_signs}
                        </StyledCardGrid>
                      )}
                      {damage.tenant_civil_liability && (
                        <StyledCardGrid xs={24} md={12} lg={8}>
                          <span>Resp. civil arrendatarios:</span>{' '}
                          {damage.tenant_civil_liability}
                        </StyledCardGrid>
                      )}
                    </Row>
                  </Card>
                ))
              : null}
          </Space>
        </>
      )}
    </Card>
  );
};

const StyledCardGrid = styled(Col)`
  text-align: center;
  overflow-x: auto;
  padding: 10px;

  span {
    display: block;
    font-weight: bold;
  }
`;

export default ShowPolicy;
