/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import API, { baseURL } from 'api/index';
import { AxiosResponse } from 'axios';

interface GetPolicies {
  perPage: number;
  currentPage: number;
  q?: string;
  initial_date?: string;
  final_date?: string;
  payment_status?: string;
  users?: string[];
  subBranches?: string[];
  companies?: string[];
}

export const getPoliciesPayment = ({
  perPage,
  currentPage,
  q,
  initial_date,
  final_date,
  payment_status,
  users,
  subBranches,
  companies,
}: GetPolicies): Promise<AxiosResponse> => {
  let URI = `/policy/policy-payment?per_page=${perPage}&page=${currentPage}`;
  if (q) {
    URI += `&q=${q}`;
  }
  if (initial_date && final_date) {
    URI += `&initial_date=${initial_date}&final_date=${final_date}`;
  }
  if (payment_status) {
    URI += `&payment_status=${payment_status}`;
  }
  if (users?.length) {
    URI += `&users_array=${users
      .map((user) => `"${user.split('-')[0]}"`)
      .join()}`;
  }
  if (subBranches?.length) {
    URI += `&sub_branches_array=${subBranches
      .map((branch) => `"${branch}"`)
      .join()}`;
  }
  if (companies?.length) {
    URI += `&companies_array=${companies
      .map((company) => `"${company}"`)
      .join()}`;
  }

  return API.get(URI);
};

export const getPolicyPayment = (id: string): Promise<AxiosResponse> =>
  API.get(`/policy/policy-payment/${id}`);

export const editPolicyPayment = (
  id: string | number,
  data: unknown
): Promise<AxiosResponse> => API.put(`/policy/policy-payment/${id}`, data);

export const downloadPolicyPayments = ({
  payment_status,
  users,
  subBranches,
  companies,
  initial_date,
  final_date,
  q,
}: {
  payment_status: string;
  users?: string[];
  subBranches?: string[];
  companies?: string[];
  initial_date?: string;
  final_date?: string;
  q?: string;
}): Promise<AxiosResponse> => {
  let URI = `${baseURL}/policy/policy-payment/report`;
  if (payment_status) {
    URI += `?payment_status=${payment_status}`;
  }
  if (users?.length) {
    URI += `&users_array=${users
      .map((user) => `"${user.split('-')[0]}"`)
      .join()}`;
  }
  if (subBranches?.length) {
    URI += `&sub_branches_array=${subBranches
      .map((branch) => `"${branch}"`)
      .join()}`;
  }
  if (companies?.length) {
    URI += `&companies_array=${companies
      .map((company) => `"${company}"`)
      .join()}`;
  }

  if (q) {
    URI += `&q=${q}`;
  }

  if (initial_date && final_date) {
    URI += `&initial_date=${initial_date}&final_date=${final_date}`;
  }
  return API.get(URI, { responseType: 'blob' });
};

export const countPolicyPayments = ({
  initial_date,
  final_date,
}: any): Promise<AxiosResponse> => {
  let URI = '/policy/policy-payment/count';

  if (initial_date && final_date) {
    URI += `?initial_date=${initial_date}&final_date=${final_date}`;
  }

  return API.get(URI);
};
