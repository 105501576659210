/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
import API from 'api/index';
import { AxiosResponse } from 'axios';

export const sendLetter = (policyId: number): Promise<AxiosResponse> =>
  API.post(`/letter/send-letter/${policyId}`);

export const downloadLetter = (policyId: number): Promise<AxiosResponse> =>
  API.get(`/letter/download/${policyId}`);
