import React from 'react';

// Components
import Login from 'screens/Login';
import Dashboard from 'screens/Dashboard';
import User from 'screens/User';
import AddEditUser from 'screens/AddEditUser';
import Client from 'screens/Client';
import Policy from 'screens/Policy';
import Company from 'screens/Company';
import Group from 'screens/Group';
import PolicyPayment from 'screens/PolicyPayment';
import Letter from 'screens/Letter';
// import Blank from './Blank';

// Icons
import {
  HomeOutlined,
  UserOutlined,
  UserSwitchOutlined,
  SnippetsOutlined,
  BankOutlined,
  GroupOutlined,
  DollarCircleOutlined,
  EditOutlined,
  MailOutlined,
} from '@ant-design/icons';

export interface RouteType {
  path?: string;
  title: string;
  component?: React.ReactNode;
  Icon?: React.ReactNode;
  children?: Array<RouteType>;
}

const _guessRoutes = [
  {
    title: 'Login',
    component: Login,
  },
];

const _privateRoutes = [
  {
    path: '/',
    title: 'Panel de Administración',
    component: Dashboard,
    Icon: HomeOutlined,
  },
  {
    title: 'Usuarios',
    path: '/usuario',
    Icon: UserOutlined,
    component: User,
  },
  {
    title: 'Clientes',
    path: '/cliente',
    Icon: UserSwitchOutlined,
    component: Client,
  },
  {
    title: 'Pólizas',
    path: '/poliza',
    Icon: SnippetsOutlined,
    component: Policy,
  },
  {
    title: 'Cobranzas',
    path: '/cobranza',
    Icon: DollarCircleOutlined,
    children: [
      {
        title: 'Pagado',
        component: PolicyPayment,
      },
      {
        title: 'Liquidado',
        component: PolicyPayment,
      },
      {
        title: 'Consolidado',
        component: PolicyPayment,
      },
    ],
  },
  {
    title: 'Compañias',
    path: '/compañia',
    Icon: BankOutlined,
    component: Company,
  },
  {
    title: 'Grupos',
    path: '/grupo',
    Icon: GroupOutlined,
    component: Group,
  },
  {
    title: 'Cartas',
    path: '/carta',
    Icon: MailOutlined,
    component: Letter,
  },
  {
    title: 'Editar perfil',
    path: '/perfil',
    Icon: EditOutlined,
    component: AddEditUser,
  },
];

export const privateRoutesClients = ['/perfil', '/poliza', '/poliza/ver/:id'];

const addPath = (routes: Array<RouteType>): Array<RouteType> =>
  routes.map((route) => {
    const path =
      route.path ||
      `/${route.title}`
        .normalize('NFD')
        .replace(/[\u0300-\u036f]/g, '')
        .replace(/ /g, '-')
        .toLowerCase();
    if (route.children) {
      return {
        ...route,
        path,
        children: [...addPath(route.children)],
      };
    }
    return {
      ...route,
      path,
    };
  });

export const guessRoutes = addPath(_guessRoutes);
export const privateRoutes = addPath(_privateRoutes);

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const getPaths = (routes: Array<RouteType>, parentPath = ''): Array<any> =>
  routes.map((route) => {
    if (route.children) {
      return getPaths(route.children, parentPath + route.path);
    }
    return parentPath + route.path;
  });

const flatPaths = (routes: Array<RouteType>): Array<string> => {
  let paths = getPaths(routes);
  while (paths.some((e) => Array.isArray(e))) {
    paths = paths.flat();
  }
  return paths;
};

export const guessPaths = flatPaths(guessRoutes);
export const privatePaths = flatPaths(privateRoutes);
