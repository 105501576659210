/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
import API from 'api/index';
import { AxiosResponse } from 'axios';

interface GetPolicies {
  perPage: number;
  currentPage: number;
  client_id?: number;
  q?: string;
  initial_date?: string;
  final_date?: string;
  end_validity_date?: string;
  canceled?: boolean;
}

export const getPolicies = ({
  perPage,
  currentPage,
  client_id,
  q,
  initial_date,
  final_date,
  end_validity_date,
  canceled,
}: GetPolicies): Promise<AxiosResponse> => {
  let URI = `/policy?per_page=${perPage}&page=${currentPage}`;
  if (client_id) {
    URI += `&client_id=${client_id}`;
  }
  if (q) {
    URI += `&q=${q}`;
  }
  if (initial_date && final_date) {
    URI += `&initial_date=${initial_date}&final_date=${final_date}`;
  }
  if (end_validity_date) {
    URI += `&end_validity_date=${end_validity_date}`;
  }
  if (canceled) {
    URI += `&canceled=${canceled}`;
  }
  return API.get(URI);
};

export const createPolicy = (data: unknown): Promise<AxiosResponse> =>
  API.post(`/policy`, data);

export const getPolicy = (id: number | string): Promise<AxiosResponse> =>
  API.get(`/policy/${id}`);

export const deletePolicy = (id: number | string): Promise<AxiosResponse> =>
  API.delete(`/policy/${id}`);

export const updatePolicy = (
  id: number | string,
  data: unknown
): Promise<AxiosResponse> => API.put(`/policy/${id}`, data);

export const updateCanceledPolicy = (
  id: number | string,
  data: unknown
): Promise<AxiosResponse> => API.patch(`/policy/${id}`, data);

export const getPolicyByPolicyNumber = (
  policyNumber: string
): Promise<AxiosResponse> =>
  API.get(`/policy/policy-number/${encodeURIComponent(policyNumber)}`);

export const sendEmail = (data: unknown): Promise<AxiosResponse> =>
  API.post(`/policy/send-email`, data);

export const downloadPolicy = (policyId: number): Promise<AxiosResponse> =>
  API.get(`/policy/download/${policyId}`);

export const uploadImage = (
  id: string | number,
  data: any
): Promise<AxiosResponse> => {
  const formData = new FormData();
  formData.append('file', data.file);
  return API.post(`/policy/images/${id}`, formData, {
    headers: { 'Content-Type': 'multipart/form-data' },
  });
};

export const countPolicies = (): Promise<AxiosResponse> =>
  API.get('/policy/count');
