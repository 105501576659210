/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useState, useEffect, useRef } from 'react';
import { Form, Row, Col, Input, Button, Spin, Select, Typography } from 'antd';
import { useHistory, useParams, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { createUser, getUser, updateUser } from 'api/user';
import { handleError, handleSuccess, MessageTypes } from 'utils/handlers';
import { UserType } from 'interfaces';
import { updateUserAction } from 'store/actions/auth';

const { Option } = Select;
const { Title } = Typography;

const UserForm: React.FC = () => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState<boolean>(true);
  const [formstate, setFormState] = useState<UserType | undefined>(undefined);
  const history = useHistory();
  const params: { id: string } = useParams();
  const location = useLocation();
  const isMounted = useRef(true);

  const { user } = useSelector(({ auth }: any) => auth);

  useEffect(() => {
    return () => {
      isMounted.current = false;
    };
  }, []);

  useEffect(() => {
    if (params.id) {
      const queryApi = async () => {
        try {
          const response = await getUser(params.id);
          if (isMounted.current) {
            setFormState({
              ...response.data,
              profile_color: response.data.profile_color ?? '#000000',
            });
            setLoading(false);
          }
        } catch (err: any) {
          handleError({ err, type: MessageTypes.error });
          setTimeout(() => {
            history.push('/usuario');
          }, 3000);
        }
      };
      if (params.id == user.id) {
        history.push('/perfil');
        return;
      }
      queryApi();
    } else {
      if (location.pathname.split('/')[1] === 'perfil') {
        setFormState(user);
      }

      if (isMounted.current) {
        setLoading(false);
      }
    }
  }, [params]);

  const onFinish = async (values: any) => {
    try {
      await form.validateFields();
      setLoading(true);
      let response: any;
      if (params.id) {
        response = await updateUser(params.id, values);
      } else {
        if (location.pathname.split('/')[1] === 'perfil') {
          response = await updateUser(user.id, values);
          const { message, ...rest } = response.data;
          updateUserAction({ ...user, ...rest, default_password: 0 });
        } else {
          response = await createUser(values);
        }
      }
      handleSuccess(response);
      setLoading(true);
      setTimeout(() => {
        if (location.pathname.split('/')[1] === 'perfil') {
          if (user.role === '3') {
            history.push('/poliza');
            return;
          }
          history.push('/');
        } else {
          history.push('/usuario');
        }
      }, 3000);
    } catch (err: any) {
      setLoading(false);
      handleError({ err, type: MessageTypes.error });
    }
  };

  return (
    <Spin spinning={loading} tip="Cargando...">
      {!loading && (
        <>
          <Title level={2} style={{ marginBottom: '50px' }}>
            {location.pathname.split('/')[1] === 'perfil'
              ? 'Editar perfil'
              : params.id
              ? `Editar Usuario ${formstate?.first_name}`
              : 'Agregar Usuario'}
          </Title>
          <Row justify="center" align="middle">
            <Col xs={18}>
              <Form
                form={form}
                initialValues={formstate}
                layout="vertical"
                labelCol={{ xs: 12 }}
                onFinish={onFinish}
              >
                <Form.Item
                  label="Nombre(s)"
                  name="first_name"
                  rules={[
                    {
                      required: true,
                      type: 'string',
                    },
                  ]}
                >
                  <Input data-testid="first_name" />
                </Form.Item>

                <Form.Item
                  label="Apellido(s)"
                  name="last_name"
                  rules={[
                    {
                      required: true,
                      type: 'string',
                    },
                  ]}
                >
                  <Input data-testid="last_name" />
                </Form.Item>

                <Form.Item
                  label="Email"
                  name="email"
                  rules={[{ required: true, type: 'email' }]}
                >
                  <Input data-testid="email" />
                </Form.Item>

                {!params.id && (
                  <>
                    {location.pathname.split('/')[1] === 'perfil' && (
                      <Form.Item
                        label="Antigua contraseña"
                        name="old_password"
                        rules={[
                          {
                            required:
                              location.pathname.split('/')[1] === 'perfil' ||
                              params.id
                                ? false
                                : true,
                            min: 8,
                          },
                        ]}
                      >
                        <Input.Password />
                      </Form.Item>
                    )}
                    <Form.Item
                      label={
                        location.pathname.split('/')[1] === 'perfil'
                          ? 'Nueva contraseña'
                          : 'Contraseña'
                      }
                      name="password"
                      rules={[
                        {
                          required:
                            location.pathname.split('/')[1] === 'perfil' ||
                            params.id
                              ? false
                              : true,
                          min: 8,
                          max: 60,
                        },
                      ]}
                    >
                      <Input.Password />
                    </Form.Item>
                  </>
                )}

                {user.role !== '3' && (
                  <>
                    <Form.Item
                      label="Rol"
                      name="role"
                      rules={[
                        {
                          required: true,
                          type: 'string',
                        },
                      ]}
                    >
                      <Select
                        placeholder="-- Selecccionar --"
                        data-testid="role"
                      >
                        <Option value="2">Empleado</Option>
                        <Option value="1">Administrador</Option>
                      </Select>
                    </Form.Item>
                    <Form.Item label="Color de perfil" name="profile_color">
                      <Input type="color" style={{ width: '30%' }} />
                    </Form.Item>
                  </>
                )}

                <Form.Item>
                  <Button
                    type="primary"
                    htmlType="submit"
                    block
                    data-testid="btn-submit"
                  >
                    {location.pathname.split('/')[1] === 'perfil'
                      ? 'Editar perfil'
                      : params.id
                      ? `Editar Usuario`
                      : 'Crear Usuario'}
                  </Button>
                </Form.Item>
              </Form>
            </Col>
          </Row>
        </>
      )}
    </Spin>
  );
};

export default UserForm;
