import React from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Button, Result } from 'antd';
import styled from 'styled-components';

const Container = styled.div`
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const NotFound: React.FC = () => {
  const history = useHistory();
  const isAuthenticated = useSelector(
    ({ auth }: { auth: { isAuthenticated: boolean } }) => auth
  );
  return (
    <Container>
      <Result
        status="404"
        title="404"
        subTitle="Lo sentimos, la página a la que esta intentando acceder no existe."
      />
      <Button
        type="link"
        onClick={() => history.push(isAuthenticated ? '/' : '/login')}
      >
        Volver
      </Button>
    </Container>
  );
};

export default NotFound;
