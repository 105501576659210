/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useRef, useState } from 'react';
import { useHistory, useParams } from 'react-router';
import { useSelector } from 'react-redux';
import {
  Card,
  Col,
  Row,
  Form,
  Select,
  Input,
  Spin,
  Typography,
  Button,
} from 'antd';
import { handleError, handleSuccess, MessageTypes } from 'utils/handlers';
import { editPolicyPayment, getPolicyPayment } from 'api/policyPayment';
import { PolicyPaymentType } from 'interfaces';

const { Option } = Select;
const { Title } = Typography;

const EditPolicyPayment: React.FC = () => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState<boolean>(true);
  const [formstate, setFormState] = useState<PolicyPaymentType>({});

  const history = useHistory();
  const params: { id: string } = useParams();
  const { user } = useSelector((store) => store.auth);
  const isMounted = useRef(true);

  useEffect(() => {
    return () => {
      isMounted.current = false;
    };
  }, []);

  useEffect(() => {
    const queryAPI = async () => {
      try {
        const response = await getPolicyPayment(params.id);
        if (isMounted.current) {
          setFormState(response.data.policyPayment);
          setLoading(false);
        }
      } catch (err: any) {
        handleError({ err, type: MessageTypes.error });
        setTimeout(() => {
          history.goBack();
        }, 3000);
      }
    };
    queryAPI();
  }, []);

  const onFinish = async (values: any) => {
    try {
      setLoading(true);
      values.updated_by = user.id;

      const date = new Date();

      values.updated_date = `${date.getFullYear()}-${(
        '0' +
        (date.getMonth() + 1)
      ).slice(-2)}-${('0' + date.getDate()).slice(-2)}`;

      const response = await editPolicyPayment(params.id, values);
      if (response?.data) {
        handleSuccess(response);
      }
      setTimeout(() => {
        history.goBack();
      }, 3000);
    } catch (err: any) {
      setLoading(false);
      handleError({ err, type: MessageTypes.error });
    }
  };

  return (
    <Spin spinning={loading} tip="Cargando...">
      {!loading && (
        <>
          <Title level={3} style={{ marginBottom: '20px' }}>
            Editar Cobranza
          </Title>
          <Form
            initialValues={formstate}
            form={form}
            layout="vertical"
            onFinish={onFinish}
          >
            <Card
              title={`Pago ${formstate.payment_number}`}
              type="inner"
              key={0}
            >
              <Row align="middle" wrap gutter={[16, 8]}>
                <Col xs={24} md={8}>
                  <Form.Item label="Método de pago" name="payment_method">
                    <Select placeholder="--- Seleccionar --- ">
                      <Option value="efectivo">Efectivo</Option>
                      <Option value="tarjeta">Tarjeta</Option>
                      <Option value="transferencia">Transferencia</Option>
                      <Option value="cheque">Cheque</Option>
                    </Select>
                  </Form.Item>
                </Col>
                <Col xs={24} md={8}>
                  <Form.Item label="Monto" name="payment_amount">
                    <Input />
                  </Form.Item>
                </Col>
                <Col xs={24} md={8}>
                  <Form.Item label="Estado" name="payment_status">
                    <Select placeholder="--- Seleccionar --- ">
                      <Option value="pendiente">Pendiente</Option>
                      <Option value="pagado">Pagado</Option>
                      <Option value="liquidado">Liquidado</Option>
                      <Option value="consolidado">Consolidado</Option>
                    </Select>
                  </Form.Item>
                </Col>
                <Form.Item name="id">
                  <Input type="hidden" />
                </Form.Item>
              </Row>
              <Row align="middle">
                <Col xs={24} md={12}>
                  <Form.Item style={{ marginBottom: 0 }}>
                    <Button htmlType="submit" type="primary" block>
                      Editar pago
                    </Button>
                  </Form.Item>
                </Col>
              </Row>
            </Card>
          </Form>
        </>
      )}
    </Spin>
  );
};

export default EditPolicyPayment;
