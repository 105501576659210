/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useRef, useState } from 'react';
import {
  Form,
  Row,
  Col,
  Button,
  Spin,
  Space,
  Typography,
  Card,
  Input,
  Select,
  DatePicker,
  Upload,
  message,
  Checkbox,
  Modal,
} from 'antd';
import { DownloadOutlined, UploadOutlined } from '@ant-design/icons';
import moment from 'moment';
import { Redirect, useHistory, useParams } from 'react-router';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { handleError, handleSuccess, MessageTypes } from 'utils/handlers';
import { formatDate } from 'utils/functions';
import {
  createPolicy,
  getPolicy,
  getPolicyByPolicyNumber,
  updatePolicy,
  uploadImage,
  updateCanceledPolicy,
} from 'api/policy';
import { getCompanies } from 'api/company';
import { currentClientAction } from 'store/actions/app';
import {
  CarPolicyType,
  CompanyType,
  PolicyPaymentType,
  PolicyType,
  SpecsPolicyType,
} from 'interfaces';

// Components
// AutosBranch
import CarData from './AutosBranch/CarData';
import TouristData from './AutosBranch/TouristData';
// DanosBranch
import HouseData from './DanosBranch/HouseData';
import CompanyData from './DanosBranch/CompanyData';
import TransportData from './DanosBranch/TransportData';
import { baseURL } from 'api';

const { Title, Text } = Typography;
const { Option } = Select;
const { RangePicker } = DatePicker;
const dateFormat = 'DD-MM-YYYY';

interface FormStateInterface {
  policy: PolicyType;
  policy_payment: PolicyPaymentType;
  car_policy?: CarPolicyType;
  tourist_policy?: SpecsPolicyType;
}

const totalVehicleValues: any = {};

const AddEditPolicy: React.FC = () => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState<boolean>(true);
  const [formstate, setFormState] = useState<FormStateInterface | undefined>(
    undefined
  );
  const [branchs, setBranchs] = useState<{ branch: string; subBranch: string }>(
    {
      branch: '',
      subBranch: '',
    }
  );
  const [subbranchoptions, setSubBranchOptions] = useState<any[]>([]);
  const [touristOption, setTouristOption] = useState<string>('');
  const [paymentMethod, setPaymentMethod] = useState<string>('');
  const [specsData] = useState<any[]>([
    {
      key: 1,
      section: 1,
      specs: 'Colisión, Rotura de cristales',
      protected: 'AMPARADO/COVERED',
    },
    {
      key: 2,
      section: 2,
      specs: 'Incendio, robo total, y desastres naturales',
      protected: 'AMPARADO/COVERED',
    },
    {
      key: 3,
      section: 3,
      specs: 'Responsabilidad civil por daños a bienes de terceros',
      protected: 'LUC',
      sum_assured: '300,000',
    },
    {
      key: 4,
      section: 4,
      specs: 'Responsabilidad civil por daños a terceros en sus personas',
      protected: 'COVERED',
      sum_assured: 'COVERED',
    },
    {
      key: 5,
      section: 5,
      specs: 'Gasto medico ocupante del vehiculo asegurado',
      protected: '15,000',
      sum_assured: '25,000',
    },
    {
      key: 6,
      section: 6,
      specs: 'Extension territorial',
    },
    {
      key: 7,
      section: 7,
      specs: 'Otros',
      protected: 'COVERED',
      sum_assured: 'LEGAL ASSISTENCE',
    },
  ]);
  const [company, setCompany] = useState<CompanyType[]>([]);
  const [createdby, setCreatedBy] = useState<any>({});
  const [file, setFile] = useState<any[]>([]);
  const [cancel, setCancel] = useState<boolean>(false);

  const { currentClient, previousPolicyId } = useSelector((store) => store.app);
  const { user } = useSelector((store) => store.auth);

  const history = useHistory();
  const params: { id: string } = useParams();
  const isMounted = useRef(true);

  useEffect(() => {
    return () => {
      isMounted.current = false;
    };
  }, []);

  useEffect(() => {
    if (params.id) {
      const queryApi = async () => {
        try {
          const [response, responseCompanies] = await Promise.all([
            getPolicy(params.id),
            getCompanies(),
          ]);
          const policyData = response.data;
          policyData.policy.company_name = [policyData.policy.company_name];

          policyData.policy['range-validity'] = [];

          policyData.policy['range-validity'][0] = moment(
            formatDate(policyData.policy.start_validity),
            dateFormat
          );
          policyData.policy['range-validity'][1] = moment(
            formatDate(policyData.policy.end_validity),
            dateFormat
          );
          policyData.policy.capture = moment(
            formatDate(policyData.policy.capture),
            dateFormat
          );
          policyData.policy.completion_date = policyData.policy.completion_date
            ? moment(formatDate(policyData.policy.completion_date), dateFormat)
            : '';

          if (policyData.policy_payment) {
            policyData.policy_payment.forEach(
              (payment: PolicyPaymentType, index: number) => {
                policyData[`policy_payment_${index + 1}`] = payment;
              }
            );
          }

          if (policyData.specs) {
            specsData.forEach((specData) => {
              const policySpecifications = policyData.specs.find(
                (spec: SpecsPolicyType) => spec.name === specData.specs
              );
              policyData[`specs_policy_${specData.key}`] = {
                ...policySpecifications,
                name: specData.specs,
              };
            });
          }

          if (policyData.building_policy) {
            policyData.building_policy = {
              ...policyData.building_policy[0],
              earthquake:
                policyData.building_policy[0].earthquake !== null &&
                String(policyData.building_policy[0].earthquake),
              rh:
                policyData.building_policy[0].rh !== null &&
                String(policyData.building_policy[0].rh),
            };
          }

          if (policyData.risk_location) {
            policyData.risk_location = policyData.risk_location[0];
          }

          if (policyData.building_damage) {
            policyData.building_damage = policyData.building_damage[0];
          }

          if (isMounted.current) {
            if (policyData.car_policy) {
              let numberOfCategories = 0;
              let category = '';

              for (let i = 0; i < policyData.car_policy.length; i++) {
                policyData[`car_policy_${i + 1}`] = policyData.car_policy[i];
                if (policyData.policy.sub_branch === 'turistas') {
                  if (policyData.car_policy[i].license_number) {
                    category = `${++numberOfCategories}_license`;
                  } else {
                    category = `${++numberOfCategories}_vehicle`;
                  }
                }
              }
              policyData.policy[`tourist_policy_category`] = category;
              setTouristOption(category);
            }

            const imagePath = response.data.policy.policy_image_path;
            if (imagePath) {
              setFile([
                {
                  uid: imagePath,
                  name: `Descargar imagen actual`,
                  status: 'done',
                  url: `${baseURL}/policy/images/${imagePath}`,
                },
              ]);
            }

            setBranchs({
              branch: policyData.policy.branch,
              subBranch: policyData.policy.sub_branch,
            });
            setPaymentMethod(policyData.policy.payment_method);
            setCompany(responseCompanies.data.companies);
            setCreatedBy(response.data.user);
            setFormState(policyData);
            setCancel(policyData.policy.canceled === 1 ? true : false);
            setLoading(false);
          }
        } catch (err: any) {
          handleError({ err, type: MessageTypes.error });
          setTimeout(() => {
            history.push('/poliza');
          }, 3000);
        }
      };
      queryApi();
    } else {
      (async () => {
        try {
          const responseCompanies = await getCompanies();

          const policyData: any = {
            policy: {},
          };
          specsData.forEach((specData) => {
            policyData[`specs_policy_${specData.key}`] = {};
            policyData[`specs_policy_${specData.key}`].name = specData.specs;
            policyData[`specs_policy_${specData.key}`].protected =
              specData.protected;
            policyData[`specs_policy_${specData.key}`].sum_assured =
              specData.sum_assured;
          });
          const today = new Date();
          policyData.policy.capture = moment(
            `${today.getDate()}-${today.getMonth() + 1}-${today.getFullYear()}`,
            dateFormat
          );
          if (isMounted.current) {
            setCompany(responseCompanies.data.companies);
            setCreatedBy(user);
            setFormState(policyData);
            setLoading(false);
          }
        } catch (err: any) {
          handleError({ err, type: MessageTypes.error });
          setTimeout(() => {
            history.push('/poliza');
          }, 3000);
        }
      })();
    }
  }, [params]);

  const handleOnFieldsChange = (changedFields: any, values: any) => {
    if (Object.keys(changedFields)[0].includes('car_policy')) {
      const key = Object.keys(changedFields)[0];
      const value = changedFields[Object.keys(changedFields)[0]].value;

      if (value && !isNaN(value)) {
        totalVehicleValues[key] = value;
        let acc = 0;
        for (const key in totalVehicleValues) {
          acc += Number(totalVehicleValues[key]);
        }

        form.setFieldsValue({
          ...values,
          specs_policy_1: {
            ...values.specs_policy_1,
            sum_assured: acc,
          },
          specs_policy_2: {
            ...values.specs_policy_2,
            sum_assured: acc,
          },
        });
      }
    }

    if (Object.keys(changedFields)[0] === 'policy') {
      if (Object.keys(changedFields['policy'])[0] === 'sub_branch') {
        setBranchs({
          ...branchs,
          subBranch: changedFields['policy']['sub_branch'],
        });
      }
      if (Object.keys(changedFields['policy'])[0] === 'company_name') {
        form.setFieldsValue({
          ...values,
          policy: {
            ...values.policy,
            company_name: changedFields.policy.company_name.slice(-1),
          },
        });
      }
      if (Object.keys(changedFields['policy'])[0] === 'payment_method') {
        setPaymentMethod(changedFields['policy']['payment_method']);
      }
      if (Object.keys(changedFields['policy'])[0] === 'branch') {
        setBranchs({
          ...branchs,
          branch: changedFields['policy']['branch'],
        });
        if (values.policy.sub_branch) {
          form.setFieldsValue({
            ...values,
            policy: { ...values.policy, sub_branch: undefined },
          });
        }
        if (changedFields['policy']['branch'] === 'autos') {
          setSubBranchOptions([
            {
              value: 'turistas',
              name: 'Turistas',
            },
            {
              value: 'sedanes',
              name: 'Sedanes',
            },
            {
              value: 'pick up',
              name: 'Pick up',
            },
            {
              value: 'motocicleta',
              name: 'Motocicleta',
            },
          ]);
        } else if (changedFields['policy']['branch'] === 'daños') {
          setSubBranchOptions([
            {
              value: 'casas',
              name: 'Casas',
            },
            {
              value: 'empresarial',
              name: 'Empresarial',
            },
            {
              value: 'bote',
              name: 'Bote',
            },
            {
              value: 'r.c.',
              name: 'R.C.',
            },
            {
              value: 'transporte',
              name: 'Transporte',
            },
          ]);
        } else if (changedFields['policy']['branch'] === 'vida') {
          setSubBranchOptions([
            {
              value: 'gastos_medicos',
              name: 'Gastos médicos',
            },
            {
              value: 'vida',
              name: 'Vida',
            },
          ]);
        }
      }
      if (
        Object.keys(changedFields['policy'])[0] === 'tourist_policy_category'
      ) {
        const category = changedFields['policy']['tourist_policy_category'];
        setTouristOption(category);

        if (category.split('_')[1] === 'license') {
          const newValues: any = {
            specs_policy_1: {
              sum_assured: undefined,
            },
            specs_policy_2: {
              sum_assured: undefined,
            },
          };
          for (let i = 1; i <= 3; i++) {
            newValues[`car_policy_${i}`] = {
              brand: undefined,
              id: undefined,
              model: undefined,
              serial_number: undefined,
              value: undefined,
              year: undefined,
            };
          }
          form.setFieldsValue({ ...values, ...newValues });
        }

        if (category.split('_')[1] === 'vehicle') {
          const number = +category.split('_')[0];

          const carValues: any = {};
          const car_policy_reset = {
            brand: undefined,
            id: undefined,
            model: undefined,
            serial_number: undefined,
            value: undefined,
            year: undefined,
          };

          if (number === 1) {
            delete totalVehicleValues['car_policy_2'];
            delete totalVehicleValues['car_policy_3'];

            carValues.car_policy_2 = car_policy_reset;

            carValues.car_policy_3 = car_policy_reset;
          }
          if (number === 2) {
            delete totalVehicleValues['car_policy_3'];

            carValues.car_policy_3 = car_policy_reset;
          }

          let acc = 0;
          for (const key in totalVehicleValues) {
            acc += Number(totalVehicleValues[key]);
          }

          form.setFieldsValue({
            ...values,
            ...carValues,
            specs_policy_1: {
              ...values.specs_policy_1,
              sum_assured: acc,
            },
            specs_policy_2: {
              ...values.specs_policy_2,
              sum_assured: acc,
            },
          });
        }
      }

      if (Object.keys(changedFields['policy'])[0] === 'canceled') {
        const canceled = changedFields['policy']['canceled'];
        Modal.confirm({
          title: '¿Desea cancelar la póliza?',
          content: 'Una vez cancele la póliza no se podra volver a habilitar.',
          cancelText: 'Cancelar',
          onOk: async () => {
            try {
              const response = await updateCanceledPolicy(params.id, {
                canceled: canceled ? 1 : 0,
              });
              setCancel(true);
              handleSuccess(response);
            } catch (err: any) {
              handleError({ err, type: MessageTypes.error });
            }
          },
        });
      }
    }
  };

  const onFinish = async (values: any) => {
    try {
      setLoading(true);
      await form.validateFields();
      values.policy.company_name = values.policy.company_name[0];
      const specs_policy = [];
      const car_policy = [];
      const policy_payment = [];
      for await (const [key, value] of Object.entries(values)) {
        if (key.includes('specs_policy')) {
          specs_policy.push(value);
          delete values[key];
        } else if (key.includes('car_policy')) {
          car_policy.push(value);
          delete values[key];
        } else if (key.includes('policy_payment')) {
          policy_payment.push(value);
          delete values[key];
        }
      }
      if (specs_policy.length) {
        values.specs_policy = specs_policy;
      }
      if (car_policy.length) {
        values.car_policy = car_policy;
      }
      if (policy_payment.length) {
        let months = 0;
        policy_payment.forEach((policy: any, index: number) => {
          if (!params.id) {
            policy.payment_number = `${index + 1}/${policy_payment.length}`;
          }

          const date = new Date(
            values.policy['range-validity'][0].format('YYYY-MM-DD') +
              'T00:00:00'
          );
          date.setMonth(date.getMonth() + months);
          const year = date.getFullYear();
          const month = String(date.getMonth() + 1);
          const day = String(date.getDate());
          policy.payment_date = `${year}-${
            month.length === 1 ? `0${month}` : month
          }-${day.length === 1 ? `0${day}` : day}`;

          if (policy_payment.length === 2) {
            months += 6;
          } else if (policy_payment.length === 4) {
            months += 3;
          } else if (policy_payment.length === 12) {
            months += 1;
          }
        });
        values.policy_payment = policy_payment;
      }
      values.policy.client_id = currentClient.id;

      // dates
      values.policy.start_validity =
        values.policy['range-validity'][0].format('YYYY-MM-DD');
      values.policy.end_validity =
        values.policy['range-validity'][1].format('YYYY-MM-DD');
      values.policy.capture = values.policy.capture.format('YYYY-MM-DD');
      if (values.policy.completion_date) {
        values.policy.completion_date =
          values.policy.completion_date.format('YYYY-MM-DD');
      } else {
        values.policy.completion_date = null;
      }

      // created_by
      if (!params.id) values.policy.created_by = user.id;

      // building_policy.type
      if (
        values.policy.branch === 'daños' &&
        values.policy.sub_branch === 'casas'
      ) {
        values.building_policy.type = 'casas';
      } else if (
        values.policy.branch === 'daños' &&
        values.policy.sub_branch === 'empresarial'
      ) {
        values.building_policy.type = 'empresarial';
      }

      if (params.id) {
        const response = await updatePolicy(params.id, values);
        if (response?.data) {
          handleSuccess(response);
        }
      } else {
        const response = await createPolicy(values);
        if (response?.data) {
          handleSuccess(response);
        }
        if (file.length) {
          await uploadImage(response.data.policy.id, {
            file: file[0].file,
          });
        }
      }

      currentClientAction(null);
      setTimeout(() => {
        history.push('/poliza');
      }, 5000);
    } catch (err: any) {
      setLoading(false);
      handleError({ err, type: MessageTypes.error });
    }
  };

  const amountOfPayments = () => {
    let count = 0;
    if (paymentMethod === 'mensual') {
      count = 12;
    } else if (paymentMethod === 'trimestral') {
      count = 4;
    } else if (paymentMethod === 'semestral') {
      count = 2;
    } else if (paymentMethod === 'anual') {
      count = 1;
    }
    const children = [];
    for (let i = 1; i <= count; i++) {
      children.push(
        <Card title={`Pago N°${i}`} type="inner" key={i}>
          <Row align="middle" wrap gutter={[16, 8]}>
            <Col xs={24} md={8}>
              <Form.Item
                label="Método de pago"
                name={[`policy_payment_${i}`, 'payment_method']}
              >
                <Select placeholder="--- Seleccionar --- ">
                  <Option value="efectivo">Efectivo</Option>
                  <Option value="tarjeta">Tarjeta</Option>
                  <Option value="transferencia">Transferencia</Option>
                  <Option value="cheque">Cheque</Option>
                </Select>
              </Form.Item>
            </Col>
            <Col xs={24} md={8}>
              <Form.Item
                label="Monto"
                name={[`policy_payment_${i}`, 'payment_amount']}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col xs={24} md={8}>
              <Form.Item
                label="Estado"
                name={[`policy_payment_${i}`, 'payment_status']}
              >
                <Select placeholder="--- Seleccionar --- ">
                  <Option value="pendiente">Pendiente</Option>
                  <Option value="pagado">Pagado</Option>
                  <Option value="liquidado">Liquidado</Option>
                  <Option value="consolidaddo">Consolidaddo</Option>
                </Select>
              </Form.Item>
            </Col>
            <Form.Item name={[`policy_payment_${i}`, 'id']}>
              <Input type="hidden" />
            </Form.Item>
          </Row>
        </Card>
      );
    }
    return children;
  };

  const handleBeforeUpload = (file: any) => {
    if (
      file.type === 'image/png' ||
      file.type === 'image/jpg' ||
      file.type === 'image/jpeg' ||
      file.type === 'application/pdf'
    )
      return true;

    message.error(
      `${file.name} no es un archivo valido. Archivos aceptados: .png .jpg .jpeg .pdf`
    );
    return Upload.LIST_IGNORE;
  };

  const handleCustomRequest = async (options: any) => {
    let response = undefined;
    if (params.id) {
      response = await uploadImage(params.id, {
        file: options.file,
      });
      message.info(response.data.message);
    }
    const file = {
      uid: options.file.uid,
      name: params.id ? `Descargar imagen actual` : options.file.name,
      status: 'done',
      url: response && `${baseURL}/policy/images/${response.data.url}`,
      file: options.file,
    };
    setFile([file]);
  };

  if (!currentClient) {
    return <Redirect to="/poliza" />;
  }

  return (
    <Spin spinning={loading} tip="Cargando...">
      {!loading && (
        <>
          <Row justify="start" align="middle" wrap gutter={[8, 8]}>
            <Col md={24} lg={18}>
              <Title level={3}>
                {params.id
                  ? `Editar póliza ${
                      currentClient
                        ? `del cliente: ${currentClient?.first_name} ${currentClient?.last_name}`
                        : ''
                    } `
                  : `Crear póliza ${
                      currentClient
                        ? `para cliente: ${currentClient?.first_name} ${currentClient?.last_name}`
                        : ''
                    }`}
              </Title>
            </Col>
            <Col md={24} lg={6}>
              <Upload
                beforeUpload={handleBeforeUpload}
                customRequest={handleCustomRequest}
                maxCount={1}
                fileList={file}
                showUploadList={
                  params.id
                    ? {
                        downloadIcon: <DownloadOutlined />,
                        showDownloadIcon: true,
                        showRemoveIcon: false,
                      }
                    : {}
                }
              >
                <Button icon={<UploadOutlined />}>
                  {file.length ? 'Seleccionar otra imagen' : 'Subir imagen'}
                </Button>
              </Upload>
            </Col>
          </Row>

          <Form
            initialValues={formstate}
            form={form}
            layout="vertical"
            onFinish={onFinish}
            onValuesChange={handleOnFieldsChange}
          >
            <Space direction="vertical">
              <Card title="Datos de la Póliza">
                <Row align="middle" wrap gutter={[16, 8]}>
                  <Col xs={24} md={12} lg={8}>
                    <Form.Item
                      label="Ramo"
                      name={['policy', 'branch']}
                      rules={[
                        {
                          required: true,
                          type: 'string',
                        },
                      ]}
                    >
                      <Select
                        placeholder="--- Seleccionar --- "
                        disabled={params.id ? true : undefined}
                      >
                        <Option value="autos">Autos</Option>
                        <Option value="daños">Daños</Option>
                        <Option value="vida">Vida</Option>
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col xs={24} md={12} lg={8}>
                    <Form.Item
                      label="Sub Ramo"
                      name={['policy', 'sub_branch']}
                      rules={[
                        {
                          required: true,
                          type: 'string',
                        },
                      ]}
                    >
                      <Select
                        placeholder="--- Seleccionar --- "
                        disabled={params.id ? true : undefined}
                      >
                        {subbranchoptions.map((option, index) => (
                          <Option value={option.value} key={index}>
                            {option.name}
                          </Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col xs={24} md={12} lg={8}>
                    <Form.Item
                      label="Número de Cliente"
                      name={['policy', 'client_number']}
                    >
                      <Input />
                    </Form.Item>
                  </Col>
                  <Col xs={24} md={12} lg={8}>
                    <Form.Item
                      label="Compañia"
                      name={['policy', 'company_name']}
                      rules={[
                        {
                          required: true,
                        },
                      ]}
                    >
                      <Select placeholder="--- Buscar --- " mode="multiple">
                        {company.map((company) => (
                          <Option value={company.name} key={company.id}>
                            {company.name}
                          </Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col xs={24} md={12} lg={8}>
                    <Form.Item
                      label="Número de Póliza"
                      name={['policy', 'policy_number']}
                      rules={[
                        {
                          required: true,
                          type: 'string',
                        },
                        () => ({
                          async validator(_, value) {
                            if (!value) return;
                            try {
                              const response: any =
                                await getPolicyByPolicyNumber(value);
                              if (response.data.policy.length) {
                                if (
                                  response.data.policy[0].id !=
                                  formstate?.policy.id
                                ) {
                                  return Promise.reject(
                                    <div style={{ color: '#bc0000' }}>
                                      Ya existe una póliza con este número de
                                      póliza.{' '}
                                      <Text
                                        type="success"
                                        style={{ display: 'block' }}
                                      >
                                        <Link
                                          to={`/poliza/ver/${response.data.policy[0].id}`}
                                        >
                                          Ver Póliza{' '}
                                          {
                                            response.data.policy[0]
                                              .policy_number
                                          }
                                        </Link>
                                      </Text>
                                    </div>
                                  );
                                }
                              }
                            } catch (err: any) {
                              handleError({ err, type: MessageTypes.error });
                            }
                          },
                        }),
                      ]}
                    >
                      <Input />
                    </Form.Item>
                  </Col>
                  {previousPolicyId !== null && (
                    <Col xs={24} md={12} lg={8}>
                      <p>
                        Póliza anterior:
                        <Button type="link">
                          <Link to={`/poliza/ver/${previousPolicyId.id}`}>
                            {previousPolicyId.policyNumber}
                          </Link>
                        </Button>
                      </p>
                    </Col>
                  )}
                  {params.id && (
                    <Col xs={24} md={12} lg={8}>
                      <Form.Item
                        name={['policy', 'canceled']}
                        valuePropName="checked"
                      >
                        <Checkbox disabled={cancel}>Cancelar póliza</Checkbox>
                      </Form.Item>
                    </Col>
                  )}
                  <Col xs={24} md={12} lg={8}>
                    <p>
                      Póliza creada por:
                      <span style={{ display: 'block', fontWeight: 'bold' }}>
                        {createdby.first_name + ' ' + createdby.last_name}
                      </span>
                    </p>
                  </Col>
                </Row>
              </Card>
              {/* branch: autos */}
              {/* car_policy */}
              {branchs.branch === 'autos' &&
                (branchs.subBranch === 'sedanes' ||
                  branchs.subBranch === 'motocicleta' ||
                  branchs.subBranch === 'pick up') && <CarData />}
              {/* car_policy and specs_policy */}
              {branchs.branch === 'autos' &&
                branchs.subBranch === 'turistas' && (
                  <TouristData
                    touristOption={touristOption}
                    specsData={specsData}
                  />
                )}
              {/* branch: daños */}
              {branchs.branch === 'daños' && branchs.subBranch === 'casas' && (
                <HouseData />
              )}
              {branchs.branch === 'daños' &&
                branchs.subBranch === 'empresarial' && <CompanyData />}
              {branchs.branch === 'daños' &&
                branchs.subBranch === 'transporte' && <TransportData />}

              <Card title="Vigencias">
                <Row align="middle" wrap gutter={[16, 8]}>
                  <Col xs={24} md={16}>
                    <Form.Item
                      label="Vigencias"
                      name={['policy', 'range-validity']}
                      rules={[
                        {
                          required: true,
                        },
                      ]}
                    >
                      <RangePicker
                        format={dateFormat}
                        style={{ width: '100%' }}
                      />
                    </Form.Item>
                  </Col>
                  <Col xs={24} md={8}>
                    <Form.Item
                      label="Captura"
                      name={['policy', 'capture']}
                      rules={[
                        {
                          required: true,
                        },
                      ]}
                    >
                      <DatePicker
                        style={{ width: '100%' }}
                        format={dateFormat}
                        disabled
                      />
                    </Form.Item>
                  </Col>
                </Row>
              </Card>
              <Card title="Importes">
                <Row align="middle" wrap gutter={[16, 8]}>
                  <Col xs={24} md={12} lg={8}>
                    <Form.Item
                      label="Prima Neta"
                      name={['policy', 'net_premium']}
                    >
                      <Input />
                    </Form.Item>
                  </Col>
                  <Col xs={24} md={12} lg={8}>
                    <Form.Item label="Recargas" name={['policy', 'overload']}>
                      <Input />
                    </Form.Item>
                  </Col>
                  <Col xs={24} md={12} lg={8}>
                    <Form.Item
                      label="Derecho Póliza"
                      name={['policy', 'law_policy']}
                    >
                      <Input />
                    </Form.Item>
                  </Col>
                  <Col xs={24} md={12} lg={8}>
                    <Form.Item label="IVA" name={['policy', 'vat']}>
                      <Input />
                    </Form.Item>
                  </Col>
                  <Col xs={24} md={12} lg={8}>
                    <Form.Item label="Total" name={['policy', 'total']}>
                      <Input />
                    </Form.Item>
                  </Col>
                  <Col xs={24} md={12} lg={8}>
                    <Form.Item label="Moneda" name={['policy', 'currency']}>
                      <Select placeholder="--- Seleccionar --- ">
                        <Option value="pesos">Pesos</Option>
                        <Option value="dolares">Dólares</Option>
                      </Select>
                    </Form.Item>
                  </Col>
                </Row>
              </Card>
              <Card title="Pagos">
                <Row align="middle" wrap gutter={[16, 8]}>
                  <Col xs={24}>
                    <Form.Item
                      label="Forma de Pago"
                      name={['policy', 'payment_method']}
                      rules={[
                        {
                          required: true,
                        },
                      ]}
                    >
                      <Select
                        placeholder="--- Seleccionar --- "
                        disabled={params.id ? true : undefined}
                      >
                        <Option value="mensual">Mensual</Option>
                        <Option value="trimestral">Trimestral</Option>
                        <Option value="semestral">Semestral</Option>
                        <Option value="anual">Anual</Option>
                      </Select>
                    </Form.Item>
                  </Col>
                </Row>
                <Space direction="vertical" style={{ width: '100%' }}>
                  {amountOfPayments()}
                </Space>
              </Card>
              <Card title="Documentación">
                <Row align="middle" wrap gutter={[16, 8]}>
                  <Col xs={24}>
                    <Form.Item
                      label="Descripción"
                      name={['policy', 'description']}
                      style={{ marginBottom: 0 }}
                    >
                      <Input.TextArea
                        style={{
                          width: '100%',
                          height: '150px',
                          resize: 'none',
                        }}
                      />
                    </Form.Item>
                  </Col>
                </Row>
              </Card>
              <Card title="Otra Información">
                <Row align="middle" wrap gutter={[16, 8]}>
                  <Col xs={24} md={12} lg={8}>
                    <Form.Item label="Motivo" name={['policy', 'reason']}>
                      <Input />
                    </Form.Item>
                  </Col>
                  <Col xs={24} md={12} lg={8}>
                    <Form.Item
                      label="Fecha de Cancelación"
                      name={['policy', 'completion_date']}
                    >
                      <DatePicker
                        style={{ width: '100%' }}
                        format={dateFormat}
                      />
                    </Form.Item>
                  </Col>
                </Row>
              </Card>

              <Row align="middle" gutter={[16, 8]}>
                <Col xs={24} md={12}>
                  <Form.Item>
                    <Button htmlType="submit" type="primary" block>
                      {params.id ? 'Editar Póliza' : 'Crear Póliza'}
                    </Button>
                  </Form.Item>
                </Col>
              </Row>
            </Space>
          </Form>
        </>
      )}
    </Spin>
  );
};

export default AddEditPolicy;
