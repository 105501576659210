/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
import React, { useEffect, useRef, useState } from 'react';
import { Button, Col, Row, Space, Typography, Modal, Switch } from 'antd';
import { Link, useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import TableComponent from 'components/TableComponent';
import SearchComponent from 'components/SearchComponent';
import DateRangeFilterComponent from 'components/DateRangeFilterComponent';
import { deletePolicy, getPolicies } from 'api/policy';
import { formatDate } from 'utils/functions';
import { PolicyType } from 'interfaces';
import { handleError, handleSuccess, MessageTypes } from 'utils/handlers';
import {
  currentClientAction,
  setPreviousPolicyIdAction,
} from 'store/actions/app';

const { Text, Title } = Typography;

const Policy: React.FC = () => {
  const [loading, setLoading] = useState<boolean>(true);
  const [showmodal, setShowModal] = useState<boolean>(false);
  const [policies, setPolicies] = useState<PolicyType[]>([]);
  const [deletepolicy, setDeletePolicy] = useState<PolicyType | null>(null);
  const [total, setTotal] = useState<number>(1);
  const [perpage, setPerPage] = useState<number>(10);
  const [currentpage, setCurrentPage] = useState<number>(1);
  const [searchText, setSearchText] = useState<string>('');
  const [dates, setDates] = useState<{
    initial_date?: string;
    final_date?: string;
  } | null>(null);
  const [findByText, setfindByText] = useState<boolean>(false);
  const [canceled, setCanceled] = useState<boolean>(false);

  const { currentClient } = useSelector((store) => store.app);
  const { user } = useSelector((store) => store.auth);

  const history = useHistory();
  const isMounted = useRef(true);

  useEffect(() => {
    return () => {
      isMounted.current = false;
    };
  }, []);

  useEffect(() => {
    const queryApi = async () => {
      try {
        setLoading(true);
        await sortPolicies();
      } catch (err: any) {
        handleError({ err, type: MessageTypes.error });
        setTimeout(() => {
          history.push('/');
        }, 3000);
      }
    };
    queryApi();
  }, [currentpage, currentClient]);

  useEffect(() => {
    setCurrentPage(1);
    if (findByText && currentpage === 1) {
      (async () => {
        try {
          setLoading(true);
          await sortPolicies();
        } catch (err: any) {
          handleError({ err, type: MessageTypes.error });
          setTimeout(() => {
            history.push('/');
          }, 3000);
        }
      })();
    }
  }, [searchText, dates, perpage, canceled]);

  const columns = [
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
    },
    {
      title: 'Nombre',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Sub Ramo',
      dataIndex: 'sub_branch',
      key: 'sub_branch',
    },
    {
      title: 'Núm. póliza',
      dataIndex: 'policy_number',
      key: 'policy_number',
    },
    {
      title: 'Ubicación Riesgo / Datos Vehículo',
      dataIndex: 'information',
      key: 'information',
    },
    {
      title: 'Descripción',
      dataIndex: 'description',
      key: 'description',
    },
    {
      title: 'Inicio Vig',
      dataIndex: 'start_validity',
      key: 'start_validity',
    },
    {
      title: 'Fin Vig',
      dataIndex: 'end_validity',
      key: 'end_validity',
    },
    {
      title: 'Cancelada',
      dataIndex: 'canceled',
      key: 'canceled',
      render(canceled: string | number) {
        return (
          <>
            {canceled == 1 ? (
              <Text type="danger">Cancelada</Text>
            ) : (
              <Text type="success">Activa</Text>
            )}
          </>
        );
      },
    },
    {
      title: 'Creado por:',
      dataIndex: 'created_by',
      key: 'created_by',
    },
    {
      title: 'Estado',
      dataIndex: 'is_available',
      key: 'is_available',
      render(isAvailable: string | number) {
        return (
          <>
            {isAvailable == 0 ? (
              <Text type="danger">Eliminado</Text>
            ) : (
              <Text type="success">Disponible</Text>
            )}
          </>
        );
      },
    },
    {
      title: 'Acciones',
      dataIndex: 'actions',
      key: 'actions',
      render(_: unknown, policy: any) {
        return (
          <Space
            direction="horizontal"
            size="small"
            style={{ width: '100%' }}
            align="baseline"
          >
            {user?.role !== '3' && (
              <Link
                to={`/poliza/editar/${policy.id}`}
                onClick={() => {
                  currentClientAction({
                    first_name: policy.first_name,
                    last_name: policy.last_name,
                    id: policy.client_id,
                  });

                  const policyIndex = policies.findIndex(
                    (policyData) => policyData.id === policy.id
                  );
                  if (policies[policyIndex + 1]) {
                    setPreviousPolicyIdAction({
                      policyNumber: policies[policyIndex + 1].policy_number,
                      id: policies[policyIndex + 1].id,
                    });
                  } else {
                    setPreviousPolicyIdAction(null);
                  }
                }}
              >
                <Button type="link" block>
                  Editar
                </Button>
              </Link>
            )}

            <Link to={`/poliza/ver/${policy.id}`}>
              <Button type="link" block>
                Detalles
              </Button>
            </Link>

            {user?.role !== '3' && (
              <Button
                type="link"
                danger
                block
                disabled={!policy.is_available && true}
                onClick={() => handleModal('open', policy)}
              >
                Eliminar
              </Button>
            )}
          </Space>
        );
      },
    },
  ];

  const sortPolicies = async () => {
    let client_id = undefined;
    if (currentClient) {
      client_id = currentClient.id;
    }
    if (user.role === '3') {
      client_id = user.client.id;
    }

    const response = await getPolicies({
      perPage: perpage,
      currentPage: currentpage,
      client_id,
      q: searchText.length ? searchText : '',
      initial_date: dates?.initial_date,
      final_date: dates?.final_date,
      canceled,
    });

    if (response?.data?.totalPolicies === 0) {
      if (isMounted.current) {
        setPolicies([]);
        setTotal(1);
        setfindByText(true);
        setLoading(false);
        return;
      }
    }

    const data = response.data.policy.map((policy: any) => {
      let information = '';

      if (policy.car_policy.length) {
        for (const data of policy.car_policy) {
          information += `
          ${data.brand || ''} 
          ${data.model || ''} 
          ${data.year || ''} 
          ${data.license_number || ''},`;
        }

        information = information.slice(0, -1);
      }

      if (policy.risk_location.length) {
        for (const data of policy.risk_location) {
          information += `
          ${data.country || ''} 
          ${data.state || ''} 
          ${data.city || ''}
          ${data.street || ''} 
          ${data.neighborhood || ''}`;
        }
      }

      return {
        ...policy,
        name: `${policy.first_name} ${policy.last_name}`,
        information,
        start_validity: formatDate(policy.start_validity),
        end_validity: formatDate(policy.end_validity),
        key: policy.id,
      };
    });

    if (isMounted.current) {
      setTotal(response.data.totalPolicies);
      setPolicies(data);
      setfindByText(true);
      setLoading(false);
    }
  };

  const handleModal = (action: string, policy?: PolicyType) => {
    if (action === 'open') {
      setDeletePolicy(policy!);
      setShowModal(true);
    } else {
      setDeletePolicy(null);
      setShowModal(false);
    }
  };
  const handleModalOk = async () => {
    try {
      setLoading(true);
      const response = await deletePolicy(deletepolicy!.id!);
      const policyDeleted = deletepolicy;
      policyDeleted!.is_available = 0;
      setPolicies((policies) =>
        policies.map((policy) =>
          policy.id === deletepolicy!.id ? policyDeleted! : policy
        )
      );
      setLoading(false);
      handleSuccess(response);
    } catch (err: any) {
      handleError({ err, type: MessageTypes.error });
    }
    setShowModal(false);
  };

  return (
    <>
      <Title level={3}>
        Pólizas{' '}
        {currentClient &&
          `Cliente: ${currentClient?.first_name} ${currentClient?.last_name}`}
      </Title>
      <Row
        justify="start"
        align="middle"
        wrap
        gutter={[8, 8]}
        style={{ marginBottom: '10px' }}
      >
        {currentClient && (
          <Col xs={24} md={8} xl={6}>
            <Link
              to={`/poliza/crear`}
              onClick={() => {
                if (policies[0]?.id) {
                  setPreviousPolicyIdAction({
                    policyNumber: policies[0]?.policy_number,
                    id: policies[0]?.id,
                  });
                } else {
                  setPreviousPolicyIdAction(null);
                }
              }}
            >
              <Button type="primary" block disabled={loading}>
                Crear póliza
              </Button>
            </Link>
          </Col>
        )}
        <Col xs={24} md={8} xl={6}>
          <SearchComponent setSearchText={setSearchText} />
        </Col>
        <Col xs={24} md={8} xl={6}>
          <DateRangeFilterComponent setDates={setDates} />
        </Col>
        <Col xs={24} md={8} xl={6}>
          <Space align="center">
            Cancelada:{' '}
            <Switch
              onChange={() => {
                setCanceled(!canceled);
              }}
            />
          </Space>
        </Col>
      </Row>

      <TableComponent
        columns={columns}
        loading={loading}
        dataSource={policies}
        pagination={{
          position: ['bottomLeft'],
          current: currentpage,
          pageSize: perpage,
          total,
          showSizeChanger: true,
          onChange: (currentPage: number, pageSize: number) => {
            setPerPage(pageSize);
            setCurrentPage(currentPage);
          },
        }}
        style={{ whiteSpace: 'nowrap', width: 'auto' }}
      />
      <Modal
        title={`Eliminar ${location.pathname.split('/')[1]}`}
        centered
        visible={showmodal}
        confirmLoading={loading}
        onCancel={() => handleModal('close')}
        onOk={handleModalOk}
      >
        <p>
          ¿Estas seguro que quieres eliminar la póliza con el ID{' '}
          {deletepolicy?.id}?
        </p>
      </Modal>
    </>
  );
};

export default Policy;
