/* eslint-disable @typescript-eslint/no-non-null-assertion */
import React, { useState, useEffect } from 'react';
import { Layout, Menu, message } from 'antd';
import { Link, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { LogoutOutlined } from '@ant-design/icons';
import { logoutAction } from 'store/actions/auth';
import { clearAppAction } from 'store/actions/app';
import { UserType } from 'store/reducers/auth';
import { currentClientAction } from 'store/actions/app';
import {
  privateRoutes,
  privateRoutesClients,
} from 'components/Navigation/routes';

const { Sider } = Layout;

const Sidebar: React.FC = () => {
  const [selectedKeys, setSelectedKeys] = useState<string[]>([]);
  const user = useSelector(
    ({ auth }: { auth: { user: UserType } }) => auth.user
  );
  const collapsedSidebar = useSelector(({ app }) => app.collapsedSidebar);
  const location = useLocation();

  const handleLogout = () => {
    clearAppAction();
    logoutAction();
  };

  const getSelectedKeys = () => {
    const currentPath = location.pathname;
    const splitedPath = currentPath.split('/').filter((x) => x.length);
    return splitedPath.map(
      (s, is) => `/${splitedPath.filter((a, i) => i <= is).join('/')}`
    );
  };

  useEffect(() => {
    const selected = getSelectedKeys();
    setSelectedKeys(selected);
    document.title = 'SIS';
    setTimeout(() => {
      message.destroy();
    });
    // eslint-disable-next-line
  }, [location]);

  return (
    <SiderContainer
      id="sider"
      width={250}
      trigger={null}
      collapsible
      collapsed={collapsedSidebar}
    >
      <LogoContainer>
        <LogoHeader to="/">
          <>{collapsedSidebar ? 'S' : 'SIS'}</>
        </LogoHeader>
        <Divider />
        <UserInfoContainer>
          {collapsedSidebar ? (
            <PrimaryBadgeText>
              {`${user?.first_name?.[0]}${user?.last_name[0] || ''}`}
            </PrimaryBadgeText>
          ) : (
            <PrimaryText>
              {`${user?.first_name} ${user?.last_name}`}
            </PrimaryText>
          )}
        </UserInfoContainer>
      </LogoContainer>
      <Divider />
      <Menu mode="inline" selectedKeys={selectedKeys}>
        {privateRoutes.map((Route) => {
          if (user?.role === '3' && !privateRoutesClients.includes(Route.path!))
            return null;

          if (Route.children?.length) {
            return (
              <SubMenu
                key={Route.path}
                icon={Route.Icon && <Route.Icon />}
                title={Route.title}
              >
                {Route.children.map((Child) => (
                  <MenuItem
                    key={Route.path! + Child.path!}
                    icon={Child.Icon && <Child.Icon />}
                  >
                    <LabelNameLink to={Route.path! + Child.path!}>
                      {Child.title}
                    </LabelNameLink>
                  </MenuItem>
                ))}
              </SubMenu>
            );
          }
          return (
            <MenuItem key={Route.path} icon={Route.Icon && <Route.Icon />}>
              <LabelNameLink
                onClick={() => {
                  if (Route.path === '/poliza' && user?.role !== '3')
                    currentClientAction(null);
                }}
                to={Route.path!}
              >
                {Route.title}
              </LabelNameLink>
            </MenuItem>
          );
        })}
      </Menu>
      <Menu style={{ position: 'relative', marginTop: '60px', width: '100%' }}>
        <MenuItem
          key="logout"
          onClick={handleLogout}
          style={{ cursor: 'pointer' }}
          icon={<LogoutOutlined />}
        >
          <LabelNameLink to="">Cerrar sesión</LabelNameLink>
        </MenuItem>
      </Menu>
    </SiderContainer>
  );
};

const SiderContainer = styled(Sider)`
  overflow-y: auto;
  overflow-x: hidden;
  height: 100vh;
  position: fixed;
  left: 0;
  box-shadow: 1px 0 8px 0 rgba(125, 140, 166, 0.08);
  border: solid 1px #d8dde6;
  background-color: #fff;
  z-index: 2;

  .ant-message {
    padding-left: 250px;
    transition: padding-left 0.3s cubic-bezier(0.2, 0, 0, 1) 0s;
  }

  &.ant-layout-sider-collapsed {
    .ant-message {
      padding-left: 80px;
    }
  }
`;

const LogoContainer = styled.div``;

const LogoHeader = styled(Link)`
  height: 55px;
  margin: 14px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  font-size: 35px;
  font-weight: bold;
`;

const UserInfoContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const PrimaryText = styled.h2`
  font-family: Lato;
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.57;
  letter-spacing: normal;
  color: @primary-text;
  margin: 0;
  text-align: center;
`;

const PrimaryBadgeText = styled(PrimaryText)`
  color: #ffffff;
  line-height: 30px;
  width: 30px;
  height: 30px;
  border-radius: 15px;
  background: #000000;
`;

const LabelNameLink = styled(Link)`
  height: 22px;
  font-family: Lato;
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.57;
  letter-spacing: normal;
  color: #fff;
  /* margin-left: 18px; */
`;

const SubMenu = styled(Menu.SubMenu)`
  .ant-menu-submenu-title span {
    font-family: Lato;
    font-size: 14px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.57;
    letter-spacing: normal;
    color: #2d3f5d;
  }

  &.ant-menu-submenu-selected > .ant-menu-submenu-title {
    background-color: #e6f5ff !important;
    span,
    .ant-menu-submenu-arrow {
      color: #006dff !important;
    }
  }
  /* &.ant-menu-submenu-selected > .ant-menu-submenu-title :after {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    border-right: 3px solid #006dff;
  } */
`;

const MenuItem = styled(Menu.Item)`
  display: flex;
  align-items: center;
`;

const Divider = styled.div`
  border-bottom-color: #d8dde6;
  border-bottom-width: 1px;
  border-bottom-style: solid;
  margin: 10px 24px 10px 24px;
`;

export default Sidebar;
