import API from 'api/index';
import { AxiosResponse } from 'axios';

export const getCompanies = (
  perPage?: number,
  currentPage?: number
): Promise<AxiosResponse> => {
  let URI = '/company';
  if (perPage && currentPage) {
    URI = `/company?per_page=${perPage}&page=${currentPage}`;
  }
  return API.get(URI);
};

export const deleteCompany = (
  companyId: string | number
): Promise<AxiosResponse> => API.delete(`/company/${companyId}`);

export const getCompany = (id: string | number): Promise<AxiosResponse> =>
  API.get(`/company/${id}`);

export const createCompany = (company: unknown): Promise<AxiosResponse> =>
  API.post(`/company`, company);

export const updateCompany = (
  id: string | number,
  company: unknown
): Promise<AxiosResponse> => API.put(`/company/${id}`, company);
