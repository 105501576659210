import API from 'api/index';
import { AxiosResponse } from 'axios';

export interface LoginValuesTypes {
  email: string;
  password: string;
}

export const login = (values: LoginValuesTypes): Promise<AxiosResponse> =>
  API.post('/auth', values);

export const refreshToken = (token: string): Promise<AxiosResponse> =>
  API.post('/auth/refresh-token', { token });
