/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
import React, { useEffect, useRef, useState } from 'react';
import { useHistory, Link, useLocation } from 'react-router-dom';
import { Button, Modal, Space, Typography, Row, Col } from 'antd';
import { useSelector } from 'react-redux';
import { handleError, MessageTypes, handleSuccess } from 'utils/handlers';
import { getClients, deleteClient } from 'api/client';
import { getUsers, deleteUser } from 'api/user';
import TableComponent from 'components/TableComponent';
import SearchComponent from 'components/SearchComponent';
import { ClientType, UserType } from 'interfaces';
import { currentClientAction } from 'store/actions/app';

const { Text, Title } = Typography;

const UserClientTable: React.FC = () => {
  const [loading, setLoading] = useState<boolean>(true);
  const [users, setUsers] = useState<UserType[]>([]);
  const [total, setTotal] = useState<number>(1);
  const [showmodal, setShowModal] = useState<boolean>(false);
  const [deleteuser, setDeleteUser] = useState<UserType | null>(null);
  const [userperpage, setPerPage] = useState<number>(10);
  const [currentpage, setCurrentPage] = useState<number>(1);
  const [searchText, setSearchText] = useState<string>('');
  const [findByText, setfindByText] = useState<boolean>(false);

  const history = useHistory();
  const location = useLocation();
  const isMounted = useRef(true);
  const { user } = useSelector(({ auth }: any) => auth);

  useEffect(() => {
    return () => {
      isMounted.current = false;
    };
  }, []);

  useEffect(() => {
    const queryApi = async () => {
      try {
        setLoading(true);
        await sortUsers();
      } catch (err: any) {
        handleError({ err, type: MessageTypes.error });
        setTimeout(() => {
          history.push('/');
        }, 3000);
      }
    };
    queryApi();
  }, [currentpage]);

  useEffect(() => {
    setCurrentPage(1);
    if (findByText && currentpage === 1) {
      (async () => {
        try {
          setLoading(true);
          await sortUsers();
        } catch (err: any) {
          handleError({ err, type: MessageTypes.error });
          setTimeout(() => {
            history.push('/');
          }, 3000);
        }
      })();
    }
  }, [searchText, userperpage]);

  const columns = [
    {
      title: 'Nombre(s)',
      dataIndex: 'first_name',
      key: 'first_name',
    },
    {
      title: 'Apellido(s)',
      dataIndex: 'last_name',
      key: 'last_name',
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
    },
    {
      title: 'Estado',
      dataIndex: 'is_available',
      key: 'is_available',
      render(isAvailable: string | number) {
        return (
          <>
            {isAvailable == 0 ? (
              <Text type="danger">Eliminado</Text>
            ) : (
              <Text type="success">Disponible</Text>
            )}
          </>
        );
      },
    },
    {
      title: 'Acciones',
      dataIndex: 'actions',
      key: 'actions',
      render(_: unknown, user: UserType & ClientType) {
        return (
          <Space direction="horizontal" size="small" style={{ width: '100%' }}>
            <Link to={`/${location.pathname.split('/')[1]}/editar/${user.id}`}>
              <Button type="link" block>
                Editar
              </Button>
            </Link>

            {location.pathname.split('/')[1] === 'cliente' && (
              <Link to={`/${location.pathname.split('/')[1]}/ver/${user.id}`}>
                <Button type="link" block>
                  Detalles
                </Button>
              </Link>
            )}

            <Button
              type="link"
              danger
              block
              disabled={!user.is_available && true}
              onClick={() => handleModal('open', user)}
            >
              Eliminar
            </Button>

            {location.pathname.split('/')[1] === 'cliente' && (
              <Link to={`/poliza`} onClick={() => currentClientAction(user)}>
                <Button type="link" block>
                  Pólizas
                </Button>
              </Link>
            )}
          </Space>
        );
      },
    },
  ];

  if (location.pathname.split('/')[1] === 'usuario') {
    columns.splice(3, 0, {
      title: 'Rol',
      dataIndex: 'role',
      key: 'role',
    });
  }

  if (location.pathname.split('/')[1] === 'cliente') {
    columns.splice(3, 0, {
      title: 'Grupo',
      dataIndex: 'group',
      key: 'group',
    });
  }

  const sortUsers = async () => {
    if (location.pathname.split('/')[1] === 'cliente') {
      const response = await getClients({
        clientPerPage: userperpage,
        currentPage: currentpage,
        q: searchText.length ? searchText : '',
      });
      if (response?.data) {
        const clients = response.data.clients.map(
          ({ user, client }: { user: UserType; client: ClientType }) => ({
            ...user,
            ...client,
            key: client.id,
          })
        );
        if (isMounted.current) {
          setTotal(response.data.totalClients);
          setUsers(clients);
        }
      }
    } else if (location.pathname.split('/')[1] === 'usuario') {
      const response = await getUsers({
        userPerPage: userperpage,
        currentPage: currentpage,
        q: searchText.length ? searchText : '',
      });
      if (response?.data) {
        const users = response.data.users
          .filter((userData: any) => userData.id !== user.id)
          .map((user: UserType) => ({
            ...user,
            key: user.id,
            role: user.role == 1 ? 'Administrador' : 'Empleado',
          }));
        if (isMounted.current) {
          setTotal(response.data.totalUsers);
          setUsers(users);
        }
      }
    }
    if (isMounted.current) {
      setfindByText(true);
      setLoading(false);
    }
  };

  const handleModal = (action: string, client?: UserType) => {
    if (action === 'open') {
      setDeleteUser(client!);
      setShowModal(true);
    } else {
      setDeleteUser(null);
      setShowModal(false);
    }
  };
  const handleModalOk = async () => {
    try {
      setLoading(true);
      let response: any;
      if (location.pathname.split('/')[1] === 'cliente') {
        response = await deleteClient(deleteuser!.id!);
      } else if (location.pathname.split('/')[1] === 'usuario') {
        response = await deleteUser(deleteuser!.id!);
      }
      const userDeleted = deleteuser;
      userDeleted!.is_available = 0;
      setUsers((users) =>
        users.map((user) => (user.id === deleteuser!.id ? userDeleted! : user))
      );
      setLoading(false);
      handleSuccess(response);
    } catch (err: any) {
      handleError({ err, type: MessageTypes.error });
    }
    setShowModal(false);
  };

  return (
    <>
      <Title style={{ textTransform: 'capitalize' }} level={2}>
        {location!.pathname!.split('/').pop().toLowerCase() + 's'}
      </Title>
      <Row
        justify="start"
        align="middle"
        style={{ marginBottom: '10px' }}
        gutter={[8, 8]}
      >
        <Col xs={24} sm={12} md={10} lg={8} xl={6}>
          <Link to={`/${location.pathname.split('/')[1]}/crear`}>
            <Button type="primary" block disabled={loading}>
              Agregar nuevo {location.pathname.split('/')[1]}
            </Button>
          </Link>
        </Col>
        <Col xs={24} md={10} lg={8} xl={6}>
          <SearchComponent setSearchText={setSearchText} />
        </Col>
      </Row>
      <TableComponent
        columns={columns}
        dataSource={users}
        loading={loading}
        pagination={{
          position: ['bottomLeft'],
          current: currentpage,
          pageSize: userperpage,
          showSizeChanger: true,
          total,
          onChange: (currentPage: number, pageSize: number) => {
            setPerPage(pageSize);
            setCurrentPage(currentPage);
          },
        }}
      />
      <Modal
        title={`Eliminar ${location.pathname.split('/')[1]}`}
        centered
        visible={showmodal}
        confirmLoading={loading}
        onCancel={() => handleModal('close')}
        onOk={handleModalOk}
      >
        <p>
          ¿Estas seguro de querer elimnar el {location.pathname.split('/')[1]}{' '}
          {deleteuser && deleteuser.first_name}?
        </p>
      </Modal>
    </>
  );
};

export default UserClientTable;
