import {
  LOGIN,
  LOGOUT,
  REFRESH_TOKEN,
  UPDATE_USER_INFO,
  UserType,
} from 'store/reducers/auth';
import { store } from 'store';

// Types
interface LoginActionPayload {
  accessToken: string;
  user: UserType;
}

// Actions
export const loginAction = (payload: LoginActionPayload): void => {
  store.dispatch({
    type: LOGIN,
    payload,
  });
};

export const logoutAction = (): void => {
  store.dispatch({
    type: LOGOUT,
  });
};

export const refreshTokenAction = (payload: string): void => {
  store.dispatch({
    type: REFRESH_TOKEN,
    payload,
  });
};

export const updateUserAction = (payload: UserType = {}): void => {
  store.dispatch({
    type: UPDATE_USER_INFO,
    payload,
  });
};
