// Dependencies
import storage from 'redux-persist/lib/storage';
import { persistCombineReducers } from 'redux-persist';

// Reducers
import auth from './reducers/auth';
import app from './reducers/app';

const config = {
  key: 'root',
  storage,
  whitelist: ['auth', 'app', 'user'],
};

const reducers = {
  auth,
  app,
};

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const rootReducer = () => persistCombineReducers(config, reducers);

export default rootReducer;
