import { UserType, ClientType } from 'interfaces';
import { AnyAction } from 'redux';

// Constants
export const CLEAR_APP = 'CLEAR_APP';
export const TOGGLE_SIDEBAR = 'TOGGLE_SIDEBAR';
export const SET_THEME = 'SET_THEME';
export const CURRENT_CLIENT = 'CURRENT_CLIENT';
export const PREVIOUS_POLICY_ID = 'PREVIOUS_POLICY_ID';

export interface AppTypes {
  collapsedSidebar: boolean;
  theme: Record<string, unknown>;
  currentClient: (UserType & ClientType) | null;
  previousPolicyId: Record<string, unknown> | null;
}

export type handleState = (state: AppTypes, action: AnyAction) => AppTypes;

// Initial state
export const initialState: AppTypes = {
  collapsedSidebar: false,
  theme: {},
  currentClient: null,
  previousPolicyId: null,
};

// Action handlers
const clear = () => ({ ...initialState });

const toggleSidebar = (state: AppTypes) => ({
  ...state,
  collapsedSidebar: !state.collapsedSidebar,
});

const setTheme = (state: AppTypes, payload: unknown) => ({
  ...state,
  theme: payload,
});

const setCurrentClient = (state: AppTypes, payload: unknown) => ({
  ...state,
  currentClient: payload,
});

const setPreviousPolicyId = (state: AppTypes, payload: unknown) => ({
  ...state,
  previousPolicyId: payload,
});

const ACTION_HANDLER = {
  [CLEAR_APP]: clear,
  [SET_THEME]: setTheme,
  [TOGGLE_SIDEBAR]: toggleSidebar,
  [CURRENT_CLIENT]: setCurrentClient,
  [PREVIOUS_POLICY_ID]: setPreviousPolicyId,
};

const Handler: handleState = (state = initialState, action: AnyAction) => {
  const handler = ACTION_HANDLER[action.type];
  return handler ? handler(state, action.payload) : state;
};

export default Handler;
