import React, { useState } from 'react';
import { Form, Row, Col, Input, Card, Space } from 'antd';

const PaymentAddress: React.FC = ({ paNumber = 2 }: { paNumber: number }) => {
  const [panumber] = useState<number>(paNumber);

  const paymentAdresses = () => {
    const children = [];
    for (let i = 1; i <= panumber; i++) {
      children.push(
        <Card title={`Dirección de Cobro ${i}`} key={i}>
          {i === 1 && (
            <Row align="middle" gutter={[16, 8]}>
              <Col xs={24} md={8}>
                <Form.Item
                  label="Contacto de Referencia"
                  name={[`PaymentAddress_${i}`, 'contact_reference']}
                >
                  <Input />
                </Form.Item>
              </Col>
            </Row>
          )}
          <Row align="middle" wrap gutter={[16, 8]}>
            <Col xs={24} md={12} lg={8}>
              <Form.Item label="Calle" name={[`PaymentAddress_${i}`, 'street']}>
                <Input />
              </Form.Item>
            </Col>

            <Col xs={24} md={12} lg={8}>
              <Form.Item
                label="Num. Ext."
                name={[`PaymentAddress_${i}`, 'ext_number']}
              >
                <Input />
              </Form.Item>
            </Col>

            <Col xs={24} md={12} lg={8}>
              <Form.Item
                label="Num. Int."
                name={[`PaymentAddress_${i}`, 'int_number']}
              >
                <Input />
              </Form.Item>
            </Col>

            <Col xs={24} md={12} lg={8}>
              <Form.Item label="País" name={[`PaymentAddress_${i}`, 'country']}>
                <Input />
              </Form.Item>
            </Col>

            <Col xs={24} md={12} lg={8}>
              <Form.Item label="Estado" name={[`PaymentAddress_${i}`, 'state']}>
                <Input />
              </Form.Item>
            </Col>

            <Col xs={24} md={12} lg={8}>
              <Form.Item label="Ciudad" name={[`PaymentAddress_${i}`, 'city']}>
                <Input />
              </Form.Item>
            </Col>

            <Col xs={24} md={12} lg={8}>
              <Form.Item
                label="Colonia"
                name={[`PaymentAddress_${i}`, 'neighborhood']}
              >
                <Input />
              </Form.Item>
            </Col>

            <Col xs={24} md={12} lg={8}>
              <Form.Item
                label="Código Postal"
                name={[`PaymentAddress_${i}`, 'zip_code']}
              >
                <Input />
              </Form.Item>
            </Col>

            <Form.Item name={[`PaymentAddress_${i}`, 'id']}>
              <Input type="hidden" />
            </Form.Item>
          </Row>
        </Card>
      );
    }
    return children;
  };
  return <Space direction="vertical">{paymentAdresses()}</Space>;
};

export default PaymentAddress;
