/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import { DatePicker } from 'antd';

interface Dates {
  initial_date?: string;
  final_date?: string;
}

interface Props {
  setDates: (
    dates: Dates | null
  ) => React.Dispatch<React.SetStateAction<Dates>>;
}

const { RangePicker } = DatePicker;
const dateFormat = 'DD-MM-YYYY';

const DateRangeFilterComponent: React.FC = ({ setDates }: Props) => {
  const handleChange = (dates: any) => {
    if (dates === null) {
      setDates(null);
      return;
    }
    setDates({
      initial_date: dates[0].format('YYYY-MM-DD'),
      final_date: dates[1].format('YYYY-MM-DD'),
    });
  };

  return (
    <RangePicker
      format={dateFormat}
      style={{ width: '100%' }}
      onChange={handleChange}
    />
  );
};

export default DateRangeFilterComponent;
