import React from 'react';
import { Form, Row, Col, Card, Input, Select } from 'antd';

const { Option } = Select;

const TransportData: React.FC = () => (
  <Card title="Transporte">
    <Row align="middle" wrap gutter={[16, 8]}>
      <Col xs={24} md={12} lg={8}>
        <Form.Item
          label="Marca"
          name={['car_policy_1', 'brand']}
          rules={[
            {
              required: true,
              type: 'string',
            },
          ]}
        >
          <Input />
        </Form.Item>
      </Col>
      <Col xs={24} md={12} lg={8}>
        <Form.Item
          label="Modelo"
          name={['car_policy_1', 'model']}
          rules={[
            {
              required: true,
              type: 'string',
            },
          ]}
        >
          <Input />
        </Form.Item>
      </Col>
      <Col xs={24} md={12} lg={8}>
        <Form.Item
          label="Número de Serie"
          name={['car_policy_1', 'serial_number']}
        >
          <Input />
        </Form.Item>
      </Col>
      <Col xs={24} md={12} lg={8}>
        <Form.Item label="Placas" name={['car_policy_1', 'plates']}>
          <Input />
        </Form.Item>
      </Col>
      <Col xs={24} md={12} lg={8}>
        <Form.Item
          label="Nombre del conductor"
          name={['car_policy_1', 'driver_name']}
        >
          <Input />
        </Form.Item>
      </Col>
      <Col xs={24} md={12} lg={8}>
        <Form.Item
          label="Tipo de mercancia"
          name={['car_policy_1', 'merchandise_type']}
        >
          <Input />
        </Form.Item>
      </Col>
      <Col xs={24} md={12} lg={8}>
        <Form.Item
          label="Origen del embarque"
          name={['car_policy_1', 'shipment_origin']}
        >
          <Input />
        </Form.Item>
      </Col>
      <Col xs={24} md={12} lg={8}>
        <Form.Item
          label="Destino del embarque"
          name={['car_policy_1', 'shipment_destination']}
        >
          <Input />
        </Form.Item>
      </Col>
      <Col xs={24} md={12} lg={8}>
        <Form.Item label="Coberturas" name={['car_policy_1', 'coverage']}>
          <Select placeholder="--- Seleccionar --- ">
            <Option value="R.O.T">R.O.T</Option>
            <Option value="R.O.T y robo">R.O.T y robo</Option>
          </Select>
        </Form.Item>
      </Col>

      <Form.Item name={['car_policy_1', 'id']} hidden>
        <Input />
      </Form.Item>
    </Row>
  </Card>
);

export default TransportData;
